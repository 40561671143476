import { ExpandMore } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, Box, Button, Card, Dialog, DialogActions, DialogContent, Skeleton, Snackbar, Stack, Tab, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RemoveProperty from "./Property/RemoveProperty";
import ViewProperty from "./Property/ViewProperty";
import ViewSchedule from "./iCalendar/ViewSchedule";
import CompletedServicesByPFK from "./serviceprovider/CompletedServicesByPFK";
import ServiceProviderPage from "./serviceprovider/ServiceProviderPage";
import RulesPage from "./IoT/RulesPage";
import DevicesPage from "./IoT/DevicesPage";
import { useGetPropertyQuery } from "../features/auth/hostAPI";
import { useGetPremadeRulesAndRuleGroupsByPropertyQuery, useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery } from "../features/auth/rulesAPI";
import { showLoader } from "../features/auth/authSlice";

const TAB_BASIC_INFORMATION = "1";
const TAB_BOOKING_SCHEDULE = "2";
const TAB_DEVICES = "3";
const TAB_ALERTS = "4";
const TAB_AUTOMATED_SERVICES = "5";

export default function Property() {
    const theme = useTheme();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [SPTabValue, setSPTabValue] = useState("1");
    const [hidePropertyDetails, sethidePropertyDetails] = useState(false);
    const [expanded, setExpanded] = useState("pnlProperty");
    const [searchParams, setSearchParams] = useSearchParams();
    const [showNewPropertyAlert, setShowNewPropertyAlert] = useState(false);
    const [showRemoveDlg, setShowRemoveDlg] = useState(false);
    const [showSuccessPropertRemove, setShowSuccessPropertyRemove] = useState(false);
    const [selectedTab, setSelectedTab] = useState(TAB_BASIC_INFORMATION);
    const [premadeRulesExists, setPremadeRulesExists] = useState(true);

    const navigate = useNavigate();

    const {
        data: dataGetProperty,
        isLoading: isLoadingGetProperty,
        isFetching: isFetchingGetProperty,
    } = useGetPropertyQuery({ propertyId: id });

    const isGettingPropertyInfo = isLoadingGetProperty || isFetchingGetProperty;

    const [
        getPremadeRulesAndRuleGroupsQuery,
    ] = useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery();

    useEffect(() => {
        dispatch(showLoader({
            open: isGettingPropertyInfo,
            message: "Please wait...",
        }));
    }, [isGettingPropertyInfo]);

    useEffect(() => {
        if (searchParams.get("status") !== null) {
            setShowNewPropertyAlert(true);
        }
        (async () => {
            await getPremadeRulesAndRuleGroupsQuery({ propertyId: id }).then((data) => {
                setPremadeRulesExists(data.isSuccess);
            });
        })();
    }, [searchParams]);

    const ServiceTabChange = (event, newValue) => {
        setSPTabValue(newValue);
    };
    const handlePanelOnChange = (panel) => (event, isExpanded) => {
        if (panel === "pnlDevices") {
            setExpanded(isExpanded ? panel : "closed");
        } else { setExpanded(isExpanded ? panel : false); }
    };

    const handleNewPropertySnackbarClose = (event, reason) => {
        if (reason === "clickaway") { return; }

        setShowNewPropertyAlert(false);
    };

    if (hidePropertyDetails === true) {
        return (
            <>
                <h2>Property Not Found.</h2>

                <RouterLink to="/property">Back to Properties</RouterLink>
            </>
        );
    }
    return (
        <>
            {!premadeRulesExists && (
                <Alert
                    severity="error"
                    sx={{ marginBottom: "1rem" }}
                    onClose={() => setPremadeRulesExists(true)}
                >
                    <AlertTitle>Sorry, we ran into an unexpected problem.</AlertTitle>
                    <Typography>
                        Our support team has been notified. Unfortunately, your devices
                        won&apos;t be available until the issue is resolved.
                    </Typography>
                </Alert>
            )}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showNewPropertyAlert}
                autoHideDuration={6000}
                onClose={handleNewPropertySnackbarClose}
            >
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                    Property added successfuly.
                </Alert>
            </Snackbar>
            {dataGetProperty !== undefined ? (
                <Stack sx={{ marginBottom: 2 }} direction="row" alignItems="center">
                    <Typography component="div" variant="h5" flexGrow={1}>
                        {dataGetProperty.propertyName}
                    </Typography>
                    <Button
                        color="error"
                        variant="contained"
                        onClick={() => setShowRemoveDlg(true)}
                    >
                        Remove Property
                    </Button>
                </Stack>
            ) : (
                <Stack spacing={1} sx={{ marginBottom: 2 }}>
                    <Skeleton
                        variant="rectangular"
                        sx={{ fontSize: "2rem" }}
                        width={200}
                    />
                </Stack>
            )}
            <RemoveProperty
                propertyInfo={dataGetProperty}
                forComponent="propertyDetails"
                callbackCloseDlg={() => setShowRemoveDlg(false)}
                open={showRemoveDlg}
            />
            <Card
                sx={{
                    paddingLeft: {
                        xs: 0,
                        sm: theme.spacing(1),
                    },
                    paddingRight: {
                        xs: 0,
                        sm: theme.spacing(1),
                    },
                }}
            >
                <TabContext value={selectedTab}>
                    <TabList
                        onChange={(e, newVal) => {
                            setSelectedTab(newVal);
                        }}
                        scrollButtons="auto"
                        variant="scrollable"
                        visibleScrollbar
                        allowScrollButtonsMobile
                    >
                        <Tab label="Basic Information" value={TAB_BASIC_INFORMATION} />
                        <Tab label="Booking Schedule" value={TAB_BOOKING_SCHEDULE} />
                        <Tab label="Devices" value={TAB_DEVICES} />
                        <Tab label="Alerts" value={TAB_ALERTS} />
                        <Tab label="Automated Services" value={TAB_AUTOMATED_SERVICES} />
                    </TabList>
                    <TabPanel value={TAB_BASIC_INFORMATION}>
                        <ViewProperty
                            propertyId={id}
                            RemoveProperty={() => setShowRemoveDlg(true)}
                        />
                    </TabPanel>
                    <TabPanel value={TAB_BOOKING_SCHEDULE}>
                        {dataGetProperty !== undefined && (
                            <ViewSchedule propertyInfo={dataGetProperty} />
                        )}
                    </TabPanel>
                    <TabPanel value={TAB_DEVICES}>
                        {expanded && dataGetProperty !== undefined && (
                            <DevicesPage
                                propertyId={dataGetProperty.id}
                                expanded={expanded}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={TAB_ALERTS}>
                        {dataGetProperty !== undefined && (
                            <RulesPage propertyId={dataGetProperty.id} />
                        )}
                    </TabPanel>
                    <TabPanel value={TAB_AUTOMATED_SERVICES}>
                        {dataGetProperty !== undefined && (
                            <ServiceProviderPage propertyInfo={dataGetProperty} />
                        )}
                    </TabPanel>
                </TabContext>
            </Card>
            <Dialog open={showSuccessPropertRemove}>
                <DialogContent>Property sucessfully removed.</DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            navigate("/property");
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
