import { Delete, MapsHomeWork } from "@mui/icons-material";
import { Box, Button, Card, CardContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { setPageTitle } from "../features/auth/authSlice";
import { useGetAllPropertiesQuery } from "../features/auth/hostAPI";
import RemoveProperty from "./Property/RemoveProperty";
import { StyledEmptyStateContainer } from "./helper/EmptyStateContainer";

export default function Properties() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const navigate = useNavigate();
    const [showRemoveDlg, setShowRemoveDlg] = useState(false);
    const [propertyInfo, setPropertyInfo] = useState(null);
    const {
        data: dataGetAllProperties,
        isLoading: isLoadingGetAllProperties,
        isFetching: isFetchingGetAllProperties,
        isError: isErrorGetAllProperties,
    } = useGetAllPropertiesQuery();

    useEffect(() => {
        dispatch(setPageTitle("Property"));
    }, []);

    const viewProperty = (id) => {
        navigate(`/property/${id}`);
    };

    const isGettingData = isLoadingGetAllProperties || isFetchingGetAllProperties;

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    paddingBottom: 2,
                }}
            >
                <Box sx={{ flexGrow: 1 }} />
                <Button variant="contained" component={RouterLink} to="/property/add">
                    Add Property
                </Button>
            </Box>
            <RemoveProperty
                propertyInfo={propertyInfo}
                open={showRemoveDlg}
                forComponent="propertyList"
                callbackCloseDlg={() => setShowRemoveDlg(false)}
            />
            <Card>
                <CardContent>
                    <TableContainer>
                        <Table>
                            <TableHead
                                sx={{
                                    display: {
                                        xs: "none",
                                        sm: "none",
                                        md: "table-header-group",
                                    },
                                }}
                            >
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isGettingData && (
                                    <TableRow>
                                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>Please wait...</TableCell>
                                    </TableRow>
                                )}
                                {(!isGettingData && isErrorGetAllProperties) && (
                                    <TableRow>
                                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>An error occured.</TableCell>
                                    </TableRow>
                                )}
                                {(!isGettingData && !isErrorGetAllProperties && dataGetAllProperties.length > 0)
                                    && dataGetAllProperties.map((property) => {
                                        let statusColor = "";
                                        switch (property.propertyStatus) {
                                            case 0: // vacant
                                                statusColor = "property_status.vacant";
                                                break;
                                            case 2: // occupied
                                                statusColor = "property_status.vacant";
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            <TableRow
                                                key={property.id}
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <TableCell onClick={() => viewProperty(property.id)}>
                                                    <Typography>{property.propertyName}</Typography>
                                                    {isMobile && (
                                                        <Typography color={statusColor}>{property.propertyStatusName}</Typography>
                                                    )}
                                                </TableCell>
                                                {/* {!isMobile && (
                                                    <> */}
                                                <TableCell
                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "table-cell",
                                                        },
                                                    }}
                                                    onClick={() => viewProperty(property.id)}
                                                >
                                                    {`${property.streetAddress1}, ${property.city}, ${property.state}`}
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        display: {
                                                            xs: "none",
                                                            sm: "none",
                                                            md: "table-cell",
                                                        },
                                                    }}
                                                    onClick={() => viewProperty(property.id)}
                                                >
                                                    <Typography color={statusColor}>{property.propertyStatusName}</Typography>
                                                </TableCell>
                                                {/* </>
                                                )} */}
                                                <TableCell sx={{ textAlign: "center" }}>
                                                    <IconButton
                                                        color="error"
                                                        onClick={(e) => {
                                                            setShowRemoveDlg(true);
                                                            setPropertyInfo(property);
                                                        }}
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {!isGettingData && !isErrorGetAllProperties && dataGetAllProperties.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                                            <StyledEmptyStateContainer>
                                                <MapsHomeWork />
                                                <Typography className="main">No properties found.</Typography>
                                                <Typography className="sub">
                                                    Would you like to add one now?
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    component={RouterLink}
                                                    to="/property/add"
                                                >
                                                    Add Property
                                                </Button>
                                            </StyledEmptyStateContainer>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </div>
    );
}
