import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const accountsAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getAccountsById: builder.query({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.get("IoTAPI", `/accounts/${id}`);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "account",
                    id: id.id,
                },
            ],
        }),
        switchPartnerAccount: builder.mutation({
            queryFn: async ({ partnerAccountId }) => {
                try {
                    const response = await API.put(
                        "IoTAPI",
                        `/partners/account/switch/${partnerAccountId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "partners",
                    id: "list",
                },
            ],
        }),
    }),
});

export const {
    useGetAccountsByIdQuery,
    useLazyGetAccountsByIdQuery,
    useSwitchPartnerAccountMutation,
} = accountsAPI;
