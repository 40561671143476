import { API } from "aws-amplify";

// export async function getRulesByProperty(id) {
//     const response = await API.get("IoTAPI", `/rules/property/${id}`);
//     return response.data;
// }

export async function getRulesDefaultByProperty(id) {
    const response = await API.get("IoTAPI", `/rules/default/property/${id}`);
    return response.data;
}

export async function GetRuleByDefaultDeviceId(id) {
    const response = await API.get("IoTAPI", `/rules/default/${id}`);
    return response.data;
}

// export async function GetRulesWithoutGroupConditions(id) {
//     const response = await API.get("IoTAPI", `/rules/no-group/${id}`);
//     return response.data;
// }

// export async function GetRuleGroups(propertyId) {
//     const response = await API.get("IoTAPI", `/rules/rulegroups/${propertyId}`);
//     return response.data;
// }

// export async function AddRule(rule) {
//     const myInit = {
//         body: {
//             ...rule,
//         },

//     };

//     const response = await API.post("IoTAPI", "/rules", myInit);
//     return response.data;
// }

export async function UpdateRule(id, rule) {
    const myInit = {
        body: {
            ...rule,
        },
    };

    const response = await API.put("IoTAPI", `/rules/${id}`, myInit);
    return response.data;
}

// export async function DeleteRule(id) {
//     const response = await API.del("IoTAPI", `/rules/${id}`);
//     return response.data;
// }

// export async function DeleteDefaultRule(defaultDeviceId) {
//     const response = await API.del("IoTAPI", `/rules/default/${defaultDeviceId}`);
//     return response.data;
// }

// export async function SyncDefaultRuleToCondition(id) {
//     const response = await API.get("IoTAPI", `/rules/default/sync/${id}`);
//     return response;
// }

export async function UpdateRuleState(rule) {
    const myInit = {
        body: {
            ...rule,
        },
    };
    const response = await API.put("IoTAPI", "/rules/premade/isActive/", myInit);
    return response.data;
}

// export async function AddRuleGroup(RuleGroup, GroupedRuleIds) {
//     const myInit = {
//         body: {
//             RuleGroup,
//             GroupedRuleIds,
//         },
//     };
//     const response = await API.post("IoTAPI", "/rulegroups", myInit);
//     return response.data;
// }
