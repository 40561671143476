import { Alert, Box, Button, Grid, Modal, Paper, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { Auth, Geo } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../features/auth/authSlice";
import { hostAPI, useUpdatePropertyMutation } from "../../features/auth/hostAPI";
import MapComponent from "../MapComponent";
import CustomLocationFormGroup from "../helper/CustomLocationFormGroup";
import PropertyNameComponent from "./PropertyNameComponent";
import PropertyRoom from "./PropertyRoom";

const AddressItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[0],
    minHeight: 64,
}));

export default function UpdatePropertyForm({ loadedProperty, setShowPropertyForm, callbackSuccess }) {
    // Constant/s Initialization...
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const [PropertyName, setPropertyName] = useState("");
    const [PropertyAddress, setPropertyAddress] = useState("");
    const [PropertyBathroom, setPropertyBathroom] = useState("");
    const [PropertyBed, setPropertyBed] = useState("");
    const [property, setProperty] = useState(null);
    const [propertyError, setPropertyError] = useState(null);
    const [open, setOpen] = useState(false);
    const [AddButtonState, setAddButtonState] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [mapLocation, setMapLocation] = useState({
        latitude: 0,
        longitude: 0,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    });
    const dispatch = useDispatch();

    const [updateProperty, {
        data: dataUpdateProperty,
        isLoading: isLoadingUpdateProperty,
        isSuccess: isSuccessUpdateProperty,
        isError: isErrorUpdateProperty,
        error: errorUpdateProperty,
    }] = useUpdatePropertyMutation();

    const [isPropertyNameAvailable, setIsPropertyNameAvailable] = useState(false);

    const { data: dataIsPropertyNameExists,
        isLoading: isLoadingCheckPropertyNameExists,
        isFetching: isFetchingCheckPropertyNameExists,
    } = hostAPI.endpoints.checkPropertyNameExist.useQueryState({
        propertyId: property?.id,
        propertyName: PropertyName,
    });

    const isValid = PropertyName.trim().length > 0
        && PropertyAddress.trim().length > 0
        && rooms.length > 0
        && (mapLocation.country.trim().length > 0
            || mapLocation.municipality.trim().length > 0
            || mapLocation.region.trim().length > 0
            || mapLocation.postalCode.trim().length > 0);

    // ...End of Constant/s Initialization
    // ..Events
    useEffect(() => {
        if (dataIsPropertyNameExists === undefined
            || dataIsPropertyNameExists === null
        ) {
            setIsPropertyNameAvailable(false);
        } else {
            setIsPropertyNameAvailable(!dataIsPropertyNameExists);
        }

        if (isLoadingCheckPropertyNameExists || isFetchingCheckPropertyNameExists) setIsPropertyNameAvailable(false);
    }, [dataIsPropertyNameExists, isLoadingCheckPropertyNameExists, isFetchingCheckPropertyNameExists]);

    useEffect(() => {
        // get property info
        const getProperty = async () => {
            setProperty(loadedProperty);
            setPropertyBed(loadedProperty.bedroomCount);
            setPropertyBathroom(loadedProperty.bathroomCount);
            setPropertyName(loadedProperty.propertyName);
            setPropertyAddress(loadedProperty.streetAddress1);
            setMapLocation({
                latitude: parseFloat(loadedProperty.locationLat),
                longitude: parseFloat(loadedProperty.locationLong),
                country: loadedProperty.country,
                municipality: loadedProperty.city,
                postalCode: loadedProperty.postalCode,
                region: loadedProperty.state,
                timeZone: loadedProperty.timeZone,
            });
            if (loadedProperty.id !== 0) setIsPropertyNameAvailable(true);
        };
        getProperty().catch((err) => {
            setPropertyError(err);
        });
    }, [loadedProperty]);

    useEffect(() => {
        if (!isLoadingUpdateProperty) {
            dispatch(showLoader({ open: false, message: "" }));
        }
    }, [isLoadingUpdateProperty]);

    useEffect(() => {
        if (isSuccessUpdateProperty) {
            setShowPropertyForm(false);
            if (callbackSuccess) {
                callbackSuccess(dataUpdateProperty);
            }
        }

        // if (isErrorUpdateProperty) {
        //     alert(errorUpdateProperty);
        // }
    }, [isSuccessUpdateProperty, isErrorUpdateProperty]);

    const mapCallBack = (e) => {
        setMapLocation((prev) => ({
            ...prev,
            latitude: e.latitude,
            longitude: e.longitude,
            label: e.label,
            country: e.country,
            municipality: e.municipality,
            postalCode: e.postalCode,
            region: e.region,
            street: e.street,
            timeZone: e.timeZone.name,
        }));
    };
    const PropNameOnChange = (e) => {
        setPropertyName(e.target.value);
    };

    const PropBathroomOnChange = (e) => {
        setPropertyBathroom(e.target.value);
        // if (PropertyBathroom >= 10 || PropertyBathroom <= 0) { setPropertyBathroom(1) }
    };

    const PropBedOnChange = (e) => {
        setPropertyBed(e.target.value);
        if (PropertyBed === 5) {
            setPropertyBed("5+");
        }
        // if (PropertyBed >= 10 || PropertyBed <= 0) { setPropertyBed(1) }
    };

    const UpdateRooms = (roomArr) => {
        setRooms(roomArr);
    };

    const searchOptionsWithBiasPosition = { maxResults: 1 };
    // ...End of Events

    const modalConfirmBtn = () => {
        setMapLocation({
            ...mapLocation,
            country: mapLocation.country,
            municipality: mapLocation.municipality,
            region: mapLocation.region,
            postalCode: mapLocation.postalCode,
            timeZone: mapLocation.timeZone.name,
        });
        alert("Address refined successfully! See the details below Street Address");
        setOpen(false);
    };

    const modalCancelBtn = () => {
        setMapLocation({
            ...mapLocation,
            country: mapLocation.country,
            municipality: mapLocation.municipality,
            region: mapLocation.region,
            postalCode: mapLocation.postalCode,
            timeZone: mapLocation.timeZone.name,
        });
        setOpen(false);
    };

    const UpdateFormSubmit = (e) => {
        e.preventDefault();
        dispatch(showLoader({ open: true, message: "Updating property information. Please wait..." }));
        Geo.searchByText(PropertyAddress, searchOptionsWithBiasPosition).then((data) => {
            setMapLocation({
                ...mapLocation,
                country: data[0].country || "",
                longitude: data[0].geometry.point[0],
                latitude: data[0].geometry.point[1],
                municipality: data[0].municipality || "",
                region: data[0].region || "",
                postalCode: data[0].postalCode || "",
                timeZone: data[0].timeZone.name || "",
            });

            Auth.currentAuthenticatedUser().then((d) => {
                updateProperty({
                    propertyId: property.id,
                    propertyName: PropertyName,
                    cognitoUserId: d.attributes.sub,
                    locationLong: JSON.stringify(data[0].geometry.point[0]),
                    locationLat: JSON.stringify(data[0].geometry.point[1]),
                    streetAddress1: PropertyAddress,
                    city: data[0].municipality || "",
                    state: data[0].region || "",
                    country: data[0].country || "",
                    postalCode: data[0].postalCode || "",
                    timeZone: data[0].timeZone.name || "",
                    bedroomCount: PropertyBed,
                    bathroomCount: PropertyBathroom,
                    propertyRooms: rooms,
                });
            });
        });
    };

    const isCheckingPropertyNameRequest = isLoadingCheckPropertyNameExists || isFetchingCheckPropertyNameExists;

    return property && (
        <>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => {
                    setMapLocation({
                        ...mapLocation,
                        country: mapLocation.country,
                        municipality: mapLocation.municipality,
                        region: mapLocation.region,
                        postalCode: mapLocation.postalCode,
                    });
                    setOpen(false);
                }}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                {/* <Sheet
                                variant="outlined"
                                sx={{
                                    maxWidth: 800,
                                    borderRadius: "lg",
                                    p: 3,
                                    boxShadow: "lg",
                                }}
                            >
                                <ModalClose
                                    variant="outlined"
                                    sx={{
                                        top: "calc(-1/4 * var(--IconButton-size))",
                                        right: "calc(-1/4 * var(--IconButton-size))",
                                        boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                                        borderRadius: "50%",
                                        bgcolor: "background.body",
                                    }}
                                /> */}
                <Box>
                    <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
                        View Map
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }} id="modal-desc" textColor="text.tertiary">
                        Search your Address through this map and auto-fill the fields through Amazon Location Service
                    </Typography>
                    <div style={{ marginBottom: "10px" }}>
                        <MapComponent callBack={mapCallBack} mapSize={{ width: "100%", height: "50vh" }} />
                    </div>
                    <Button
                        onClick={modalCancelBtn}
                        style={{ marginLeft: "5%", width: "40%", marginRight: "5%" }}
                        color="neutral"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={modalConfirmBtn}
                        style={{ marginLeft: "5%", width: "40%", marginRight: "5%" }}
                        color="neutral"
                    >
                        Confirm
                    </Button>
                    {/* </Sheet> */}
                </Box>
            </Modal>
            <Grid container spacing={1}>
                {isErrorUpdateProperty && (
                    <Grid
                        item
                        xs={12}
                    >
                        <Alert severity="error">
                            {errorUpdateProperty?.message}
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <PropertyNameComponent
                        label="Property Name"
                        fullWidth
                        value={PropertyName}
                        onChange={(e) => {
                            setIsPropertyNameAvailable(false);
                            setPropertyName(e.target.value);
                        }}
                        propertyid={property.id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomLocationFormGroup
                        propertyInfo={loadedProperty}
                        callbackResult={(res) => {
                            setPropertyAddress(res.PropertyAddress);
                            setMapLocation(res.mapLocation);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PropertyRoom
                        callbackUpdateRooms={UpdateRooms}
                        roomData={loadedProperty.propertyRooms}
                        disableEntry={mapLocation.country === ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent={{
                            xs: "center",
                            sm: "flex-end",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="close"
                            onClick={() => setShowPropertyForm(false)}
                            fullWidth={!!isMobile}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!isValid || !isPropertyNameAvailable}
                            onClick={UpdateFormSubmit}
                            fullWidth={!!isMobile}
                        >
                            Update
                        </Button>
                    </Stack>
                </Grid>

                {AddButtonState && (
                    <Grid item xs={6}>
                        <Button onClick={() => setOpen(true)} fullWidth color="primary">
                            Show Map
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
