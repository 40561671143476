import { Add, AddAlert, Close, Delete, Edit, ExpandMore, HelpOutline, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Popover,
    Skeleton,
    Stack,
    Switch,
    TextField,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    UpdateRule,
} from "../../api/IoT/rules";
import { useAddRuleMutation, useDeleteRuleMutation, useGetRulesByPropertyQuery, useUpdateRuleMutation } from "../../features/auth/rulesAPI";
import Conditions from "./Conditions";
import { useLazyGetConditionsByRuleQuery } from "../../features/auth/conditionsAPI";
import { useLazyGetActionsByRuleIdQuery } from "../../features/auth/actionsAPI";
import { StyledEmptyStateContainer } from "../helper/EmptyStateContainer";
// import Conditions from "./Conditions";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    ".accordionSummaryContainer": {
        display: "flex",
        ".MuiAccordionSummary-root": {
            flexGrow: 1,
            flexDirection: "row-reverse",
        },
        ".MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1),
        },
        ".MuiAccordionSummary-expandIconWrapper": {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(2),
        },
    },
    ".actions": {
        display: "flex",
        alignItems: "center",
    },
    "&.Mui-expanded": {
        margin: 0,
    },
}));

function RulesPage({ propertyId, closeCallback }) {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const [rules, setRules] = useState([]);
    const [premadeRules, setPremadeRules] = useState([]);
    const [customRules, setCustomRules] = useState([]);
    const [ruleName, setRuleName] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [ruleId, setRuleId] = useState();
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disableControls, setDisableControls] = useState(false);
    const [hasRefreshResponse, setHasRefreshResponse] = useState(false);
    const [successAlertMsg, setSuccessAlertMsg] = useState("");
    const [deleteRule, setDeleteRule] = useState();
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [hidePreMadeRules, sethidePreMadeRules] = useState(true);

    const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);

    const [anchorElInfoTips, setAnchorElInfoTips] = useState(null);
    const openInfo = Boolean(anchorElInfoTips);

    const {
        data: dataRulesByProperty,
        isLoading: isLoadingGetRulesByProperty,
        isFetching: isFetchingGetRulesByProperty,
    } = useGetRulesByPropertyQuery({
        propertyId,
    });
    const [addRule, {
        isLoading: isLoadingAddRule,
        isFetching: isFetchingAddRule,
    }] = useAddRuleMutation();
    const [updateRule, {
        isLoading: isLoadingUpdaterule,
        isFetching: isFetchingUpdateRule,
    }] = useUpdateRuleMutation();
    const [removeRule, {
        isLoading: isLoadingRemoveRule,
        isFetching: isFetchingRemoveRule,
    }] = useDeleteRuleMutation();
    const isLoadingRules = isLoadingGetRulesByProperty || isFetchingGetRulesByProperty;
    const isAddingRule = isLoadingAddRule || isFetchingAddRule;
    const isUpdatingRule = isLoadingUpdaterule || isFetchingUpdateRule;
    const isRemovingRule = isLoadingRemoveRule || isFetchingRemoveRule;

    useEffect(() => {
        if (dataRulesByProperty !== undefined) {
            const rulesByProperty = dataRulesByProperty;
            setCustomRules(rulesByProperty.filter((x) => !x.isPreMadeRule));
            setPremadeRules(rulesByProperty.filter((x) => x.isPreMadeRule));
            setRules(rulesByProperty);
        }
    }, [dataRulesByProperty]);

    useEffect(() => {
        if (isDesktop) setAnchorElInfoTips(null);
    }, [isDesktop]);

    const AddRuleHandler = async (e) => {
        // setDisableControls(true);
        e.preventDefault();
        if (ruleName !== "") {
            const ruleObject = {
                Name: ruleName,
                PropertyId: propertyId,
                isActive,
            };

            await addRule({ ruleObject });
            // setDisableControls(false);
            setSuccessAlertMsg(`${ruleName} has been successfully added.`);
            setHasRefreshResponse(true);
            setShowAdd(false);
            // setReload((curr) => !curr);
        } else {
            alert("Rule name is required");
        }
    };

    const EditRuleHandler = async (e) => {
        // setDisableControls(true);
        e.preventDefault();
        const ruleObject = dataRulesByProperty.filter((x) => x.ruleId === ruleId);
        const objForUpdate = JSON.parse(JSON.stringify(ruleObject[0]));

        objForUpdate.name = ruleName;
        objForUpdate.isActive = isActive;
        await updateRule({
            id: ruleId,
            ruleObject: objForUpdate,
        });
        // setDisableControls(false);
        setSuccessAlertMsg(`${ruleName} has been successfully updated.`);
        setHasRefreshResponse(true);
        // setReload((curr) => !curr);
        setShowEdit(false);
    };

    const UpdateRuleStateHandler = async (e, input) => {
        const rule = structuredClone(input);
        rule.isActive = e;
        // await UpdateRule(rule.ruleId, rule);

        await updateRule({
            id: rule.ruleId,
            ruleObject: rule,
        });

        if (rule.isActive) {
            setSuccessAlertMsg(`${rule.name} Rule has been successfully activated.`);
        } else {
            setSuccessAlertMsg(`${rule.name} Rule has been successfully deactivated.`);
        }
        setHasRefreshResponse(true);
    };

    const DeleteRuleHandler = async (rule) => {
        // setDisableControls(true);
        setRuleId(rule.ruleId);
        setRuleName(rule.name);
        await removeRule({
            id: rule.ruleId,
        });
        setSuccessAlertMsg("Rule has been successfully deleted.");
        setHasRefreshResponse(true);
        setShowDeleteRuleModal(false);
        // setDisableControls(false);
        // setReload((curr) => !curr);
    };

    const setRuleNameHandler = (name) => {
        setRuleName(name);
    };
    const setIsActiveHandler = (state) => setIsActive(state);

    const onEditButtonHandler = (rule) => {
        setShowEdit(true);
        setRuleName(rule.name);
        setIsActive(rule.isActive);
        setRuleId(rule.ruleId);
    };

    function hidePreMadeRulesHandler(isHidden) {
        sethidePreMadeRules(isHidden);
    }

    const actionButtons = (x) => (
        <Box className="actions">
            {!x.isPreMadeRule && (
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => onEditButtonHandler(x)}
                >
                    <Edit />
                </IconButton>
            )}

            {x.isPreMadeRule && (
                <FormControlLabel
                    control={(
                        <Switch
                            checked={x.isActive}
                            size="small"
                            onClick={(e) => {
                                UpdateRuleStateHandler(e.target.checked, x);
                            }}
                        />
                    )}
                    label={(
                        <Chip
                            size="small"
                            label={x.isActive ? "active" : "inactive"}
                            sx={{
                                backgroundColor: theme.palette.common.white,
                            }}
                        />
                    )}
                />
            )}
            {!x.isPreMadeRule && (
                <IconButton
                    color="error"
                    size="small"
                    onClick={() => {
                        setShowDeleteRuleModal(true);
                        setDeleteRule(x);
                    }}
                >
                    <Delete />
                </IconButton>
            )}
        </Box>
    );

    const customAccordion = (x, i) => {
        let colorCode = theme.palette.alert_rules.inactive; // default inactive
        if (x.isActive && x.isPreMadeRule && !x.isDefaultRule) colorCode = theme.palette.alert_rules.premade;
        if (x.isActive && !x.isPreMadeRule && !x.isDefaultRule) colorCode = theme.palette.alert_rules.custom;
        if (x.isActive && x.isPreMadeRule && x.isDefaultRule) colorCode = theme.palette.alert_rules.premade;

        return (
            <StyledAccordion
                key={i}
                sx={{
                    border: `1px solid ${colorCode}`,
                }}
            >
                <Box
                    className="accordionSummaryContainer"
                    sx={{
                        backgroundColor: colorCode,
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                    >
                        <Typography>
                            {x.name}
                        </Typography>
                    </AccordionSummary>
                    {actionButtons(x)}
                </Box>
                <AccordionDetails>
                    <Conditions ruleId={x.ruleId} propertyId={propertyId} />
                </AccordionDetails>
            </StyledAccordion>
        );
    };

    const infoTip = () => {
        const msg = "Upon adding devices into HAI, individual reserved alerts are created as well."
        + " These alerts are then added to Alert Groups that get triggered as soon as any one member"
        + " is alerted (i.e. new doors get added to [Unexpected Entry] automatically). "
        + "You can make your own custom group by adding an Alert Group condition to an Alert, and selecting"
        + "Create custom alert group.";
        return (
            <>
                <Grid
                    item
                    lg={3}
                    sx={{
                        display: {
                            xs: "none",
                            lg: "block",
                        },
                    }}
                >
                    <Alert
                        severity="info"
                    >
                        <AlertTitle>Info/Tips:</AlertTitle>
                        {msg}
                    </Alert>
                </Grid>
                <Popover
                    open={openInfo}
                    onClose={() => setAnchorElInfoTips(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    anchorEl={anchorElInfoTips}
                >
                    <Alert
                        severity="info"
                        onClose={() => { setAnchorElInfoTips(null); }}
                    >
                        {msg}
                    </Alert>
                </Popover>
            </>
        );
    };

    return (
        <>
            {hasRefreshResponse && (
                <Alert
                    severity="success"
                    onClose={() => {
                        setHasRefreshResponse(false);
                    }}
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    {successAlertMsg}
                </Alert>
            )}
            <Grid
                container
                spacing={2}
                direction={{ xs: "column-reverse", lg: "row" }}
            >
                {isLoadingRules && <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>}
                {!isLoadingRules && (
                    <>
                        <Grid
                            item
                            xs={12}
                            lg={9}
                        >
                            <Card
                                sx={{
                                    marginBottom: theme.spacing(2),
                                }}
                            >
                                <CardHeader
                                    title={(
                                        <Typography>
                                            Custom Alerts
                                        </Typography>
                                    )}
                                    action={(
                                        <>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setRuleName("");
                                                    setShowAdd(true);
                                                }}
                                                startIcon={<AddAlert />}
                                            >
                                                Add Alert
                                            </Button>
                                            <IconButton
                                                color="info"
                                                onClick={(e) => setAnchorElInfoTips(e.currentTarget)}
                                                sx={{
                                                    display: isDesktop ? "none" : "inline-flex",
                                                }}
                                            >
                                                <HelpOutline />
                                            </IconButton>
                                        </>
                                    )}
                                />
                                <CardContent>
                                    {customRules.map((x, i) => (
                                        customAccordion(x, i)
                                    ))}
                                    {customRules.length === 0 && (
                                        <StyledEmptyStateContainer
                                            sx={{
                                                width: "100%",
                                            }}
                                        >
                                            <AddAlert />
                                            <Typography className="main">No custom alerts found.</Typography>
                                        </StyledEmptyStateContainer>
                                    )}
                                </CardContent>
                            </Card>
                            <Card>
                                <CardHeader
                                    title={(
                                        <Typography>
                                            Default Alerts
                                        </Typography>
                                    )}
                                />
                                <CardContent>
                                    {premadeRules.map((x, i) => (
                                        customAccordion(x, i)
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                        {infoTip()}
                        {/* <Grid
                            item
                            xs={12}
                            lg={3}
                        >
                            <Alert
                                severity="info"
                                variant="outlined"
                            >
                                <AlertTitle>
                                    <Typography
                                        variant="caption"
                                        fontWeight="bold"
                                    >
                                        Note:
                                    </Typography>
                                </AlertTitle>
                                <Typography variant="caption">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                                    . Excepteur sint occaecat cupidatat non proident,
                                    sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </Typography>
                            </Alert>
                        </Grid> */}
                    </>
                )}
            </Grid>
            {/* Modal for adding a rule */}
            <Dialog open={showAdd} onClose={() => setShowAdd(false)} fullWidth maxWidth="xs">
                <DialogTitle>Add Custom Alert</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        error={ruleName.length === 0}
                        helperText="Name must be filled out."
                        placeholder="Enter Alert Name"
                        size="small"
                        fullWidth
                        disabled={isAddingRule}
                        label="Alert Name:"
                        value={ruleName}
                        onChange={(e) => {
                            setRuleNameHandler(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        disabled={isAddingRule}
                        color="info"
                        onClick={() => setShowAdd(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        disabled={isAddingRule || ruleName.length === 0}
                        loading={isAddingRule}
                        endIcon={<Add />}
                        loadingPosition="end"
                        onClick={(e) => AddRuleHandler(e)}
                    >
                        <span>Add</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {/* Modal for editing a rule */}
            <Dialog open={showEdit} onClose={(e) => setShowEdit(false)} fullWidth maxWidth="xs">
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="normal"
                        error={ruleName.length === 0}
                        helperText="Name must be filled out."
                        placeholder="Enter Alert Name"
                        size="small"
                        fullWidth
                        disabled={isUpdatingRule}
                        label="Alert Name:"
                        value={ruleName}
                        onChange={(e) => {
                            setRuleNameHandler(e.target.value);
                        }}
                    />
                    {/* <FormControlLabel
                                    disabled={disableControls}
                                    control={<Checkbox checked={isActive} onChange={(e) => setIsActiveHandler(e.target.checked)} />}
                                    label="Set Rule as Active"
                                /> */}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        disabled={isUpdatingRule}
                        color="info"
                        onClick={() => setShowEdit(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        disabled={isUpdatingRule || ruleName.length === 0}
                        loading={isUpdatingRule}
                        loadingPosition="center"
                        endIcon={<Save />}
                        onClick={(e) => EditRuleHandler(e)}
                    >
                        <span>Update</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog open={showDeleteRuleModal} onClose={() => setShowDeleteRuleModal(false)} fullWidth maxWidth="xs">
                <DialogTitle>
                    Confirm Rule Deletion
                </DialogTitle>
                <DialogContent>
                    <Alert
                        severity="warning"
                        variant="standard"
                        sx={{ marginBottom: "10px" }}
                    >
                        <strong>Are you sure you want to delete this rule?</strong>
                        {" "}
                        Deleting the rule will also permanently
                        remove all conditions associated with it.
                        {" "}
                        <strong>This action cannot be undone.</strong>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        disabled={isRemovingRule}
                        color="info"
                        onClick={() => setShowDeleteRuleModal(false)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={isRemovingRule}
                        loadingPosition="end"
                        color="error"
                        endIcon={<Delete />}
                        onClick={() => {
                            DeleteRuleHandler(deleteRule);
                        }}
                    >
                        <span>Delete</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );

    // return (
    //     <>
    //         {hasRefreshResponse && (
    //             <Alert
    //                 severity="success"
    //                 onClose={() => {
    //                     setHasRefreshResponse(false);
    //                 }}
    //                 sx={{
    //                     marginBottom: 2,
    //                 }}
    //             >
    //                 {successAlertMsg}
    //             </Alert>
    //         )}
    //         {loading && <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>}
    //         {!loading && (
    //             <Grid container marginTop="5px" rowSpacing={1}>
    //                 <Grid item xs={12}>
    //                     <Box flexDirection="row" justifyContent="space-between" display="flex">
    //                         <Button
    //                             variant="contained"
    //                             onClick={() => {
    //                                 setRuleName("");
    //                                 setShowAdd(true);
    //                             }}
    //                         >
    //                             <Add />
    //                             Add Alert
    //                         </Button>
    //                         <FormGroup>
    //                             <FormControlLabel
    //                                 control={(
    //                                     <Switch
    //                                         defaultChecked
    //                                         onChange={(e) => {
    //                                             hidePreMadeRulesHandler(e.target.checked);
    //                                         }}
    //                                     />
    //                                 )}
    //                                 labelPlacement="start"
    //                                 label="Hide reserved alerts"
    //                             />
    //                         </FormGroup>
    //                     </Box>
    //                     {/* Modal for adding a rule */}
    //                     <Dialog open={showAdd} onClose={() => setShowAdd(false)} fullWidth maxWidth="xs">
    //                         <DialogTitle>Add</DialogTitle>
    //                         <DialogContent>
    //                             <TextField
    //                                 margin="normal"
    //                                 error={ruleName.length === 0}
    //                                 helperText="Name must be filled out."
    //                                 placeholder="Enter Alert Name"
    //                                 size="small"
    //                                 fullWidth
    //                                 disabled={disableControls}
    //                                 label="Alert Name:"
    //                                 value={ruleName}
    //                                 onChange={(e) => {
    //                                     setRuleNameHandler(e.target.value);
    //                                 }}
    //                             />
    //                             <FormControlLabel
    //                                 disabled={disableControls}
    //                                 control={<Checkbox checked={isActive} onChange={(e) => setIsActiveHandler(e.target.checked)} />}
    //                                 label="Set alert as active"
    //                             />
    //                         </DialogContent>
    //                         <DialogActions>
    //                             <Button
    //                                 variant="outlined"
    //                                 disabled={disableControls}
    //                                 color="info"
    //                                 onClick={() => setShowAdd(false)}
    //                             >
    //                                 Cancel
    //                             </Button>
    //                             <LoadingButton
    //                                 variant="contained"
    //                                 disabled={disableControls || ruleName.length === 0}
    //                                 loading={disableControls}
    //                                 endIcon={<Add />}
    //                                 loadingPosition="end"
    //                                 onClick={(e) => AddRuleHandler(e)}
    //                             >
    //                                 <span>Add</span>
    //                             </LoadingButton>
    //                         </DialogActions>
    //                     </Dialog>

    //                     {/* Modal for editing a rule */}
    //                     <Dialog open={showEdit} onClose={(e) => setShowEdit(false)} fullWidth maxWidth="xs">
    //                         <DialogTitle>Edit</DialogTitle>
    //                         <DialogContent>
    //                             <TextField
    //                                 margin="normal"
    //                                 error={ruleName.length === 0}
    //                                 helperText="Name must be filled out."
    //                                 placeholder="Enter Alert Name"
    //                                 size="small"
    //                                 fullWidth
    //                                 disabled={disableControls}
    //                                 label="Alert Name:"
    //                                 value={ruleName}
    //                                 onChange={(e) => {
    //                                     setRuleNameHandler(e.target.value);
    //                                 }}
    //                             />
    //                             {/* <FormControlLabel
    //                                 disabled={disableControls}
    //                                 control={<Checkbox checked={isActive} onChange={(e) => setIsActiveHandler(e.target.checked)} />}
    //                                 label="Set Rule as Active"
    //                             /> */}
    //                         </DialogContent>
    //                         <DialogActions>
    //                             <Button
    //                                 variant="outlined"
    //                                 disabled={disableControls}
    //                                 color="info"
    //                                 onClick={() => setShowEdit(false)}
    //                             >
    //                                 Cancel
    //                             </Button>
    //                             <LoadingButton
    //                                 variant="contained"
    //                                 disabled={disableControls || ruleName.length === 0}
    //                                 loading={disableControls}
    //                                 loadingPosition="center"
    //                                 endIcon={<Save />}
    //                                 onClick={(e) => EditRuleHandler(e)}
    //                             >
    //                                 <span>Update</span>
    //                             </LoadingButton>
    //                         </DialogActions>
    //                     </Dialog>
    //                 </Grid>
    //                 {rules.filter((x) => (!x.isPreMadeRule && !x.isDefaultRule) || !hidePreMadeRules).map((x) => (
    //                     <Grid item xs={12} container key={x.ruleId}>
    //                         <Grid item xs={10}>
    //                             <Accordion>

    //                                 {x.isActive && x.isPreMadeRule && !x.isDefaultRule && (
    //                                     <AccordionSummary
    //                                         style={{ backgroundColor: "#F6FFDE" }}
    //                                         expandIcon={<ExpandMore />}
    //                                     >
    //                                         <Typography>{x.name}</Typography>
    //                                     </AccordionSummary>
    //                                 )}

    //                                 {x.isActive && !x.isPreMadeRule && !x.isDefaultRule && (
    //                                     <AccordionSummary
    //                                         style={{ backgroundColor: "#E3F2C1" }}
    //                                         expandIcon={<ExpandMore />}
    //                                     >
    //                                         <Typography>{x.name}</Typography>
    //                                     </AccordionSummary>
    //                                 )}

    //                                 {x.isActive && x.isPreMadeRule && x.isDefaultRule && (
    //                                     <AccordionSummary
    //                                         style={{ backgroundColor: "#F6FFDE" }}
    //                                         expandIcon={<ExpandMore />}
    //                                     >
    //                                         <Typography>{x.name}</Typography>
    //                                     </AccordionSummary>
    //                                 )}

    //                                 {!x.isActive && (
    //                                     <AccordionSummary
    //                                         style={{ backgroundColor: "#e6e6e6" }}
    //                                         expandIcon={<ExpandMore />}
    //                                     >
    //                                         <Typography>
    //                                             {x.name}
    //                                             {" "}
    //                                             {/* (Inactive) */}
    //                                         </Typography>
    //                                     </AccordionSummary>
    //                                 )}
    //                                 <AccordionDetails>
    //                                     <Conditions ruleId={x.ruleId} propertyId={propertyId} />
    //                                 </AccordionDetails>
    //                             </Accordion>
    //                         </Grid>

    //                         {!x.isPreMadeRule && (
    //                             <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //                                 <IconButton
    //                                     color="primary"
    //                                     onClick={() => onEditButtonHandler(x)}
    //                                 >
    //                                     <Edit style={{ fontSize: "2rem" }} />
    //                                 </IconButton>
    //                             </Grid>
    //                         )}

    //                         {x.isPreMadeRule && (
    //                             <Grid item xs={1}>
    //                                 <Switch
    //                                     checked={x.isActive}
    //                                     onClick={(e) => {
    //                                         UpdateRuleStateHandler(e.target.checked, x);
    //                                     }}
    //                                 />
    //                             </Grid>
    //                         )}
    //                         {!x.isPreMadeRule && (
    //                             <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //                                 <IconButton
    //                                     color="error"
    //                                     onClick={() => {
    //                                         setShowDeleteRuleModal(true);
    //                                     }}
    //                                 >
    //                                     <Delete style={{ fontSize: "2rem" }} />
    //                                 </IconButton>
    //                                 <Dialog open={showDeleteRuleModal} onClose={() => setShowDeleteRuleModal(false)} fullWidth maxWidth="xs">
    //                                     <DialogTitle>
    //                                         Confirm Rule Deletion
    //                                     </DialogTitle>
    //                                     <DialogContent>
    //                                         <Alert
    //                                             severity="warning"
    //                                             variant="standard"
    //                                             sx={{ marginBottom: "10px" }}
    //                                         >
    //                                             <strong>Are you sure you want to delete this rule?</strong>
    //                                             {" "}
    //                                             Deleting the rule will also permanently
    //                                             remove all conditions associated with it.
    //                                             {" "}
    //                                             <strong>This action cannot be undone.</strong>
    //                                         </Alert>
    //                                     </DialogContent>
    //                                     <DialogActions>
    //                                         <Button
    //                                             variant="outlined"
    //                                             disabled={disableControls}
    //                                             color="info"
    //                                             onClick={() => setShowDeleteRuleModal(false)}
    //                                         >
    //                                             Cancel
    //                                         </Button>
    //                                         <LoadingButton
    //                                             variant="contained"
    //                                             loading={disableControls}
    //                                             loadingPosition="end"
    //                                             color="error"
    //                                             endIcon={<Delete />}
    //                                             onClick={() => {
    //                                                 DeleteRuleHandler(x);
    //                                             }}
    //                                         >
    //                                             <span>Delete</span>
    //                                         </LoadingButton>
    //                                     </DialogActions>
    //                                 </Dialog>
    //                             </Grid>
    //                         )}
    //                     </Grid>
    //                 ))}
    //             </Grid>
    //         )}
    //     </>
    // );
}

export default RulesPage;
