import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import theme from "./utils/theme";
import AmazonConfig from "./amazonConfig";

(async () => {
    Amplify.configure(await AmazonConfig());
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <Authenticator.Provider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <App />
                    </ThemeProvider>
                </Authenticator.Provider>
            </Provider>
        </React.StrictMode>,
    );
    reportWebVitals();
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
