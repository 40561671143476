import { Box, Button, Card, CardContent, CardHeader, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress, styled, useMediaQuery, useTheme } from "@mui/material";
import { API } from "aws-amplify";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import "../../utils/moment-setup";
// import { useEffect, useState } from "react";
import { BrokenImage } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { ServiceStatusColorCode } from "../../utils/ServiceStatusColorCode";
import { StyledEmptyStateContainer } from "../helper/EmptyStateContainer";
import ServiceDetailsRemarks from "./ServiceDetailsRemarks";

const EMPTY_DATETIME_VALUE = "0001-01-01T00:00:00";

const StyledServiceRequestDetails = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    "& .label": {
        color: theme.palette.text.secondary,
    },
    "& .value": {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        color: theme.palette.text.primary,
    },
}));

function CompletedServicesDetails({ PassInfoId }) {
    const theme = useTheme();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const [getRoomDetails, setGetRoomDetails] = useState([]);
    const [getServiceInfo, setGetServiceInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (openDialog) FetchDetails();
    }, [openDialog]);

    const FetchDetails = async () => {
        setIsLoading(true);
        await API.get("ServiceProviderApi", `/GetCompletedDetails/${PassInfoId}`).then((res) => {
            setGetServiceInfo(res);
            setIsLoading(false);
        }).catch((ex) => {
            setIsLoading(false);
            console.log(ex.message);
        });
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={() => setOpenDialog(true)}
                fullWidth={isMobile}
            >
                Details
            </Button>
            <Dialog
                open={openDialog}
                maxWidth="lg"
                fullScreen={isMobile}
            >
                <DialogTitle
                    sx={{
                        borderTopColor: ServiceStatusColorCode({ statusCode: getServiceInfo.serviceStatusId }),
                        borderTopWidth: theme.spacing(1),
                        borderTopStyle: "solid",
                    }}
                >
                    Details
                </DialogTitle>

                {isLoading && (
                    <LinearProgress />
                )}
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <StyledServiceRequestDetails>
                                <Box className="label">Provider Name:</Box>
                                <Box className="value">{getServiceInfo.serviceProviderName}</Box>
                            </StyledServiceRequestDetails>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledServiceRequestDetails>
                                <Box className="label">Service Type:</Box>
                                <Box className="value">{getServiceInfo.serviceTypeName}</Box>
                            </StyledServiceRequestDetails>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledServiceRequestDetails>
                                <Box className="label">Status:</Box>
                                <Box className="value">{getServiceInfo.serviceStatusName}</Box>
                            </StyledServiceRequestDetails>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledServiceRequestDetails>
                                <Box className="label">Request Date:</Box>
                                <Box className="value">{moment.utc(getServiceInfo.requestDate).tz(moment.tz.guess()).format("llll")}</Box>
                            </StyledServiceRequestDetails>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledServiceRequestDetails>
                                <Box className="label">Start Date:</Box>
                                <Box className="value">
                                    {getServiceInfo.startDateTime === EMPTY_DATETIME_VALUE
                                        ? "- -"
                                        : moment.utc(getServiceInfo.startDateTime).tz(moment.tz.guess()).format("llll")}
                                </Box>
                            </StyledServiceRequestDetails>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledServiceRequestDetails>
                                <Box className="label">End Date:</Box>
                                <Box className="value">
                                    {getServiceInfo.endDateTime === EMPTY_DATETIME_VALUE
                                        ? "- -"
                                        : moment.utc(getServiceInfo.endDateTime).tz(moment.tz.guess()).format("llll")}
                                </Box>
                            </StyledServiceRequestDetails>
                        </Grid>
                        {getServiceInfo.hostRemarks && (
                            <Grid item xs={12}>
                                <StyledServiceRequestDetails>
                                    <Box className="label">Provider Remarks:</Box>
                                </StyledServiceRequestDetails>
                                <ServiceDetailsRemarks remarksString={getServiceInfo.hostRemarks} />

                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                paddingTop: theme.spacing(1),
                            }}
                        >
                            <StyledServiceRequestDetails
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Box className="label">Provider Remakrs:</Box>

                            </StyledServiceRequestDetails>
                            <ServiceDetailsRemarks remarksString={getServiceInfo.providerRemarks} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: theme.spacing(1),
                        }}
                    >
                        <Grid item xs={12}>
                            <Button onClick={() => { setGetRoomDetails(JSON.parse(getServiceInfo.propertyRooms)); }}>View Rooms</Button>
                        </Grid>
                        {getRoomDetails.map((item, i) => {
                            const idx = i;
                            return (
                                <Grid item xs={12} sm={6} key={idx}>
                                    <Card>
                                        <CardHeader title={item.RoomLabel} />
                                        {item.ImgURL !== null ? (
                                            <CardMedia
                                                component="img"
                                                image={item.ImgURL}
                                            />
                                        ) : (
                                            <CardContent
                                                sx={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                <StyledEmptyStateContainer>
                                                    <BrokenImage />
                                                    <Box className="main">
                                                        No Image available
                                                    </Box>
                                                </StyledEmptyStateContainer>
                                            </CardContent>
                                        )}
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setOpenDialog(false)}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CompletedServicesDetails;
