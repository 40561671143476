import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

export const icalendarAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getBookings: builder.query({
            queryFn: async ({ iCalendarFK }) => {
                try {
                    const response = await API.get(
                        "serverlessBookingAPI",
                        `/icalendarFk?icalendarFk=${iCalendarFK}`,
                    );
                    return response;
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, arg) => [{
                type: "bookings",
                id: arg.iCalendarFK,
            }],
        }),

        pullBookings: builder.mutation({
            queryFn: async ({
                iCalendarFK,
            }) => {
                try {
                    const response = await API.get(
                        "serverlessBookingAPI",
                        `/pull?icalendarFk=${iCalendarFK}`,
                    );
                    return response;
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "bookings",
                id: arg.iCalendarFK,
            }],
        }),

        getICalendar: builder.query({
            queryFn: async ({
                propertyFk,
            }) => {
                try {
                    const response = await API.get("serverlessICalendarAPI", `/propertyFK?propertyFK=${propertyFk}`, {
                        body: "",
                    });
                    return response;
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, arg) => [{
                type: "icalendar",
                id: arg.propertyFk,
            }],
        }),
        addICalendar: builder.mutation({
            queryFn: async ({
                body,
            }) => {
                try {
                    const response = await API.post("serverlessICalendarAPI", "/new", {
                        body,
                    });

                    return response;
                } catch (error) {
                    const validationErrorsObj = error.response.data?.errors || {};
                    // const validationErrors = Object.keys(validationErrorsObj).map((key) => validationErrorsObj[key].join(", "));
                    const validationErrors = Object.keys(validationErrorsObj).map((key) => (key === "Url" ? "Enter a valid ICalendar URL." : validationErrorsObj[key].join(", ")));
                    const validationErrorsString = validationErrors.join(", ");

                    return {
                        error: {
                            data: error?.response?.data,
                            message: `An error occured: ${validationErrorsString || error.response.data?.message || error.message || "Unknown error"}`,
                        },
                    };
                }
            },
            invalidatesTags: ({ propertyFK }) => [{
                type: "icalendar",
                id: propertyFK,
            }],
        }),
        updateICalendar: builder.mutation({
            queryFn: async ({
                body,
                iCalerndarId,
            }) => {
                try {
                    const response = await API.put("serverlessICalendarAPI", "/id", {
                        body,
                        queryStringParameters: { id: iCalerndarId },
                    });

                    return response;
                } catch (error) {
                    const validationErrorsObj = error.response.data?.errors || {};
                    // const validationErrors = Object.keys(validationErrorsObj).map((key) => validationErrorsObj[key].join(", "));
                    const validationErrors = Object.keys(validationErrorsObj).map((key) => (key === "Url" ? "Enter a valid ICalendar URL." : validationErrorsObj[key].join(", ")));
                    const validationErrorsString = validationErrors.join(", ");

                    return {
                        error: {
                            data: error?.response?.data,
                            message: `An error occured: ${validationErrorsString || error.response.data?.message || error.message || "Unknown error"}`,
                        },
                    };
                }
            },
            invalidatesTags: ({ propertyFK }) => [{
                type: "icalendar",
                id: propertyFK,
            }],
        }),
    }),
});

export const {
    useGetBookingsQuery,
    usePullBookingsMutation,
    useGetICalendarQuery,
    useAddICalendarMutation,
    useUpdateICalendarMutation,
} = icalendarAPI;
