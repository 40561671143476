import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { useState } from "react";

export default function CustomPasswordField({
    label,
    onKeyDown,
    onChange,
    value,
}) {
    const [showPassword, setShowPassword] = useState(false);

    const iconAdornment = {
        endAdornment: (
            <InputAdornment position="end">
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </Stack>
            </InputAdornment>
        ),
    };

    return (
        <TextField
            label={label}
            size="small"
            type={showPassword ? "text" : "password"}
            value={value}
            fullWidth
            onKeyDown={onKeyDown}
            onChange={onChange}
            InputProps={iconAdornment}
        />
    );
}
