import { Delete, LinkOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Box, Dialog, DialogActions, DialogContent, Stack, styled, Typography, useTheme } from "@mui/material";

const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "borderColor",
})(({ theme, borderColor }) => ({
    ".MuiDialog-paper": {
        borderTopWidth: theme.spacing(1),
        borderTopColor: theme.palette.error.light,
        borderTopStyle: "solid",
    },
}));

export default function CustomRemoveDialog({
    open,
    title,
    message,
    cancelButtonText = "Cancel",
    okButtonText,
    onRemoveClick,
    onCancelClick,
    loading,
    icon,
}) {
    const theme = useTheme();
    const setIcon = () => {
        switch (icon) {
            case "unlink":
                return (
                    <LinkOff
                        sx={{
                            height: 64,
                            width: 64,
                            color: theme.palette.error.light,
                        }}
                    />
                );
            default:
                return (
                    <Delete
                        sx={{
                            height: 64,
                            width: 64,
                            color: theme.palette.error.light,
                        }}
                    />
                );
        }
    };
    return (
        <StyledDialog open={open}>
            <DialogContent>
                <Stack direction="row">
                    <Box
                        sx={{
                            marginRight: theme.spacing(3),
                        }}
                    >
                        {setIcon()}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6">{title}</Typography>
                        <Typography component="div" variant="body2">
                            {message}
                        </Typography>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{
                    borderTopStyle: "solid",
                    borderTopColor: theme.palette.grey.A400,
                    borderTopWidth: "1px",
                    padding: theme.spacing(2),
                }}
            >
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                        if (onCancelClick !== undefined) onCancelClick();
                    }}
                >
                    {cancelButtonText}
                </Button>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="small"
                    onClick={() => {
                        if (onRemoveClick !== undefined) onRemoveClick();
                    }}
                    color="error"
                >
                    {okButtonText}
                </LoadingButton>
            </DialogActions>
        </StyledDialog>
    );
}
