import { Edit, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyGetAccountsByIdQuery } from "../../features/auth/accountsAPI";
import { useDeleteActionMutation, useUpdateActionMutation } from "../../features/auth/actionsAPI";
import ActionModel from "./models/ActionModel";
import ActionReferenceModel from "./models/ActionReferenceModel";
import { useGetDevicesByPropertyIdQuery, useLazyGetDevicesByPropertyIdQuery } from "../../features/auth/devicesAPI";
import { useGetAllActionReferencesQuery } from "../../features/auth/actionReferenceAPI";
import { SEND_SMS_ACTION, TRIGGER_IFTTT_ACTION } from "./constants/ActionReferences";
import { NA_DEVICETYPEID } from "./constants/DeviceTypes";
// import { AddAction, UpdateAction } from "../../api/IoT/actions";

export default function UpdateAction({
    selectedAction,
    ruleId,
    propertyId,
    onClick,
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [actionRefList, setActionRefList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [selectedActionRefObject, setSelectedActionRefObject] = useState(ActionReferenceModel());
    const [actionRefDeviceList, setActionRefDeviceList] = useState([]);
    const [actionHasParam, setActionHasParam] = useState(false);
    const [selectedActionRefDeviceObject, setSelectedActionRefDeviceObject] = useState();
    const [actionObject, setActionObject] = useState(ActionModel());
    const [paramValue, setParamValue] = useState(16);
    const [addDisabled, setAddDisabled] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [paramUnitList, setParamUnitList] = useState([]);
    const [selectedTempUnit, setSelectedTempUnit] = useState(null);
    const [showEditAction, setShowEditAction] = useState(false);

    // might be queried from db too
    const parameterUnits = [
        {
            id: 1,
            measurement: "temperature",
            unit: "°C",
            condition: "Temperature must be between 16°C and 35°C",
            error: [16, 35],
        },
        {
            id: 2,
            measurement: "temperature",
            unit: "°F",
            condition: "Temperature must be between 60°F and 95°F",
            error: [60, 95],
        },
    ];

    // << rtk query
    const [updateActionData, {
        isLoading: isLoadingUpdateActionData,
        isFetching: isFetchingUpdateActionData,
    }] = useUpdateActionMutation({
        fixedCacheKey: `update-action-${ruleId}`,
    });
    const [
        triggerGetAccounts,
    ] = useLazyGetAccountsByIdQuery();
    // const {
    //     data: dataDevicesByProperty,
    //     isLoading: isLoadingGetDevicesByPropertyId,
    //     isFetching: isFetchingGetDevicesByPropertyId,
    // } = useGetDevicesByPropertyIdQuery({
    //     propertyId,
    // });
    const [triggerGetDevicesByPropertyId, {
        data: dataDevicesByProperty,
        isLoading: isLoadingGetDevicesByPropertyId,
        isFetching: isFetchingGetDevicesByPropertyId,
    }] = useLazyGetDevicesByPropertyIdQuery();
    const {
        data: dataAllActionReferences,
        isLoading: isLoadingGetAllActionReferences,
        isFetching: isFetchingGetAllActionReferences,
    } = useGetAllActionReferencesQuery();

    const disableControl = isLoadingGetDevicesByPropertyId
        || isFetchingGetDevicesByPropertyId
        || isLoadingGetAllActionReferences
        || isFetchingGetAllActionReferences
        || isLoadingUpdateActionData
        || isFetchingUpdateActionData
        || isLoadingGetDevicesByPropertyId;

    useEffect(() => {
        // get devices
        if (dataDevicesByProperty !== undefined) {
            setDeviceList(dataDevicesByProperty);
            setActionRefDeviceList((dataDevicesByProperty.filter((d) => d.deviceTypeId === selectedAction.deviceTypeId)));
            setSelectedActionRefDeviceObject((dataDevicesByProperty.filter((d) => d.deviceId === selectedAction.deviceId))[0]);
        }
    }, [dataDevicesByProperty]);

    useEffect(() => {
        // get action references
        if (dataAllActionReferences !== undefined) {
            setSelectedActionRefObject(dataAllActionReferences[0]);
            setActionRefList(dataAllActionReferences);
            setSelectedActionRefObject(
                dataAllActionReferences.filter(
                    (a) => a.actionReferenceId === selectedAction.actionReferenceId,
                )[0],
            );
        }
    }, [dataAllActionReferences]);
    // rtk query >>

    useEffect(() => {
        if (showEditAction) {
            triggerGetDevicesByPropertyId({
                propertyId,
            });
        }
    }, [showEditAction]);

    useEffect(() => {
        setParamValue(null);
        setParamUnitList(parameterUnits);
        setSelectedTempUnit(parameterUnits[0]);
        if (selectedAction.parameters != null) {
            const [v, u] = selectedAction.parameters.split(" ");
            setParamValue(v);
            setSelectedTempUnit((parameterUnits.filter((p) => p.unit === u)[0]));
            setActionHasParam(true);
        } else {
            setParamValue(null);
            setSelectedTempUnit(parameterUnits[0]);
        }
    }, []);

    const editActionHandler = async () => {
        setAddDisabled(true);
        setAddLoading(true);
        let partnerAccountObject = null;
        if (selectedActionRefDeviceObject != null) {
            // partnerAccountObject = await getAccountsbyId(
            //     selectedActionRefDeviceObject.partnerAccountId,
            // );
            triggerGetAccounts({
                id: selectedActionRefDeviceObject.partnerAccountId,
            }).then((d) => {
                partnerAccountObject = d;
            });
        }
        const actionObjectUpdate = {
            EventActionId: selectedAction.eventActionId,
            ActionReferenceId: selectedActionRefObject.actionReferenceId,
            RuleId: ruleId,
            DeviceId:
                selectedActionRefDeviceObject != null
                    ? selectedActionRefDeviceObject.deviceId
                    : null,
            PartnerAccountId:
                selectedActionRefDeviceObject != null
                    ? selectedActionRefDeviceObject.partnerAccountId
                    : null,
            PartnerId:
                partnerAccountObject != null
                    ? partnerAccountObject.partnerId
                    : null,
            Parameters:
                paramValue != null
                    ? `${paramValue} ${selectedTempUnit.unit}`
                    : null,
            IsActive: true,
        };
        setActionObject({ ...actionObject, actionObjectUpdate });
        await updateActionData({
            action: actionObjectUpdate,
        });
        setAddDisabled(false);
        setShowEditAction(false);
    };

    const actionHasParamChecker = (actionRefName) => {
        const nameArray = actionRefName.split(" ");
        if (nameArray[0] === "Set") {
            setActionHasParam(true);
        } else {
            setActionHasParam(false);
        }
    };

    const onChangeActionRefHandler = async (actionRefObject) => {
        setSelectedActionRefObject(actionRefObject);
        actionHasParamChecker(actionRefObject.name);
        const actionRefDevices = deviceList.filter(
            (d) => d.deviceTypeId === actionRefObject.deviceTypeId,
        );
        setActionRefDeviceList(actionRefDevices);
        if (actionRefDevices.length === 0) {
            setSelectedActionRefDeviceObject(null);
            if (actionRefObject.deviceTypeId !== NA_DEVICETYPEID) {
                setAddDisabled(true);
            } else {
                setAddDisabled(false);
            }
        } else {
            setSelectedActionRefDeviceObject(actionRefDevices[0]);
            setAddDisabled(false);
        }

        if (actionRefObject.state === null || !actionRefObject.state) {
            setParamValue(null);
        } else {
            setParamValue(16);
        }
    };

    return (
        <>
            <IconButton
                color="primary"
                size="small"
                onClick={() => {
                    if (onClick !== undefined) onClick();
                    setShowEditAction(true);
                }}
            >
                <Edit />
            </IconButton>

            <Dialog
                open={
                    showEditAction
                    // && deviceList.length > 0 && actionRefList.length > 0
                }
                fullWidth
                maxWidth="sm"
                fullScreen={isMobile}
            >
                <DialogTitle>Edit Action</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {deviceList.length <= 0 && (
                            <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>
                        )}
                        {deviceList.length > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <InputLabel>Choose an Action:</InputLabel>
                                    {actionRefList.length > 0 && actionRefList.includes(selectedActionRefObject) && (
                                        <Select
                                            disabled={disableControl}
                                            value={selectedActionRefObject}
                                            onChange={(e) => {
                                                onChangeActionRefHandler(e.target.value);
                                            }}
                                            fullWidth
                                            size="small"
                                        >
                                            {actionRefList.length > 0
                                                && actionRefList.map((a) => (
                                                    <MenuItem key={a.actionReferenceId} value={a}>
                                                        {a.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    )}
                                </Grid>
                                {actionRefDeviceList.length > 0 && actionRefDeviceList.includes(selectedActionRefDeviceObject) && (
                                    <Grid item xs={12}>
                                        <InputLabel>
                                            Choose your
                                            {" "}
                                            {actionRefDeviceList[0].name}
                                            :
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            disabled={addDisabled}
                                            value={selectedActionRefDeviceObject}
                                            onChange={(e) => {
                                                setSelectedActionRefDeviceObject(e.target.value);
                                            }}
                                            fullWidth
                                        >
                                            {actionRefDeviceList.map((d) => (
                                                <MenuItem key={d.deviceId} value={d}>
                                                    {d.displayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}
                                {(actionRefDeviceList.length === SEND_SMS_ACTION
                                && selectedActionRefObject.deviceTypeId !== TRIGGER_IFTTT_ACTION) && (
                                    <Grid item xs={8}>
                                        <InputLabel>
                                            No devices for that action in the property.
                                        </InputLabel>
                                    </Grid>
                                )}
                                {actionHasParam && paramUnitList.includes(selectedTempUnit) && (
                                    <>
                                        <Grid item>
                                            <TextField
                                                disabled={disableControl || addDisabled}
                                                sx={{ width: "20ch" }}
                                                label={selectedActionRefObject.name}
                                                value={paramValue}
                                                error={
                                                    paramValue < selectedTempUnit.error[0]
                                                    || paramValue > selectedTempUnit.error[1]
                                                }
                                                helperText={
                                                    paramValue.length === 0
                                                        ? "Required"
                                                        : selectedTempUnit.condition
                                                }
                                                onChange={(e) => {
                                                    setParamValue(e.target.value);
                                                }}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                size="small"
                                                disabled={disableControl || addDisabled}
                                                value={selectedTempUnit}
                                                onChange={(e) => {
                                                    setSelectedTempUnit(e.target.value);
                                                }}
                                            >
                                                {paramUnitList.map((u) => (
                                                    <MenuItem key={u.id} value={u}>
                                                        {u.unit}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => setShowEditAction(false)}
                        disabled={disableControl}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        onClick={editActionHandler}
                        disabled={disableControl || addDisabled}
                        loading={addLoading}
                        loadingPosition="end"
                        endIcon={<Save />}
                    >
                        Update
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
