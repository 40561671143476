import { Alert, Box, Button, Grid, Skeleton, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetPropertyQuery } from "../../features/auth/hostAPI";
import PropertyStatus from "./PropertyStatus";
import UpdatePropertyForm from "./UpdatePropertyForm";
import { showLoader } from "../../features/auth/authSlice";

const StyledPropertyDetail = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    "& .label": {
        color: theme.palette.text.secondary,
        flexShrink: 0,
    },
    "& .value": {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        color: theme.palette.text.primary,
    },

    [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        "& .label": {
            width: 200,
        },
        "& .value": {
            paddingLeft: 0,
        },
    },
}));

export default function ViewProperty({ propertyId, RemoveProperty }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const [showPropertyForm, setShowPropertyForm] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const {
        data: dataGetProperty,
        isLoading: isLoadingGetProperty,
        isFetching: isFetchingGetProperty,
    } = useGetPropertyQuery({ propertyId });

    const callbackSuccess = () => {
        setShowSuccessAlert(true);
    };

    const viewData = () => (
        <Grid container spacing={1}>
            {dataGetProperty ? (
                <>
                    <Grid item xs={12}>
                        <StyledPropertyDetail>
                            <Box className="label">Property:</Box>
                            <Box className="value">{dataGetProperty.propertyName}</Box>
                        </StyledPropertyDetail>
                        <StyledPropertyDetail>
                            <Box className="label">Status:</Box>
                            <Box className="value">
                                <PropertyStatus
                                    propertyId={propertyId}
                                />
                            </Box>
                        </StyledPropertyDetail>
                        <StyledPropertyDetail>
                            <Box className="label">Street Address:</Box>
                            <Box className="value">{dataGetProperty.streetAddress1}</Box>
                        </StyledPropertyDetail>
                        <StyledPropertyDetail>
                            <Box className="label">City:</Box>
                            <Box className="value">{dataGetProperty.city}</Box>
                        </StyledPropertyDetail>
                        <StyledPropertyDetail>
                            <Box className="label">State:</Box>
                            <Box className="value">{dataGetProperty.state}</Box>
                        </StyledPropertyDetail>
                        <StyledPropertyDetail>
                            <Box className="label">Zip:</Box>
                            <Box className="value">{dataGetProperty.postalCode}</Box>
                        </StyledPropertyDetail>
                    </Grid>
                    <Grid item xs={12} textAlign={isMobile ? "center" : "right"}>
                        <Button
                            variant="contained"
                            fullWidth={!!isMobile}
                            onClick={() => {
                                setShowPropertyForm(true);
                                setShowSuccessAlert(false);
                            }}
                            sx={{ marginTop: 2 }}
                        >
                            Update Property
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={2}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <Stack spacing={1}>
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                            <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                        </Stack>
                    </Grid>
                </>
            )}
        </Grid>
    );
    return (
        <>
            {showSuccessAlert && (
                <Alert
                    sx={{ marginBottom: 2 }}
                    onClose={() => {
                        setShowSuccessAlert(false);
                    }}
                    severity="success"
                >
                    Basic information successfully updated.
                </Alert>
            )}
            {showPropertyForm ? (
                <UpdatePropertyForm
                    loadedProperty={dataGetProperty}
                    setShowPropertyForm={setShowPropertyForm}
                    callbackSuccess={callbackSuccess}
                />
            ) : (
                viewData()
            )}
        </>
    );
}
/*
    return showPropertyForm ? (
        <UpdatePropertyForm loadedProperty={property} setShowPropertyForm={setShowPropertyForm} />
    ) : (
        viewData()
    );
*/
