import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDeleteDeviceConditionMutation, useDeleteGroupConditionMutation, useDeleteRelativeTimeConditionMutation } from "../../features/auth/conditionsAPI";

export default function DeleteCondition({
    ruleId,
    conditionType,
    conditionId,
    rule,
    onClick,
}) {
    const [deleteDeviceCondition, {
        isLoading: isLoadingDeleteDeviceCondition,
        isFetching: isFetchingDeleteDeviceCondition,
    }] = useDeleteDeviceConditionMutation({
        fixedCacheKey: `delete-device-condition-${ruleId}`,
    });

    const [deleteRelativeTimeCondition, {
        isLoading: isLoadingDeleteRelativeTimeCondition,
        isFetching: isFetchingDeleteRelativeTimeCondition,
    }] = useDeleteRelativeTimeConditionMutation({
        fixedCacheKey: `delete-relative-time-condition-${ruleId}`,
    });

    const [deleteGroupCondition, {
        isLoading: isLoadingDeleteGroupCondition,
        isFetching: isFetchingDeleteGroupCondition,
    }] = useDeleteGroupConditionMutation({
        fixedCacheKey: `delete-group-condition-${ruleId}`,
    });

    const disableControl = isLoadingDeleteDeviceCondition
        || isFetchingDeleteDeviceCondition
        || isLoadingDeleteRelativeTimeCondition
        || isFetchingDeleteRelativeTimeCondition
        || isLoadingDeleteGroupCondition
        || isFetchingDeleteGroupCondition;

    const DeleteConditionHandler = async (type, id) => {
        switch (type) {
            case "Device":
                await deleteDeviceCondition({ id });
                break;
            case "Time":
                await deleteRelativeTimeCondition({ id });
                break;
            case "Rule Group":
                await deleteGroupCondition({ id });
                break;
            default:
                break;
        }
    };

    return (
        <IconButton
            disabled={rule.isPreMadeRule || disableControl}
            color="error"
            size="small"
            onClick={() => {
                if (onClick !== undefined) onClick();
                DeleteConditionHandler(conditionType, conditionId);
            }}
        >
            <Delete />
        </IconButton>
    );
}
