import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const propertyAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        addToIoTPropertyReference: builder.mutation({
            queryFn: async ({ property }) => {
                try {
                    const myInit = {
                        body: property,
                    };
                    const response = await API.post(
                        "IoTAPI",
                        "/propertyReferences",
                        myInit,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
    }),
});

export const {
    useAddToIoTPropertyReferenceMutation,
} = propertyAPI;
