import { useAuthenticator } from "@aws-amplify/ui-react";
import { Alert, Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setLogInProcess, showLoader, updateSignUpProcessFlow } from "../features/auth/authSlice";
import { LOGIN_STEP, LOGIN_VERIFYOTP_STEP } from "../utils/LoginStatusCode";
import {
    CheckRegistration,
    STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE,
    STATUS_CODE_ADD_CALENDAR_COMPLETE,
    STATUS_CODE_ADD_PROPERTY_COMPLETE,
    STATUS_CODE_OTP_SUCCESS,
    STATUS_CODE_PCR_COMPLETE,
    STATUS_CODE_PROFILE_COMPLETE,
} from "../utils/SignUpStatusCode";
import VerifyOtp from "./VerifyOtp";
import HouseCard from "./helper/HouseCard";
import ChangePassword from "./profile/ChangePassword";
import CustomPasswordField from "./shared/custom_password";
import CustomPhoneInput from "./shared/custom_phoneinput";

export default function Login() {
    const theme = useTheme();
    const [phone, setPhone] = useState("");
    const [user, setUser] = useState("");
    const currentLogInProcess = useSelector((state) => state.auth.logInProcess);
    const dispatch = useDispatch();
    const [session] = useState(null);
    const [errorPhoneField, setErrorPhoneField] = useState("");
    const [password, setPassword] = useState("");
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [showForceChangePassword, setShowForceChagnePassword] = useState(false);
    const [userSession, setUserSession] = useState(null);

    const [formIsComplete, setFormIsComplete] = useState(false);

    useEffect(() => {
        if (route === "authenticated") {
            navigate("/property");
            dispatch(setLogInProcess(""));
        } else {
            dispatch(setLogInProcess(LOGIN_STEP));
        }
    }, [route, navigate, dispatch]);

    useEffect(() => {
        if (phone === null) return;
        if (phone.length !== 0 && password.length !== 0) setFormIsComplete(true);
        else setFormIsComplete(false);
    }, [phone, password]);

    const SignIn = async () => {
        setHasSubmitted(true);
        dispatch(showLoader({ open: true, message: "Please wait..." }));
        setErrorPhoneField("");

        try {
            const _user = phone.replace("+", "").replace(/\s+/g, "").trim();
            setUser(_user);
            const userAuth = await Auth.signIn(_user, password);

            if (userAuth.challengeName === "NEW_PASSWORD_REQUIRED") {
                /// show change password
                setShowForceChagnePassword(true);
                setUserSession(userAuth);
            } else {
                const statusObj = await CheckRegistration();
                dispatch(updateSignUpProcessFlow({ statusCode: statusObj.statusCode }));
                if (statusObj.statusCode !== null) {
                    switch (statusObj.statusCode) {
                        case STATUS_CODE_OTP_SUCCESS:
                        case STATUS_CODE_PROFILE_COMPLETE:
                        case STATUS_CODE_ADD_PROPERTY_COMPLETE:
                        case STATUS_CODE_ADD_CALENDAR_COMPLETE:
                        case STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE:
                        case STATUS_CODE_PCR_COMPLETE:
                            window.location.replace("/complete-registration");
                            break;
                        default:
                            window.location.replace("/property");
                            break;
                    }
                }
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                dispatch(setLogInProcess(LOGIN_VERIFYOTP_STEP));
            } else {
                setErrorPhoneField(err.message);
                setHasSubmitted(false);
            }

            dispatch(showLoader({ open: false, message: "" }));
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const handleEnterKeyPress = (e) => {
        if (e.key !== "Enter") return;
        if (hasSubmitted) return;
        if (!formIsComplete) return;
        SignIn();
    };

    return (
        <HouseCard px={8} py={4}>
            {(!showForceChangePassword && currentLogInProcess === LOGIN_STEP) && (
                <Box height="100%" display="flex" flexDirection="column">
                    <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Log In
                                </Typography>
                            </Grid>
                            {(errorPhoneField !== "") && (
                                <Grid item xs={12}>
                                    <Alert severity="error" onClose={() => setErrorPhoneField("")}>{errorPhoneField}</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <CustomPhoneInput
                                    autoFocus
                                    label="Phone"
                                    fullWidth
                                    onChange={(e) => {
                                        setPhone(e);
                                        setErrorPhoneField("");
                                    }}
                                    onKeyPress={handleEnterKeyPress}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomPasswordField
                                    label="Password"
                                    onKeyDown={handleEnterKeyPress}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => SignIn()}
                                    disabled={!formIsComplete}
                                >
                                    Login
                                </Button>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle2">
                                    <Link to="/forgot-password">Forgot your password?</Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle2">
                                    Need an account?&nbsp;
                                    <Link to="/signup">Sign Up</Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Divider />
                    </Box>
                    <Box textAlign="center" mt={3}>
                        {currentLogInProcess === LOGIN_STEP && (
                            <Typography variant="subtitle2">
                                Log In as a&nbsp;
                                <Link to={`${process.env.REACT_APP_SERVICE_PROVIDER_URL}/login`}>Service Provider</Link>
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
            {currentLogInProcess === LOGIN_VERIFYOTP_STEP && (
                <>
                    <Alert
                        severity="warning"
                        sx={{
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        Your account is not yet confirmed.
                        Please provide an OTP that already sent to your mobile phone or you can choose to resend a new one.
                    </Alert>
                    <VerifyOtp
                        phoneNumber={user}
                        callbackProceedLogin={SignIn}
                        isUserNotConfirmed
                    />
                </>
            )}
            {showForceChangePassword && (
                <ChangePassword
                    userSessionForceChangePassword={userSession}
                    callbackGoBackToLoginPage={() => setShowForceChagnePassword(false)}
                />
            )}
        </HouseCard>
    );
}
