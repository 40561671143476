import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";

export default function CustomPhoneInput({
    autoFocus,
    label,
    fullWidth,
    onChange,
    onKeyPress,
    onKeyDown,
}) {
    const [phone, setPhone] = useState("");

    const onInputChange = (phoneNumber, info) => {
        setPhone(info.numberValue);
        onChange(info.numberValue);
    };

    return (
        <MuiTelInput
            autoFocus={autoFocus}
            defaultCountry="US"
            label={label}
            fullWidth={fullWidth}
            value={phone}
            onChange={onInputChange}
            onKeyPress={onKeyPress}
            focusOnSelectCountry
            onKeyDown={onKeyDown}
        />
    );
}
