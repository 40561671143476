import { LocalizationProvider, TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export default function CustomTimeField(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                }}
            />
        </LocalizationProvider>
    );
}
