import { Box, Button } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

import { useState } from "react";

export default function ExpandableLink({ href, shrunkWidth = 200 } = {}) {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <>
            <a
                style={isExpanded ? {
                    wordBreak: "break-all",
                } : {
                    display: "inline-block",
                    textOverflow: "clip",
                    overflow: "hidden",
                    verticalAlign: "top",
                    whiteSpace: "nowrap",
                    width: shrunkWidth,
                }}
                href={href}
            >
                {href}
            </a>
            {
                !isExpanded && (
                    <Box position="relative" display="inline" width={150}>
                        {/* invisible character, this is important for alignment */}
                        &nbsp;
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{
                                position: "absolute",
                                left: 8,
                                top: "50%",
                                transform: "translate(0, -50%)",
                                display: "inline-block",
                                minWidth: 0,
                                lineHeight: 0,
                                minHeight: 0,
                            }}
                            onClick={() => { setIsExpanded(true); }}
                        >
                            <MoreHoriz fontSize="small" sx={{ height: "100%" }} />
                        </Button>
                    </Box>
                )
            }
        </>
    );
}
