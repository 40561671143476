import { useAuthenticator } from "@aws-amplify/ui-react";
import { AppBar, Button, Toolbar, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: "none",
}));

export default function Main() {
    const navigate = useNavigate();

    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if (route === "authenticated") {
            navigate(from, { replace: true });
            navigate("/dashboard");
        }
    }, [route]);

    return (
        <>
            <StyledAppBar>
                <Toolbar>

                    <Typography
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        Hostly.AI
                    </Typography>
                    <Button
                        variant="text"
                        component={RouterLink}
                        to="/signup"
                    >
                        Sign Up
                    </Button>
                    <Button
                        variant="text"
                        component={RouterLink}
                        to="/login"
                    >
                        Log In
                    </Button>
                </Toolbar>
            </StyledAppBar>
            Main Landing Page
        </>
    );
}
