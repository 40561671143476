import { Info, Warning } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useState } from "react";

export default function CustomDialogIcon({
    dialogType = "info",
}) {
    const theme = useTheme();
    const [iconSx, setIconSx] = useState({
        height: 64,
        width: 64,
        color: theme.palette.info.light,
    });

    useState(() => {
        let iconColor = theme.palette.info.light;

        switch (dialogType) {
            case "info":
                iconColor = theme.palette.info.light;
                break;
            case "warning":
                iconColor = theme.palette.warning.light;
                break;
            case "error":
                iconColor = theme.palette.error.light;
                break;
            default:
                iconColor = theme.palette.info.light;
                break;
        }

        setIconSx((prev) => ({ ...prev, color: iconColor }));
    }, [dialogType]);

    if (dialogType === "warning") {
        return (
            <Warning
                sx={iconSx}
            />
        );
    }
    if (dialogType === "info") {
        return (
            <Info
                sx={iconSx}
            />
        );
    }
    if (dialogType === "error") {
        return (
            <Info
                sx={iconSx}
            />
        );
    }
}
