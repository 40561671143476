import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import CustomDialogIcon from "./CustomDialogIcon";

const StyledDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "borderColor",
})(({ theme, borderColor }) => ({
    ".MuiDialog-paper": {
        borderTopWidth: theme.spacing(1),
        borderTopColor: borderColor,
        borderTopStyle: "solid",
    },
}));

export default function CustomAlertDialog({
    open,
    title,
    message,
    dialogType = "info",
    okButtonText = "Ok",
    cancelButtonText = "Cancel",
    callbackOk,
    callbackCancel,
    cancelLoading,
    okLoading,
    cancelDisabled,
    okDisabled,
}) {
    const theme = useTheme();

    const dialogBorderColor = (type) => {
        switch (type) {
            case "info":
                return theme.palette.info.light;
            case "warning":
                return theme.palette.warning.light;
            case "error":
                return theme.palette.error.light;
            default:
                return theme.palette.info.light;
        }
    };

    return (
        <StyledDialog open={open} borderColor={dialogBorderColor(dialogType)}>
            <DialogContent>
                <Stack direction="row">
                    <Box
                        sx={{
                            marginRight: theme.spacing(3),
                        }}
                    >
                        <CustomDialogIcon dialogType={dialogType} />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6">{title}</Typography>
                        <Typography
                            component="div"
                            variant="body2"
                            sx={{ paddingTop: "8px" }}
                        >
                            {message}
                        </Typography>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions
                sx={{
                    borderTopStyle: "solid",
                    borderTopColor: theme.palette.grey.A400,
                    borderTopWidth: "1px",
                    padding: theme.spacing(2),
                }}
            >
                {callbackCancel != null && (
                    <LoadingButton
                        disabled={cancelDisabled}
                        loading={cancelLoading}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            if (callbackCancel !== undefined) callbackCancel();
                        }}
                    >
                        {cancelButtonText}
                    </LoadingButton>
                )}
                {callbackOk != null && (
                    <LoadingButton
                        disabled={okDisabled}
                        loading={okLoading}
                        variant="contained"
                        size="small"
                        onClick={() => {
                            if (callbackOk !== undefined) callbackOk();
                        }}
                    >
                        {okButtonText}
                    </LoadingButton>
                )}
            </DialogActions>
        </StyledDialog>
    );
}
