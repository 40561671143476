import { Event, PersonOutline, Phone, Refresh, Settings, TableView } from "@mui/icons-material";
import { Alert, Chip, Grid, IconButton, Skeleton, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllAutomatedRequestListQuery } from "../../features/auth/hostAPI";
import { ServiceStatusColorCode } from "../../utils/ServiceStatusColorCode";
import { StyledEmptyStateContainer } from "../helper/EmptyStateContainer";
import { StyleTableContainer } from "../helper/StyleTable";
import AddService from "./AddService";
import CancelServiceRequest from "./CancelServiceReqeust";

const StyledGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "& .MuiSvgIcon-root": {
        color: theme.palette.grey[500],
    },
}));

function ServiceProviderPage({ propertyInfo }) {
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const [showSuccessInvite, setShowSuccessInvite] = useState(false);

    const {
        data: dataAutomatedRequestList,
        error: errorAutomatedRequestList,
        isLoading: isLoadingAutomatedRequestList,
        isFetching: isFetchingAutomatedRequestList,
        isSuccess: isSuccessAutomatedRequestList,
        isError: isErrorAutomatedRequestList,
        refetch: refetchAutomatedRequestList } = useGetAllAutomatedRequestListQuery({ propertyFK: propertyInfo.id });

    useEffect(() => {
        setShowSuccessInvite(false);
    }, []);

    const callbackAddServiceSuccess = () => {
        setShowSuccessInvite(true);
    };

    const isGettingData = isLoadingAutomatedRequestList || isFetchingAutomatedRequestList;

    const statusDisplay = (s) => {
        let statusColorCode = 0;
        let statusName = "";
        let fontColor = theme.palette.common.black;

        if (s.saR_StatusId === 1) {
            statusColorCode = s.sR_StatusId;
            statusName = s.sR_Status;
            if (s.sR_StatusId === 3 || s.sR_StatusId === 4) fontColor = theme.palette.common.white;
        } else {
            if (s.saR_StatusId === 2 || s.saR_StatusId === 3) {
                statusColorCode = 4;
                fontColor = theme.palette.common.white;
            } else statusColorCode = s.saR_StatusId;
            statusName = s.saR_Status;
        }

        if (statusName === "") return <>&nbsp;</>;

        return (
            <Chip
                size="small"
                label={statusName}
                sx={{
                    backgroundColor: ServiceStatusColorCode({ statusCode: statusColorCode }),
                    color: fontColor,
                }}
            />
        );
    };

    return (
        <Grid container spacing={2} sx={{ width: "100%" }}>
            {showSuccessInvite && (
                <Grid item xs={12}>
                    <Alert
                        severity="success"
                        onClose={() => setShowSuccessInvite(false)}
                    >
                        Invite sent.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={6}>
                <AddService
                    propertyInfo={propertyInfo}
                    callbackSuccess={callbackAddServiceSuccess}
                />
            </Grid>
            <Grid
                item
                xs={6}
                sx={{ textAlign: "right" }}
            >
                <IconButton
                    color="primary"
                    onClick={() => refetchAutomatedRequestList()}
                >
                    <Refresh />
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <StyleTableContainer>
                    <Table size="small">
                        {/* <TableHead>
                            <TableRow hover>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {isErrorAutomatedRequestList && (
                                <TableRow>
                                    <TableCell
                                        colSpan={isMobile ? 3 : 5}
                                        sx={{ textAlign: "center" }}
                                    >
                                        Error:
                                        {" "}
                                        {errorAutomatedRequestList.message}
                                    </TableCell>
                                </TableRow>
                            )}
                            {isGettingData && (
                                <TableRow>
                                    <TableCell colSpan={isMobile ? 3 : 5} sx={{ textAlign: "center" }}>
                                        <Skeleton
                                            width="100%"
                                            height={50}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!isGettingData && dataAutomatedRequestList?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={isMobile ? 3 : 5} sx={{ textAlign: "center" }}>
                                        <StyledEmptyStateContainer>
                                            <TableView />
                                            <div className="main">
                                                No service provider(s) assigned.
                                            </div>
                                            <div className="sub">
                                                Would you like to add one now?
                                            </div>
                                            <AddService
                                                propertyInfo={propertyInfo}
                                                callbackSuccess={callbackAddServiceSuccess}
                                            />
                                        </StyledEmptyStateContainer>
                                    </TableCell>
                                </TableRow>
                            )}
                            {dataAutomatedRequestList?.length > 0 && (
                                dataAutomatedRequestList.map((s, i) => {
                                    const idx = i;
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <StyledGrid
                                                                item
                                                                xs={12}
                                                                sm={3}
                                                            >
                                                                <PersonOutline />
                                                                <b>{s.name}</b>
                                                            </StyledGrid>
                                                            <StyledGrid
                                                                item
                                                                xs={12}
                                                                sm={3}
                                                            >
                                                                <Phone />
                                                                <Typography component="span" variant="body2" color="text.secondary">
                                                                    {s.phoneNumber}
                                                                </Typography>
                                                            </StyledGrid>
                                                            <StyledGrid
                                                                item
                                                                xs={12}
                                                                sm={3}
                                                            >
                                                                <Settings />
                                                                {s.serviceType}
                                                            </StyledGrid>
                                                            {s.dateOfRequest !== null && (
                                                                <StyledGrid item xs={12} sm={3}>
                                                                    <Event />
                                                                    {`${s.convertedDateOfRequest} @ ${s.convertedTimeOfRequest}`}
                                                                </StyledGrid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1}>
                                                            <StyledGrid item xs={12} sm={4}>
                                                                {statusDisplay(s)}
                                                            </StyledGrid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} textAlign="right">
                                                        <CancelServiceRequest
                                                            sr={s}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>

                                    );
                                })
                            )}
                        </TableBody>
                    </Table>
                </StyleTableContainer>
            </Grid>
        </Grid>
    );
}

export default ServiceProviderPage;
