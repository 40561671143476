import { API } from "aws-amplify";
import { useState, useEffect } from "react";
import { Button, Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { showLoader } from "../../features/auth/authSlice";
import { UpdateRuleState } from "../../api/IoT/rules";
import { useUpdateSignUpProcessMutation } from "../../features/auth/hostAPI";
import { STATUS_CODE_TESTALERT_COMPLETE } from "../../utils/SignUpStatusCode";

const alertIndexes = [1, 2, 3, 4, 5, 6];
const alerts = {
    1: {
        name: "Post check-out routine ongoing",
        description:
            "Activates every X minutes after check-out. Checks the states of all devices in the property. Alerts received during this routine will contain [Post check-out] in the SMS. If there are no alerts, an SMS indicating that there are no issues will also be sent.",
        message: "",
    },
    2: {
        name: "Guests have checked in",
        description:
            "When a check in/out door sensor is alerted for the first time within 30 minutes of the check-in time.",
    },
    3: {
        name: "Unexpected entry",
        description:
            "When at least one door sensor is triggered while the property is vacant or in between bookings.",
    },
    4: {
        name: "A door was left open",
        description:
            "When at least one door sensor is left open while the property is vacant or in between bookings.",
    },
    5: {
        name: "Unexpected movement",
        description:
            "When any motion sensor is triggered while the property is vacant or in between bookings.",
    },
    6: {
        name: "Unexpected AC use",
        description:
            "When any AC thermostat is triggered while the property is vacant or in between bookings.",
    },
};
const initialAlertState = Object.fromEntries(alertIndexes.map((i) => [i, {
    isButtonPressed: false,
    isChecked: true,
}]));

export default function TestAlertButton({ propertyId }) {
    const [buttonIsLoading, setButtonIsLoading] = useState(false);
    const [alertState, setAlertState] = useState(initialAlertState);
    const [property, setProperty] = useState(null);
    const dispatch = useDispatch();
    const [updateSignUpProcess, {
        isLoading: isLoadingUpdateSignUpProcess,
    }] = useUpdateSignUpProcessMutation();

    useEffect(() => {
        // get property info
        const getProperty = async () => {
            const p = await API.get("UserPropertyAPI", `/${propertyId}`);
            setProperty(p);
        };
        getProperty().catch(() => {
        });
    }, [propertyId]);

    const sendSMSTestAlert = async (index) => {
        setAlertState((prev) => ({ ...prev, [index]: { ...prev[index], isButtonPressed: true } }));
        dispatch(showLoader({ open: true, message: "Sending test alert..." }));
        let message = "";
        if (index === 1) {
            message = `Post check-out routine is finished. No issues found in ${property.propertyName}.`;
        } else {
            message = `${property.propertyName} : ${alerts[index].name}`;
        }

        API.post("PublishToSNSApi", "/SendMessageToHost", {
            body: {
                message,
            },
        }).then(() => {
            dispatch(showLoader({ open: false, message: "" }));
        }).catch(() => {
            dispatch(showLoader({ open: false, message: "" }));
        });
    };

    const updateSignUpFlow = async (statusCode) => {
        await API.put(
            "UserAPI",
            `/updateSignUpProcessStatus?statusCode=${statusCode}`,
        )
            .then((r) => r)
            .catch((e) => e);
    };

    const onCheckboxClick = (event) => {
        const index = Number(event.target.name);
        setAlertState((prev) => ({ ...prev, [index]: { ...prev[index], isChecked: event.target.checked } }));
    };

    const onProceedClick = async () => {
        setButtonIsLoading(true);
        const selectedAlerts = alertIndexes.filter((i) => alertState[i]);
        selectedAlerts.forEach((i) => {
            const premadeRuleObject = {
                rule: i,
                state: alertState[i].isChecked,
                propertyFk: propertyId,
            };
            UpdateRuleState(premadeRuleObject);
        });
        await updateSignUpFlow(STATUS_CODE_TESTALERT_COMPLETE);
        setButtonIsLoading(false);
        window.location.replace("/property");
    };

    return (
        <div>
            These are the alerts that will be sent to your phone when certain events occur.
            All alerts are enabled by default. You may deselect the alerts that you want to disable for now.
            You can also click on &quot;Test&quot; to receive a sample SMS.
            <br />
            <table cellSpacing={0} cellPadding={10}>
                <tbody>
                    {alertIndexes.map((index) => (
                        <tr key={index} style={{ verticalAlign: "top" }}>
                            <td style={{ textAlign: "center" }}>
                                <Checkbox checked={alertState[index].isChecked} onChange={onCheckboxClick} name={index.toString()} />
                            </td>
                            <td style={{ color: !alertState[index].isChecked && "#777", textDecoration: !alertState[index].isChecked && "line-through" }}>
                                <div style={{ fontWeight: "bold" }}>{`${alerts[index].name}: `}</div>
                                {`${alerts[index].description}`}
                            </td>
                            <td style={{ textAlign: "center" }}>
                                <Button
                                    type="button"
                                    onClick={() => sendSMSTestAlert(index)}
                                    variant="contained"
                                    color="primary"
                                    disabled={alertState[index].isButtonPressed}
                                >
                                    Test
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <LoadingButton
                variant="contained"
                loading={buttonIsLoading}
                loadingPosition="center"
                onClick={onProceedClick}
                sx={{ marginBottom: "40px" }}
                color="primary"
            >
                Proceed step
            </LoadingButton>
        </div>
    );
}
