import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function DeviceAuthSuccessPage() {
    const navigate = useNavigate();
    const url = new URL(window.location.href);
    const [searchParams, setSearchParams] = useSearchParams();
    const { id } = useParams();
    const { partnerId } = useParams();

    useEffect(() => {
        // const propertyUrl = url.pathname.replace("/deviceauth", "") + url.search;

        // navigate(propertyUrl);

        const postMsg = {
            type: "auth-query-param",
            code: searchParams.get("code"),
            state: searchParams.get("state"),
        };

        window.parent.postMessage(postMsg);
    }, [searchParams]);

    return <>Please wait...</>;
}
