import { HelpOutline } from "@mui/icons-material";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export default function ClickableHelpButton({ children }) {
    const [isOpenDueToClick, setIsOpenDueToClick] = useState(false);
    const [isOpenDueToHover, setIsOpenDueToHover] = useState(false);

    const isOpen = isOpenDueToClick || isOpenDueToHover;

    return (
        <ClickAwayListener onClickAway={() => setIsOpenDueToClick(false)}>
            <Tooltip
                title={children}
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={() => setIsOpenDueToClick(false)}
                open={isOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onMouseEnter={() => setIsOpenDueToHover(true)}
                onMouseLeave={() => setIsOpenDueToHover(false)}
            >
                <IconButton
                    aria-label="help"
                    style={{ marginBottom: "3px", marginTop: "3px" }}
                    onClick={() => setIsOpenDueToClick((currentValue) => !currentValue)}
                >
                    <HelpOutline color={isOpenDueToClick ? "primary" : "default"} />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
}
