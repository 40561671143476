import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    TextField,
    Switch,
    FormControl,
    InputLabel,
    FormControlLabel,
    NativeSelect,
    useTheme,
    useMediaQuery,
    MenuItem,
    Select,
    FormLabel,
    styled,
    Typography,
} from "@mui/material";
import { API, Auth } from "aws-amplify";
import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { SIGNUP_UPDATEPROFILE_STEP } from "../SignUp";
import {
    setSignUpProcess,
    setUserInfo,
    showLoader,
} from "../../features/auth/authSlice";
import CustomTimeField from "../helper/CustomTimeField";

const CheckInput = (val) => {
    const re = /^[A-Za-z\s]*$/;

    if (val === "" || re.test(val)) {
        return true;
    }
    return false;
};

const StyledSelectLocalTimeZone = styled(Select)(({ theme }) => ({
    "option.selected": {
        backgroundColor: theme.palette.background.default,
    },
}));

export default function MyProfile({ callbackRequire }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [profile, setProfile] = useState({
        username: "",
        firstName: "",
        lastName: "",
        standardClockInTime: null,
        standardClockOutTime: null,
        isTomorrowReportActive: false,
        tomorrowReportTimeSpan: null,
        localTimeZoneDatabaseName: "",
    });
    const [enableUpdateButton, setEnableUpdateButton] = useState(false);
    const currentSignUpProcess = useSelector((state) => state.auth.signUpProcess);
    const dispatch = useDispatch();
    const authenticationType = useSelector(
        (state) => state.auth.authenticationType,
    );
    const navigate = useNavigate();
    const [showSuccessALert, setShowSuccessAlert] = useState(false);
    const disregardDesktopView = callbackRequire !== undefined;

    const timeZoneNames = useMemo(() => {
        const tzNames = moment.tz.names();
        const tzNamesWithOffset = tzNames.map((tzName) => {
            const offset = moment.tz(tzName).format("Z");
            return {
                name: tzName,
                label: `(UTC${offset}) ${tzName}`,
            };
        });
        const sortByZone = (a, b) => {
            const [ahh, amm] = a.label.split("UTC")[1].split(")")[0].split(":");
            const [bhh, bmm] = b.label.split("UTC")[1].split(")")[0].split(":");
            return (+ahh * 60 + amm) - (+bhh * 60 + bmm);
        };
        const sortedTzNamesWithOffset = tzNamesWithOffset.sort(sortByZone);
        const sortedTzNamesWithOffsetAndDefault = ([{ name: "", label: "" }]).concat(sortedTzNamesWithOffset);
        return sortedTzNamesWithOffsetAndDefault;
    }, []);

    function updateProfileFromUserInfo() {
        setProfile((prev) => ({
            ...prev,
            username: userInfo.username,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            isTomorrowReportActive: userInfo.isTomorrowReportActive,
            localTimeZoneDatabaseName: userInfo.localTimeZoneDatabaseName,
            standardClockInTime: userInfo.isStandardTimeUpdated
                ? moment(userInfo.standardCheckInTime, "h:mm a")
                : "",
            standardClockOutTime: userInfo.isStandardTimeUpdated
                ? moment(userInfo.standardCheckOutTime, "h:mm a")
                : "",
            tomorrowReportTimeSpan: userInfo.isTomorrowReportActive || userInfo.isStandardTimeUpdated
                ? moment(userInfo.tomorrowReportTimeSpan, "h:mm a")
                : "",
        }));
    }

    useEffect(updateProfileFromUserInfo, [userInfo]);

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true,
        }).then((r) => {
            setProfile((prev) => ({
                ...prev,
                username: r.username,
            }));
        });
    }, []);

    useEffect(() => {
        const validateFirstName = profile.firstName.length !== 0;
        const validateLastName = profile.lastName.length !== 0;
        const validateCheckInTime = profile.standardClockInTime !== null;
        const validateCheckOutTime = profile.standardClockOutTime !== null;

        const validateTomorrowReportInputs = ((profile.isTomorrowReportActive === true
            && profile.tomorrowReportTimeSpan != null)
            || (profile.isTomorrowReportActive === false)) && profile.localTimeZoneDatabaseName.length !== 0;

        if (
            validateFirstName
            && validateLastName
            && validateCheckInTime
            && validateCheckOutTime
            && validateTomorrowReportInputs
        ) {
            setEnableUpdateButton(true);
        } else setEnableUpdateButton(false);
    }, [profile]);

    const handleUpdateUser = async () => {
        setShowSuccessAlert(false);
        dispatch(showLoader({ open: true, message: "Updating your profile. Please wait..." }));
        await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
            given_name: profile.firstName,
            family_name: profile.lastName,
        })
            .then((res) => {
                if (res === "SUCCESS") {
                    if (authenticationType === "SIGNUP") {
                        if (currentSignUpProcess === SIGNUP_UPDATEPROFILE_STEP) {
                            dispatch(setSignUpProcess(""));
                            if (callbackRequire) callbackRequire();
                        }
                    } else {
                        const myInit = {
                            body: {
                                standardCheckInTime:
                                    profile.standardClockInTime.format("HH:mm:ss"),
                                standardCheckOutTime:
                                    profile.standardClockOutTime.format("HH:mm:ss"),
                                isFromSignUp: !!callbackRequire,
                                isTomorrowReportActive: profile.isTomorrowReportActive,
                                tomorrowReportTimeSpan:
                                    profile.isTomorrowReportActive ? profile.tomorrowReportTimeSpan.format("HH:mm:ss") : "00:00:00",
                                localTimeZoneDatabaseName:
                                    profile.localTimeZoneDatabaseName,
                            },
                        };

                        API.put("UserAPI", "/updateProfile", myInit)
                            .then((d) => {
                                dispatch(
                                    setUserInfo({
                                        id: d.id,
                                        firstName: profile.firstName,
                                        lastName: profile.lastName,
                                        standardCheckInTime: d.standardCheckInTime,
                                        standardCheckOutTime: d.standardCheckOutTime,
                                        isStandardTimeUpdated: d.isStandardTimeUpdated,
                                        isTomorrowReportActive: d.isTomorrowReportActive,
                                        tomorrowReportTimeSpan: d.tomorrowReportTimeSpan,
                                        localTimeZoneDatabaseName: d.localTimeZoneDatabaseName,
                                    }),
                                );
                                dispatch(showLoader({ open: false, message: "" }));
                                if (callbackRequire) callbackRequire();
                                else setShowSuccessAlert(true);
                            })
                            .catch((e) => {
                                console.log(">> error", e);
                                dispatch(showLoader({ open: false, message: "" }));
                            });
                    }
                }
            })
            .catch((e) => {
                console.log(">> error", e);
                dispatch(showLoader({ open: false, message: "" }));
            });
    };

    const handleFirstNameOnChange = (e) => {
        if (CheckInput(e.target.value)) {
            setProfile({ ...profile, firstName: e.target.value });
        }
    };

    const handleLastNameOnChange = (e) => {
        if (CheckInput(e.target.value)) {
            setProfile({ ...profile, lastName: e.target.value });
        }
    };

    const handleIsTomorrowReportActiveChange = (e) => {
        if (CheckInput(e.target.value)) {
            setProfile({ ...profile, isTomorrowReportActive: e.target.checked });
        }
    };

    const handleLocalTimeZoneDatabaseNameChange = (e) => {
        setProfile({ ...profile, localTimeZoneDatabaseName: e.target.value });
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Grid container justifyContent="center" spacing={3}>
                {showSuccessALert && (
                    <Grid item xs={12}>
                        <Alert
                            severity="success"
                            onClose={() => {
                                setShowSuccessAlert(false);
                            }}
                        >
                            Your changes have been successfuly saved!
                        </Alert>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            color: theme.palette.grey[500],
                            paddingLeft: theme.spacing(1),
                        }}
                    >
                        Username
                    </Typography>
                    <Typography
                        variant="body1"
                        pl={theme.spacing(2)}
                    >
                        {profile.username}
                    </Typography>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={12} md={disregardDesktopView ? false : 6}>
                    <TextField
                        label="First Name"
                        error={profile.firstName.length === 0}
                        helperText="* First Name is required."
                        value={profile.firstName}
                        onChange={handleFirstNameOnChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={disregardDesktopView ? false : 6}>
                    <TextField
                        label="Last Name"
                        error={profile.lastName.length === 0}
                        helperText="* Last Name is required."
                        value={profile.lastName}
                        onChange={handleLastNameOnChange}
                        fullWidth
                    />
                </Grid>
                {/* Tomorrow Report Section */}
                <Grid item xs={12} md={disregardDesktopView ? false : 6}>
                    <FormControl
                        fullWidth
                        size="small"
                    >
                        <InputLabel
                            id="localTimeZoneDatabaseName-select-label"
                            htmlFor="localTimeZoneDatabaseName-select"
                        >
                            Select Timezone
                        </InputLabel>
                        <StyledSelectLocalTimeZone
                            id="localTimeZoneDatabaseName-select"
                            value={profile.localTimeZoneDatabaseName}
                            label="Select Timezone"
                            onChange={handleLocalTimeZoneDatabaseNameChange}
                            sx={{ paddingX: 1 }} // temporary fix until native select is outlined
                            native
                        >
                            {timeZoneNames.map((tzName) => (
                                <option
                                    key={tzName.name}
                                    value={tzName.name}
                                    className={tzName.name === profile.localTimeZoneDatabaseName ? "selected" : ""}
                                >
                                    {tzName.label}
                                </option>
                            ))}
                        </StyledSelectLocalTimeZone>
                    </FormControl>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={disregardDesktopView ? false : 6}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        flexDirection: {
                            xs: "column",
                            sm: disregardDesktopView ? "row" : "column",
                            md: "row",
                        },
                    }}
                >
                    <FormControlLabel
                        sx={{ textAlign: "left" }}
                        label="Send me a reminder of events for the next 24 hours, daily at"
                        control={(
                            <Switch
                                checked={profile.isTomorrowReportActive}
                                onChange={handleIsTomorrowReportActiveChange}
                            />
                        )}
                    />
                    <FormControl
                        fullWidth
                        sx={{
                            marginTop: {
                                xs: theme.spacing(1),
                                md: 0,
                            },
                        }}
                    >
                        <CustomTimeField
                            disabled={!profile.isTomorrowReportActive}
                            label="Time"
                            format="h:mm A"
                            value={profile.tomorrowReportTimeSpan}
                            onChange={(newValue) => {
                                setProfile((prev) => ({
                                    ...prev,
                                    tomorrowReportTimeSpan: newValue,
                                }));
                            }}
                            localeText={{
                                timePickerToolbarTitle: "Select when to receive Tomorrow Reports",
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={disregardDesktopView ? 4 : 3}>
                    <FormControl fullWidth>
                        <CustomTimeField
                            label="Standard Check In Time"
                            format="h:mm A"
                            value={profile.standardClockInTime}
                            onChange={(newValue) => {
                                setProfile((prev) => ({
                                    ...prev,
                                    standardClockInTime: newValue,
                                }));
                            }}
                            localeText={{
                                timePickerToolbarTitle: "Select Standard Check In Time",
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={disregardDesktopView ? 4 : 3}>
                    <FormControl fullWidth>
                        <CustomTimeField
                            label="Standard Check Out Time"
                            format="h:mm A"
                            value={profile.standardClockOutTime}
                            onChange={(newValue) => {
                                setProfile((prev) => ({
                                    ...prev,
                                    standardClockOutTime: newValue,
                                }));
                            }}
                            localeText={{
                                timePickerToolbarTitle: "Select Standard Check Out Time",
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid
                    item
                    xs={0}
                    md={disregardDesktopView ? 4 : 6}
                    sx={{
                        display: {
                            xs: "none",
                            md: "block",
                        },
                    }}
                />
                <Grid item xs={12} textAlign="right">
                    <Button
                        variant="contained"
                        onClick={handleUpdateUser}
                        disabled={!enableUpdateButton}
                        fullWidth={isMobile}
                    >
                        {currentSignUpProcess !== "" ? "Done" : "Update"}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
