export class IoTPropertyReference {
    constructor(
        PropertyFk,
        PropertyName,
        UserFk,
        PropertyStatus,
    ) {
        this.PropertyFk = PropertyFk;
        this.PropertyName = PropertyName;
        this.UserFk = UserFk;
        this.PropertyStatus = PropertyStatus;
    }
}
