const ActionReferenceModel = () => {
    const actionReferenceModel = {
        actionReferenceId: 0,
        name: "",
        deviceTypeId: 0,
    };
    return actionReferenceModel;
};

export default ActionReferenceModel;
