import { Notifications as NotificationsIcon } from "@mui/icons-material";
import { Badge, Divider, IconButton, Menu, MenuItem, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { notificationsAPI, useGetNotificationsQuery, useMarkNotificationsAsReadMutation } from "../features/auth/notificationsAPI";

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: 10,
        top: 10,
    },
}));

/*
    known issues:
    - (minor issue) while browsing the notifs page, the badge will not update the notifs count until the next polling
*/

export default function NotificationsMenuIconButton() {
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
    const openNotifications = Boolean(notificationsAnchorEl);
    const dispatch = useDispatch();

    const {
        data: dataNotifications,
        isLoading: isLoadingNotifications,
        // isFetching: isFetchingNotifications,
    } = useGetNotificationsQuery({
        referenceId: 0,
        pageSize: 5,
        willMarkAsRead: false,
    }, {
        pollingInterval: 5 * 60 * 1000, // poll for notifs every 5 minutes
    });

    const [
        markNotificationsAsRead,
    ] = useMarkNotificationsAsReadMutation();

    const queriedUnreadCount = dataNotifications ? dataNotifications.notifications.filter((notif) => !notif.isRead).length : 0;
    const remainingUnreadCount = dataNotifications ? dataNotifications.unreadCount - queriedUnreadCount : 0;

    const markAsRead = () => {
        // if at least one messag e is read, mark as read starting from latest queried notif
        if (dataNotifications?.notifications.some((notif) => !notif.isRead)) {
            markNotificationsAsRead({
                // plus 1 so the latest notification itself is included
                referenceId: dataNotifications.notifications[0].id + 1,
                pageSize: 5,
            });
        }
    };
    const handleCloseNotifications = () => {
        setNotificationsAnchorEl(null);
        // if at least one message was read, invalidate cache & refetch notifs
        if (dataNotifications?.notifications.some((notif) => !notif.isRead)) {
            dispatch(notificationsAPI.util.invalidateTags([{
                type: "notifications",
                referenceId: 0,
                pageSize: 5,
            }]));
        }
    };

    return (
        <>
            <StyledBadge
                badgeContent={dataNotifications?.unreadCount}
                color="error"
            >
                <IconButton
                    color="inherit"
                    onClick={(e) => {
                        setNotificationsAnchorEl(e.currentTarget);
                        markAsRead();
                    }}
                >
                    <NotificationsIcon />
                </IconButton>
            </StyledBadge>
            <Menu
                open={openNotifications}
                anchorEl={notificationsAnchorEl}
                onClose={handleCloseNotifications}
                slotProps={{
                    paper: {
                        sx: { width: 600 },
                    },
                }}
            >
                <MenuItem
                    disabled
                >
                    <Typography variant="h6">Notifications</Typography>
                </MenuItem>
                <Divider />
                {isLoadingNotifications ? (
                    <MenuItem disabled>
                        Loading...
                    </MenuItem>
                ) : !dataNotifications?.notifications?.length ? (
                    <MenuItem disabled>
                        No notifications
                    </MenuItem>
                ) : dataNotifications.notifications.map((notif) => (
                    <MenuItem
                        component={RouterLink}
                        color="inherit"
                        variant="text"
                        to={notif.onClickURL}
                        key={notif.id}
                        onClick={handleCloseNotifications}
                        sx={{
                            backgroundColor: notif.isRead ? "transparent" : "background.default",
                            whiteSpace: "normal",
                            display: "flex",
                        }}
                    >
                        <Typography variant="caption" fontWeight="bold" flex="0 0 5em">
                            {moment(notif.dateAdded).fromNow()}
                        </Typography>
                        &nbsp;
                        <Typography variant="inherit">
                            {notif.content}
                        </Typography>
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    component={RouterLink}
                    color="inherit"
                    variant="text"
                    to="/notifications"
                    onClick={handleCloseNotifications}
                >
                    See More Notifications
                    {remainingUnreadCount > 0 ? ` (${remainingUnreadCount} unread)` : ""}
                </MenuItem>
            </Menu>
        </>
    );
}
