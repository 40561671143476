import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Grid, Skeleton, Stack, styled, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useGetBookingsQuery, useGetICalendarQuery, usePullBookingsMutation } from "../../features/auth/icalendarAPI";
import UpdateICalendar from "./UpdateICalendar";
import ShowBookingCalendar from "./ShowBookingCalendar";
import ExpandableLink from "../helper/ExpandableLink";

const StyledScheduleDetail = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    "& .label": {
        color: theme.palette.text.secondary,
        flexShrink: 0,
    },
    "& .value": {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        color: theme.palette.text.primary,
    },

    [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
        "& .label": {
            width: 200,
        },
        "& .value": {
            paddingLeft: 0,
        },
    },
}));

function ViewSchedule({ propertyInfo }) {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.auth.userInfo);
    const [iCalendar, setICalendar] = useState(null); // null if still loading, undefined if not found
    const [showICalForm, setShowICalForm] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState({
        open: false,
        status: "",
        message: "",
    });

    const {
        data: dataICalendar,
        isLoading: isLoadingICalendar,
        isFetching: isFetchingICalendar,
        refetch: refetchScheduleInfo,
    } = useGetICalendarQuery({
        propertyFk: propertyInfo.id,
    });

    const isGettingICalendar = isLoadingICalendar || isFetchingICalendar;

    const {
        data: dataGetBookings,
        refetch: refetchGetBookings,
        isLoading: isLoadingGetBookings,
        isFetching: isFetchingGetBookings,
        error: errorGetBookings,
    } = useGetBookingsQuery({ iCalendarFK: dataICalendar?.id }, { skip: !dataICalendar });

    const [pullBookings, {
        // data: dataPullBookings,
        isLoading: isLoadingPullBookings,
        isFetching: isFetchingPullBookings,
        error: errorPullBookings,
    }] = usePullBookingsMutation();

    useEffect(() => {
        setShowSuccessAlert((prev) => ({
            ...prev,
            open: false,
            status: "",
            message: "",
        }));
    }, []);

    const onUpdateICalendar = (data) => {
        if (data.status === "success") {
            setICalendar(null);
            setShowICalForm(false);
        }
        // setShowSuccessAlert(true);
        setShowSuccessAlert((prev) => ({
            ...prev,
            open: true,
            status: data.status,
            message: data.message,
        }));
    };

    const handleUpdateOnClick = () => {
        setShowICalForm(true);
        setShowSuccessAlert((prev) => ({
            ...prev,
            open: false,
        }));
    };

    const onRefreshBookings = async () => {
        await pullBookings({ iCalendarFK: dataICalendar?.id }).unwrap();
        await refetchScheduleInfo({
            propertyFk: propertyInfo.id,
        });
    };

    const scheduleInfo = () => {
        if (dataICalendar === undefined) {
            return (
                <Typography variant="body1">No iCalendar yet</Typography>
            );
        }
        return (
            <>
                <StyledScheduleDetail>
                    <Box className="label">Standard Check-In:</Box>
                    <Box className="value">{moment(dataICalendar?.checkInTimeLocal, "HH:mm:ss").format("hh:mm A")}</Box>
                </StyledScheduleDetail>
                <StyledScheduleDetail>
                    <Box className="label">Standard Check-Out:</Box>
                    <Box className="value">{moment(dataICalendar?.checkOutTimeLocal, "HH:mm:ss").format("hh:mm A")}</Box>
                </StyledScheduleDetail>
                <StyledScheduleDetail>
                    <Box className="label">Calendar Source:</Box>
                    <Box className="value">
                        <ExpandableLink href={dataICalendar.url} shrunkWidth={200} />
                    </Box>
                </StyledScheduleDetail>
            </>
        );
    };

    return (
        <Grid container spacing={3}>
            {(showSuccessAlert.open) && (
                <Grid item xs={12}>
                    <Alert
                        sx={{ marginBottom: 2 }}
                        severity={showSuccessAlert.status}
                        onClose={() => {
                            setShowSuccessAlert((prev) => ({
                                ...prev,
                                open: false,
                            }));
                        }}
                    >
                        {showSuccessAlert.message}
                    </Alert>
                </Grid>
            )}
            {showICalForm ? (
                propertyInfo && (
                    <Grid item xs={12}>
                        <UpdateICalendar
                            propertyFK={propertyInfo.id}
                            iCalendar={dataICalendar}
                            setICalendar={setICalendar}
                            timeZone={propertyInfo.timeZone}
                            standardCheckInTime={userInfo.standardCheckInTime}
                            standardCheckOutTime={userInfo.standardCheckOutTime}
                            setShowICalForm={setShowICalForm}
                            // callbackRequired={onUpdateICalendar}
                            callbackStatus={onUpdateICalendar}
                            showCancelButton
                        />
                    </Grid>
                )
            ) : (
                <>
                    <Grid item xs={12}>
                        {isGettingICalendar ? (
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                                    <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                                    <Skeleton variant="rectangular" sx={{ fontSize: "1.25rem" }} />
                                </Stack>
                            </Grid>
                        ) : (
                            scheduleInfo()
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" } }}>
                            {dataICalendar?.lastSyncedAt > dataICalendar?.createdAt && (
                                <Box
                                    sx={{
                                        border: "1px solid",
                                        borderColor: "info.main",
                                        borderRadius: 1,
                                        backgroundColor: "white",
                                        paddingY: 1,
                                        paddingX: 2,
                                        color: "info.main",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Info fontSize="medium" sx={{ marginRight: 2 }} />
                                    <Box>
                                        {`Last synchronized on ${moment(iCalendar?.lastSyncedAt).utc().format("YYYY-MM-DD h:mm A")} UTC+00:00`}
                                    </Box>
                                </Box>
                            )}
                            <Box sx={{ marginLeft: "auto", marginTop: { xs: 3, lg: 0 } }}>
                                {dataICalendar !== undefined ? (
                                    <LoadingButton
                                        variant="outlined"
                                        disabled={isLoadingGetBookings || isFetchingGetBookings
                                            || isLoadingPullBookings || isFetchingPullBookings}
                                        loading={isLoadingPullBookings || isFetchingPullBookings}
                                        onClick={onRefreshBookings}
                                    >
                                        Sync
                                    </LoadingButton>
                                ) : (
                                    null
                                )}
                                {(!isGettingICalendar && dataICalendar === undefined) ? (
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setShowICalForm(true);
                                        }}
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Add
                                    </Button>
                                ) : (!isGettingICalendar && dataICalendar !== undefined) ? (
                                    <Button
                                        variant="contained"
                                        onClick={handleUpdateOnClick}
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Edit
                                    </Button>
                                ) : (
                                    null
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </>
            )}
            {dataICalendar !== undefined && (
                <Grid item xs={12}>
                    <ShowBookingCalendar
                        bookings={dataGetBookings}
                        isLoading={isLoadingGetBookings || isFetchingGetBookings}
                        error={errorPullBookings || errorGetBookings}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default ViewSchedule;
