import { Alert, Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { Auth, Hub } from "aws-amplify";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticationStatus, setLogInProcess, setSignUpProcess, setUserInfo, showLoader } from "../features/auth/authSlice";
import { SIGNUP_UPDATEPROFILE_STEP } from "./SignUp";
// import { LOGIN_VERIFYOTP_STEP } from "./Login";

export default function VerifyOtp({
    phoneNumber,
    callbackProceedLogin,
    isUserNotConfirmed = false,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const currentSignUpProcess = useSelector((state) => state.auth.signUpProcess);
    const [otp, setOtp] = useState("");
    const [errorOtpField, setErrorOtpField] = useState("");
    const [authSession, setAuthSession] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    console.log("callbackProceedLogin", callbackProceedLogin);

    const PreparSession = async () => {
        try {
            // get currrent authenticated user
            const currentUser = await Auth.currentAuthenticatedUser();

            dispatch(
                setUserInfo({
                    id: currentUser.attributes["custom:UserFK"],
                    firstName: "",
                    lastName: "",
                    standardCheckInTime: "",
                    standardCheckOutTime: "",
                    isStandardTimeUpdated: "",
                }),
            );

            dispatch(setLogInProcess(""));
            dispatch(showLoader({ open: false, message: "" }));
            dispatch(setSignUpProcess(SIGNUP_UPDATEPROFILE_STEP));
            if (currentSignUpProcess !== "") window.location.replace("/complete-registration");
            else window.location.replace("/property");
        } catch (error) {
            dispatch(showLoader({ open: false, message: "" }));
            dispatch(setLogInProcess(""));
        }
    };

    useEffect(() => {
        const hubListenerCancellation = Hub.listen("auth", ({ payload: { event, data } }) => {
            console.log("Hub.event", event, data);
            switch (event) {
                case "autoSignIn":
                    PreparSession();
                    break;
                // case "confirmSignUp":
                //     if (isUserNotConfirmed) PreparSession();
                //     break;
                default:
                    break;
            }
        });

        // on component unmount
        return () => {
            // cancels listening to amplify auth events
            hubListenerCancellation();
        };
    }, []);

    const handleVerifyOTP = async () => {
        setHasSubmitted(true);
        setErrorOtpField("");
        dispatch(showLoader({ open: true, message: "Verifying your code. Please wait..." }));

        try {
            const confirmResponse = await Auth.confirmSignUp(phoneNumber, otp);
            dispatch(setAuthenticationStatus(true));
            if (isUserNotConfirmed && callbackProceedLogin !== undefined) {
                callbackProceedLogin();
            }
        } catch (err) {
            if (err.code === "NotAuthorizedException") {
                setErrorOtpField("Invalid verification code.");
                setOtp("");
            } else if (err.code === "CodeMismatchException") {
                setErrorOtpField(err.message);
            } else if (err.message === "Challenge response cannot be empty") {
                setErrorOtpField("OTP cannot be empty");
            } else if (err.code === "ExpiredCodeException") {
                setErrorOtpField("OTP has expired. Please click on resend code.");
            } else setErrorOtpField("Error encountered while verifying your OTP. Please try again.");

            dispatch(setAuthenticationStatus(false));
            setHasSubmitted(false);
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    const handleResendOtp = async () => {
        dispatch(showLoader({ open: true, message: "Resending verification code. Please wait..." }));
        setErrorOtpField("");
        setOtp("");

        try {
            const s = await Auth.resendSignUp(phoneNumber);
            setAuthSession(s);
        } catch (err) {
            setErrorOtpField(err.message);
        } finally {
            dispatch(showLoader({ open: false, message: "" }));
        }
    };

    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginBottom: theme.spacing(1) }}>Verify OTP</Typography>
                </Grid>
                {(errorOtpField !== "") && (
                    <Grid item xs={12}>
                        <Alert severity="error" onClose={() => setErrorOtpField("")}>{errorOtpField}</Alert>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <MuiOtpInput
                        autoFocus
                        value={otp}
                        inputMode="numeric"
                        length={6}
                        onChange={(newValue) => setOtp(newValue)}
                        TextFieldsProps={{
                            size: "small",
                        }}
                        onComplete={() => console.log("complete")}
                        onKeyDown={(e) => {
                            if (e.key !== "Enter") return;
                            if (otp.length !== 6) return;
                            handleVerifyOTP();
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleVerifyOTP}
                        disabled={otp.length !== 6}
                    >
                        Confirm
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        size="small"
                        onClick={handleResendOtp}
                        fullWidth
                        variant="outlined"
                    >
                        Resend Code
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}
