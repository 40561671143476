export class DeviceUpdate {
    constructor(
        DeviceId,
        DeviceToken,
        DisplayName,
        isEnabled,
        isCheckInOutDoor,
        FloorNumber,
        PropertyId,
    ) {
        this.DeviceId = DeviceId;
        this.DisplayName = DisplayName;
        this.DeviceToken = DeviceToken;
        this.isEnabled = isEnabled;
        this.isCheckInOutDoor = isCheckInOutDoor;
        this.FloorNumber = FloorNumber;
        this.PropertyId = PropertyId;
    }
}
