import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookingCalendar from "./BookingCalendar";

export default function ShowBookingCalendar({ bookings, isLoading, error }) {
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>Booking Calendar</AccordionSummary>
            <AccordionDetails>
                <BookingCalendar {...{ bookings, isLoading, error }} />
            </AccordionDetails>
        </Accordion>
    );
}
