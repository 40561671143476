import { Alert, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showLoader } from "../../features/auth/authSlice";
import { useDisableDeviceMutation, useLazyGetDevicesByPropertyIdQuery } from "../../features/auth/devicesAPI";
import { useRemovePropertyMutation } from "../../features/auth/hostAPI";
import CustomRemoveDialog from "../helper/CustomRemoveDialog";

function RemoveProperty({
    propertyInfo,
    forComponent,
    callbackCloseDlg,
    open }) {
    const [showRemoveDlg, setShowRemoveDlg] = useState(false);
    const [showSuccessRemoveDlg, setShowSuccessRemoveDlg] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [
        triggerGetDevicesByPropertyId] = useLazyGetDevicesByPropertyIdQuery();
    const [
        disableDevice] = useDisableDeviceMutation();
    const [removeProperty, {
        isLoading: isLoadingRemoveProperty,
        isSuccess: isSuccessRemoveProperty,
        isError: isErrorRemoveProperty,

    }] = useRemovePropertyMutation();

    useEffect(() => {
        if (open !== undefined) {
            setShowRemoveDlg(open);
        }
    }, [open]);

    useEffect(() => {
        if (isLoadingRemoveProperty) {
            dispatch(showLoader({ open: true, message: "Property is being removed. Please wait..." }));
        } else { dispatch(showLoader({ open: false, message: "" })); }
    }, [isLoadingRemoveProperty]);

    useEffect(() => {
        if (isSuccessRemoveProperty) {
            switch (forComponent) {
                case "propertyList":
                    setShowSuccessRemoveDlg(true);
                    break;

                case "propertyDetails":
                    setShowSuccessRemoveDlg(true);
                    break;
                default:
                    break;
            }

            // get devices and disable each
            (async () => {
                await triggerGetDevicesByPropertyId({ propertyId: propertyInfo.id }).then(async (response) => {
                    response.data.forEach(async (device) => {
                        await disableDevice({ id: device.deviceId });
                    });
                });
            })();
        }

        if (isErrorRemoveProperty) {
            setShowErrorAlert(true);
        }
    }, [isSuccessRemoveProperty, isErrorRemoveProperty]);

    const handleCancelOnClick = () => {
        setShowRemoveDlg(false);
        if (callbackCloseDlg !== undefined) { callbackCloseDlg(); }
    };

    const handleRemoveOnClick = async () => {
        handleCancelOnClick();
        setShowRemoveDlg(false);
        removeProperty({
            propertyFK: propertyInfo.id,
        });
    };
    return (
        <>
            <Dialog
                maxWidth="xs"
                open={showSuccessRemoveDlg}
            >
                <DialogContent>
                    Property successfully removed.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        navigate("/property");
                        setShowSuccessRemoveDlg(false);
                    }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            {showErrorAlert && (
                <Alert
                    severity="error"
                    onClose={() => setShowErrorAlert(false)}
                >
                    An error occurred while removing property. Please try again.
                </Alert>
            )}
            <CustomRemoveDialog
                open={showRemoveDlg}
                title="Remove Property"
                message={(
                    <>
                        <div>
                            You are about to remove the property
                            {" "}
                            <b>{propertyInfo && propertyInfo.propertyName}</b>
                            .
                        </div>
                        <div>
                            Do you wish to continue?
                        </div>
                    </>
                )}
                okButtonText="Remove Property"
                onCancelClick={handleCancelOnClick}
                onRemoveClick={handleRemoveOnClick}
            />
        </>
    );
}

export default RemoveProperty;
