import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const actionReferenceAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getAllActionReferences: builder.query({
            queryFn: async () => {
                try {
                    const response = await API.get("IoTAPI", "/actionreference/*");
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
    }),
});

export const {
    useGetAllActionReferencesQuery,
} = actionReferenceAPI;
