import { API, Amplify, Auth } from "aws-amplify";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import AmazonConfig from "./amazonConfig";
import Dashboard from "./components/Dashboard";
import ForgotPassword from "./components/ForgotPassword";
import DeviceAuthSuccessPage from "./components/IoT/DeviceAuthSuccessPage";
import DevicesPage from "./components/IoT/DevicesPage";
import SelectDevices from "./components/IoT/SelectDevices";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Main from "./components/Main";
import Properties from "./components/Properties";
import Property from "./components/Property";
import PropertyAdd from "./components/PropertyAdd";
import RequiredInfo from "./components/RequiredInfo";
import SignUp from "./components/SignUp";
import TestMapComponent from "./components/TestMapComponent";
import Settings from "./components/profile/Settings";
import ServiceProviderPage from "./components/serviceprovider/ServiceProvidersPage";
import CompletedServicesPage from "./components/serviceprovider/ServiceRequestPage";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { CheckRegistration } from "./utils/SignUpStatusCode";
import NotificationsPage from "./components/NotificationsPage";
import IoTSimulator from "./components/IoTSimulator/IoTSimulator";

async function checkAuth() {
    Amplify.configure(await AmazonConfig());
    const auth = await Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((r) => r)
        .catch((e) => null)
        .finally(() => null);

    if (auth === null) return null;

    const checkSignUpStatus = await CheckRegistration();

    const user = await API.get("UserAPI", "/").then((r) => r).catch(() => null);

    return {
        auth,
        user,
        checkSignUpStatus,
    };
}

const AppRoute = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                element={<Layout />}
                loader={checkAuth}
            >
                <Route
                    path="*"
                    element={<PageNotFound />}
                />
                {/* <Route element={<Main />} path="/" exact /> */}
                <Route element={<Login />} path="/" exact />
                <Route element={<SignUp />} path="/signup" />
                <Route element={<Login />} path="/login" />
                <Route element={<ForgotPassword />} path="/forgot-password" />
                <Route element={<ProtectedRoute />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="complete-registration" element={<RequiredInfo />} />
                    <Route path="settings">
                        <Route path="" element={<Settings />} />
                    </Route>
                    {/* <Route path="changepassword">
                        <Route path="" element={<ChangePassword />} />
                    </Route> */}
                    <Route path="property">
                        <Route path="" element={<Properties />} />
                        <Route path=":id">
                            <Route path="" element={<Property />} />
                            {/* <Route path="selectdevices/:partnerId?" element={<SelectDevices />} /> */}
                        </Route>
                        <Route path="add" element={<PropertyAdd />} />
                        <Route path="devices/:id" element={<DevicesPage />} />
                    </Route>
                    <Route path="serviceprovider" element={<ServiceProviderPage />} />
                    <Route path="notifications" element={<NotificationsPage />} />
                </Route>
                <Route element={<CompletedServicesPage />} path="/servicerequest" />
                <Route element={<TestMapComponent />} path="/testmap" />
                <Route element={<IoTSimulator />} path="/iotsimulator" />
                {/* <Route path="deviceauth/property/:id/selectdevices/:partnerId?" element={<DeviceAuthSuccessPage />} /> */}
            </Route>
            <Route path="deviceauth/property/:id/selectdevices/:partnerId?" element={<DeviceAuthSuccessPage />} />
            <Route element={<ProtectedRoute />}>
                <Route path="/property/:id/selectdevices/:partnerId?" element={<SelectDevices />} />
            </Route>
        </>,
    ),
);

export default AppRoute;
