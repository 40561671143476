import { Button, Checkbox, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useState } from "react";

function IoTSimulator(props) {
    const [doorId, setDoorId] = useState("d88b4c0200017672");
    const [motionId, setMotionId] = useState("d88b4c020003770b");
    const [leakId, setLeakId] = useState("d88b4c020005dc69");
    const [thermostatId, setThermostatId] = useState("d88b4c0100040e53");
    const [ac, setAc] = useState({
        tempMode: "C",
        mode: "off",
        temperature: "0",
    });
    const [defDoor, setDefDoor] = useState(true);
    const [defMotion, setDefMotion] = useState(true);
    const [defLeak, setDefLeak] = useState(true);
    const [defThermoStat, setDefThermoStat] = useState(true);

    const deviceIds = [
        {
            type: "DoorSensor",
            id: "d88b4c0200017672",
        },
        {
            type: "MotionSensor",
            id: "d88b4c020003770b",
        },
        {
            type: "Thermostat",
            id: "d88b4c0100040e53",
        },
        {
            type: "LeakSensor",
            id: "d88b4c020005dc69",
        },
    ];

    async function changeDeviceState(deviceType, deviceId, state, mode, tempMode, temp) {
        const url = "https://e43tsodd1l.execute-api.us-east-1.amazonaws.com/dev";
        const client = new XMLHttpRequest();

        let content;
        if (deviceType !== "Thermostat") {
            content = {
                // body: {
                event: `${deviceType}.report`,
                time: 1691032048256,
                msgid: "1691032048255",
                data: {
                    state,
                    battery: 4,
                    version: "0470",
                    ledAlarm: true,
                    alertInterval: 1,
                    nomotionDelay: 1,
                    sensitivity: 3,
                    loraInfo: {
                        signal: -18,
                        gatewayId: "d88b4c160301bba5",
                        gateways: 1,
                    },
                    stateChangedAt: 1691032048254,
                },
                deviceId,
                // },
            };
        } else {
            content = {
                event: "Thermostat.report",
                deviceId,
                data: {
                    state: {
                        tempMode,
                        mode,
                        temperature: parseFloat(temp),
                    },
                },
            };
        }

        client.open("POST", url, true);
        client.setRequestHeader("Content-Type", "application/json");

        return new Promise((resolve, reject) => {
            client.onreadystatechange = function () {
                if (client.readyState === 4) {
                    if (client.status >= 200 && client.status < 300) {
                        console.log(client.responseText);
                        resolve(client.responseText);
                    } else {
                        reject(new Error(`HTTP request failed with status ${client.status}`));
                    }
                }
            };
            client.send(JSON.stringify(content));
        });
    }

    return (
        <div>
            <h1>IOT SIMULATOR</h1>
            <TableContainer component={Paper} style={{ width: "100%" }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell rowSpan={1}>Door Sensor</TableCell>
                            <TableCell colSpan={2}>
                                <TextField
                                    label="Device ExternalID"
                                    size="small"
                                    fullWidth
                                    // disabled
                                    value={doorId}
                                    onChange={(e) => {
                                        setDoorId(e.target.value);
                                        setDefDoor(false);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <InputLabel>Use default?</InputLabel>
                                <Checkbox
                                    checked={defDoor}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setDoorId(
                                                deviceIds.filter((x) => x.type === "DoorSensor")[0].id,
                                            );
                                        } else {
                                            setDoorId("");
                                        }
                                        setDefDoor(e.target.checked);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => changeDeviceState("DoorSensor", doorId, "open")}
                                >
                                    OPEN
                                </Button>
                            </TableCell>
                            <TableCell colSpan={3}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => changeDeviceState("DoorSensor", doorId, "closed")}
                                >
                                    CLOSED
                                </Button>
                            </TableCell>
                        </TableRow>
                        {/* <TableRow>
                            <TableCell>
                                <TextField
                                    label="Device ExternalID"
                                    size="small"
                                    fullWidth
                                    // disabled
                                    value={doorId}
                                />
                            </TableCell>
                        </TableRow> */}
                        <TableRow>
                            <TableCell>Motion Sensor</TableCell>
                            <TableCell colSpan={2}>
                                <TextField
                                    label="Device ExternalID"
                                    size="small"
                                    // fullWidth
                                    // disabled
                                    value={motionId}
                                    onChange={(e) => {
                                        setMotionId(e.target.value);
                                        setDefMotion(false);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <InputLabel>Use default?</InputLabel>
                                <Checkbox
                                    checked={defMotion}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setMotionId(
                                                deviceIds.filter((x) => x.type === "MotionSensor")[0]
                                                    .id,
                                            );
                                        } else {
                                            setDoorId("");
                                        }
                                        setDefMotion(e.target.checked);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => changeDeviceState("MotionSensor", motionId, "alert")}
                                >
                                    MOTION
                                </Button>
                            </TableCell>
                            <TableCell colSpan={3}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => changeDeviceState("MotionSensor", motionId, "normal")}
                                >
                                    NORMAL
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Leak Sensor</TableCell>
                            <TableCell colSpan={2}>
                                <TextField
                                    label="Device ExternalID"
                                    size="small"
                                    // fullWidth
                                    // disabled
                                    value={leakId}
                                    onChange={(e) => {
                                        setLeakId(e.target.value);
                                        setDefLeak(false);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <InputLabel>Use default?</InputLabel>
                                <Checkbox
                                    checked={defLeak}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setLeakId(
                                                deviceIds.filter((x) => x.type === "LeakSensor")[0].id,
                                            );
                                        } else {
                                            setDoorId("");
                                        }
                                        setDefLeak(e.target.checked);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => changeDeviceState("LeakSensor", leakId, "alert")}
                                >
                                    LEAK
                                </Button>
                            </TableCell>
                            <TableCell colSpan={3}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => changeDeviceState("LeakSensor", leakId, "normal")}
                                >
                                    NORMAL
                                </Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell rowSpan={2}>AC Thermostat</TableCell>
                            <TableCell colSpan={2}>
                                <TextField
                                    label="Device ExternalID"
                                    size="small"
                                    // fullWidth
                                    // disabled
                                    value={thermostatId}
                                    onChange={(e) => {
                                        setThermostatId(e.target.value);
                                        setDefThermoStat(false);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <InputLabel>Use default?</InputLabel>
                                <Checkbox
                                    checked={defThermoStat}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setThermostatId(
                                                deviceIds.filter((x) => x.type === "Thermostat")[0].id,
                                            );
                                        } else {
                                            setThermostatId("");
                                        }
                                        setDefThermoStat(e.target.checked);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <InputLabel>Unit</InputLabel>
                                <Select
                                    fullWidth
                                    value={ac.tempMode}
                                    onChange={(e) => {
                                        setAc({ ...ac, tempMode: e.target.value });
                                    }}
                                >
                                    <MenuItem value="F">F</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <InputLabel>Mode</InputLabel>
                                <Select
                                    fullWidth
                                    value={ac.mode}
                                    onChange={(e) => {
                                        setAc({ ...ac, mode: e.target.value });
                                    }}
                                >
                                    <MenuItem value="auto">Auto</MenuItem>
                                    <MenuItem value="cool">Cool</MenuItem>
                                    <MenuItem value="heat">Heat</MenuItem>
                                    <MenuItem value="off">Off</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell>
                                <InputLabel>Temperature</InputLabel>
                                <TextField
                                    // label="Temperature"
                                    size="medium"
                                    fullWidth
                                    // disabled
                                    value={ac.temperature}
                                    onChange={(e) => {
                                        setAc({ ...ac, temperature: e.target.value });
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => changeDeviceState("Thermostat", thermostatId, "", ac.mode, ac.tempMode, ac.temperature)}
                                >
                                    SIMULATE
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default IoTSimulator;
