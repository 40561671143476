import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function RuleThenDisplay({ action }) {
    const [actionRefName, setActionRefName] = useState("");
    const [paramValue, setParamValue] = useState();
    const [deviceName, setDeviceName] = useState("");

    // set temp sample object
    const actionObject = {
        eventActionId: action.eventActionId,
        actionReferenceId: action.actionreferenceId,
        actionReference: null,
        deviceId: action.deviceId,
        device: null,
        partnerAccountId: action.partnerAccountId,
        partnerAccount: null,
        partnerId: action.partnerId,
        partner: null,
        ruleId: action.ruleId,
        rule: null,
        parameters: action.parameters,
        isActive: true,
        deviceDisplayName: "Thermostat - QxXujy38",
        actionReferenceName: "Set temperature",
        actionReferenceDeviceTypeName: "Thermostat",
    };

    // turn off AC sample object
    // const actionObject = {
    //     eventActionId: 14,
    //     actionReferenceId: 2,
    //     actionReference: null,
    //     deviceId: 11,
    //     device: null,
    //     partnerAccountId: 4033,
    //     partnerAccount: null,
    //     partnerId: 2,
    //     partner: null,
    //     ruleId: 46,
    //     rule: null,
    //     parameters: "QxXujy38,",
    //     isActive: true,
    //     deviceDisplayName: "Thermostat - QxXujy38",
    //     actionReferenceName: "Turn off AC",
    //     actionReferenceDeviceTypeName: "Thermostat",
    // };

    // send sms alert sample object
    // const actionObject = {
    //     eventActionId: 14,
    //     actionReferenceId: 2,
    //     actionReference: null,
    //     deviceId: 11,
    //     device: null,
    //     partnerAccountId: null,
    //     partnerAccount: null,
    //     partnerId: 2,
    //     partner: null,
    //     ruleId: 46,
    //     rule: null,
    //     parameters: null,
    //     isActive: false,
    //     deviceDisplayName: null,
    //     actionReferenceName: "Send SMS alert",
    //     actionReferenceDeviceTypeName: null,
    // };

    useEffect(() => {
    }, []);
    return (
        <Typography variant="body2" color="text.secondary">
            {action.name}
            {" "}
            {/* {paramValue} */}
            {action.parameters != null ? `to ${(action.parameters).replace(" ", "")}` : ""}
            {action.displayName !== null ? ` (${action.displayName})` : ""}
        </Typography>
    );
}

export default RuleThenDisplay;

// {
//     "data": [
//         {
//             "eventActionId": 17,
//             "actionReferenceId": 3,
//             "actionReference": null,
//             "deviceId": 11,
//             "device": null,
//             "partnerAccountId": 4033,
//             "partnerAccount": null,
//             "partnerId": 2,
//             "partner": null,
//             "ruleId": 46,
//             "rule": null,
//             "parameters": "QxXujy38,20",
//             "isActive": true
//         }
//     ]
// }
