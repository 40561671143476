import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function RuleIfDisplay({ deviceId, stateId, isActive, conditionType, name, state, value, time, days }) {
    const [getDeviceName, setGetDeviceName] = useState("");
    const [getStateName, setGetStateName] = useState("");

    // useEffect(() => {
    //   async function getData() {
    //     const device = await getDevicesbyId(deviceId);
    //     const state = await getStatesbyId(stateId);
    //     setGetDeviceName(device.displayName);
    //     setGetStateName(state.name);
    //   }
    //   getData();
    // }, [deviceId, stateId]);

    useEffect(() => {
        if (conditionType === "time") {
            if (name === "0") {
                setGetDeviceName(`${value} minutes after check-in`);
            } else if (name === "1") {
                setGetDeviceName(`${value} minutes after check-out`);
            } else if (name === "2") {
                setGetDeviceName("In Between Bookings");
            } else if (name === "3") {
                setGetDeviceName(`${value} minutes before check-in`);
            } else if (name === "4") {
                setGetDeviceName(`${value} minutes before check-out`);
            } else if (name === "5") {
                setGetDeviceName("During Bookings");
            } else if (name === "6") {
                if (days.length === 7) {
                    setGetDeviceName(`${time} everyday`);
                } else if (days.length === 5 && days.includes("MON") && days.includes("TUE") && days.includes("WED") && days.includes("THU") && days.includes("FRI")) {
                    setGetDeviceName(`${time} on weekdays`);
                } else if (days.length === 2 && days.includes("SAT") && days.includes("SUN")) {
                    setGetDeviceName(`${time} on weekends`);
                } else {
                    setGetDeviceName(`${time} every ${days}`);
                }
            }
        } else {
            setGetDeviceName(name);
        }
        setGetStateName(state);
    }, [conditionType, name, state, value]);

    return (
        <>
            {!isActive && (
                <Typography variant="body2" color="text.secondary">
                    {/* {getDeviceName.length > 0 && ( */}
                    {getStateName !== "" && (
                        <span>
                            {getDeviceName}
                            &apos;s state is
                            {" "}
                            {` ${getStateName}`}
                            {" "}
                            (Inactive)
                        </span>
                    )}
                    {getStateName === "" && <span>{getDeviceName}</span>}
                    {/* )} */}
                    {/* <span>{getDeviceName}</span> */}
                </Typography>
            )}
            {isActive && (
                <Typography variant="body2" color="text.secondary">
                    {/* {getDeviceName.length > 0 && ( */}
                    {getStateName != "" && (
                        <span>
                            {getDeviceName}
                            &apos;s state is
                            {" "}
                            {` ${getStateName}`}
                        </span>
                    )}
                    {getStateName == "" && <span>{getDeviceName}</span>}
                    {/* )} */}
                    {/* <span>{getDeviceName}</span> */}
                </Typography>
            )}
        </>
    );
}

export default RuleIfDisplay;
