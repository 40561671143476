import { createTheme } from "@mui/material/styles";

// eslint-disable-next-line import/no-mutable-exports
const palette = {
    mode: "light",
    background: {
        default: "#DFEAF2",
    },
    primary: {
        main: "#5767F2", // primary
    },
    close: {
        main: "#1976d2",
    },
    text: {
        primary: "#232323", // text-dark
        secondary: "#718EBF", // text-light
        disabled: "#BBBBBB",
    },
    info: {
        main: "#0288D1",
    },
    service_status: {
        pending: "#CCCC99",
        confirmed: "#33CC00",
        cancelled: "#C62828",
        ongoing: "#33CC00",
        done: "#0033FF",
    },
    // TODO: setup colors for other statuses
    property_status: {
        vacant: "rgba(0, 128, 0, 1)",
        vacant_light: "rgba(0, 128, 0, .1)",
        occupied: "rgba(196, 2, 51, 1)",
        occupied_light: "rgba(196, 2, 51, .1)",
    },
    alert_rules: {
        // custom: "#E3F2C1",
        custom: "#E6EE9C",
        // premade: "#F6FFDE",
        premade: "#FFF9C4",
        inactive: "#e6e6e6",
    },
};

const customTheme = createTheme({
    palette,
    typography: {
        h5: {
            fontWeight: "bold",
            color: palette.text.secondary,
            fontSize: 20,
        },
        h6: {
            fontWeight: "bold",
            color: palette.text.secondary,
            fontSize: 18,
        },
    },
    components: {
        MuiCard: {
            defaultProps: {
            },
        },
        MuiTextField: {
            defaultProps: {
                size: "small",
                InputLabelProps: {
                    shrink: true,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: ({ ownerState, theme }) => ({
                    color: theme.palette.text.secondary,
                }),
                root: ({ ownerState, theme }) => ({
                    "& svg": {
                        color: theme.palette.text.secondary,
                    },
                    ...(ownerState.disabled && {
                        "& svg": theme.palette.action.disabled,
                    }),
                }),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    color: theme.palette.text.primary,
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: theme.palette.background.default,
                    },
                }),
            },
        },
        MuiNativeSelect: {
            defaultProps: {
                size: "small",
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .MuiTableCell-root": {
                        color: "#354C80",
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                    },
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderColor: theme.palette.background.default,
                }),
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.palette.background.default,
                }),
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottomWidth: 1,
                    borderBottomStyle: "solid",
                    borderBottomColor: theme.palette.background.default,
                }),
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    marginTop: theme.spacing(1),
                }),
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,
                }),
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.main,
                    "&.Mui-selected": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    },
                    "&.MuiPaginationItem-root": {
                        border: 0,
                    },
                }),
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderColor: theme.palette.background.default,
                }),
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    "&.MuiButton-outlined": {
                        backgroundColor: theme.palette.common.white,
                    },
                    "&.MuiButton-outlinedError:hover": {
                    },
                }),
            },
        },
    },
});

console.log("customTheme", customTheme);

export default customTheme;
