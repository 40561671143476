import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Card, CardContent, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../features/auth/authSlice";
import MyProfile from "./MyProfile";
import ChangePassword from "./ChangePassword";

const TAB_PROFILE = "profile";
const TAB_CHANGE_PASSWORD = "change_password";

export default function Settings() {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState(TAB_PROFILE);

    useEffect(() => {
        dispatch(setPageTitle("Settings"));
    }, []);

    return (
        <Card>
            <TabContext value={selectedTab}>
                <TabList
                    onChange={(e, newVal) => {
                        setSelectedTab(newVal);
                    }}
                    scrollButtons="auto"
                    variant="scrollable"
                    visibleScrollbar
                    allowScrollButtonsMobile
                >
                    <Tab label="Profile" value={TAB_PROFILE} />
                    <Tab label="Change Password" value={TAB_CHANGE_PASSWORD} />
                </TabList>
                <TabPanel value={TAB_PROFILE}>
                    <MyProfile />
                </TabPanel>
                <TabPanel value={TAB_CHANGE_PASSWORD}>
                    <ChangePassword />
                </TabPanel>
            </TabContext>
        </Card>
    );
}
