import { createSlice } from "@reduxjs/toolkit";
import {
    STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE,
    STATUS_CODE_ADD_CALENDAR_COMPLETE,
    STATUS_CODE_ADD_PROPERTY_COMPLETE,
    STATUS_CODE_OTP_SUCCESS,
    STATUS_CODE_PCR_COMPLETE,
    STATUS_CODE_PROFILE_COMPLETE,
} from "../../utils/SignUpStatusCode";

const initialState = {
    // determins whether user clicks on sign up or log in link (values: SIGNUP/LOGIN/'')
    authenticationType: "",
    // determins whether the user is successfully signed or logged in
    isAuthenticated: false,
    // use for sign-up step process (Sign Up > Verify OTP > Update User Profile)
    signUpProcess: "",
    // use for log-in step process (Log In > Verify OTP)
    logInProcess: "",
    // user info (cognito and db)
    userInfo: {
        id: 0,
        firstName: "",
        lastName: "",
        standardCheckInTime: "00:00:00",
        standardCheckOutTime: "00:00:00",
        isStandardTimeUpdated: false,
        isUserProfileComplete: false,
        isTomorrowReportActive: false,
        tomorrowReportTimeSpan: "00:00:00",
        localTimeZoneDatabaseName: "",
    },
    signUpProcessFlow: {
        statusCode: 0,
        initialPropertyId: 0,
        initialPropertyTimezone: "",
        isSignUpStatusComplete: false,
    },
    loader: {
        open: false,
        message: "",
    },
    confirmDialog: {
        open: false,
        title: "",
        message: "",
        actionResult: "",
    },
    pageTitle: "",
    mobileBreakpoint: "md",
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        // use to set whether user clicks on sign up or log in page (values: SIGNUP/LOGIN/'')
        setAuthenticationType: (state, action) => {
            state.authenticationType = action.payload;
        },
        // use to set if user already logged in or not
        setAuthenticationStatus: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        // use for sign-up step process
        setSignUpProcess: (state, action) => {
            state.signUpProcess = action.payload;
            state.logInProcess = "";
            state.authenticationType = "SIGNUP";
        },
        // use for log-in step process
        setLogInProcess: (state, action) => {
            state.signUpProcess = "";
            state.logInProcess = action.payload;
            state.authenticationType = "LOGIN";
        },
        // store user info
        setUserInfo: (state, action) => {
            state.userInfo.id = action.payload.id;
            state.userInfo.firstName = action.payload.firstName !== undefined ? action.payload.firstName : "";
            state.userInfo.lastName = action.payload.lastName !== undefined ? action.payload.lastName : "";
            state.userInfo.standardCheckInTime = action.payload.standardCheckInTime;
            state.userInfo.standardCheckOutTime = action.payload.standardCheckOutTime;
            state.userInfo.isStandardTimeUpdated = action.payload.isStandardTimeUpdated;

            // Tomorrow Report
            state.userInfo.isTomorrowReportActive = action.payload.isTomorrowReportActive;
            state.userInfo.tomorrowReportTimeSpan = action.payload.tomorrowReportTimeSpan;
            state.userInfo.localTimeZoneDatabaseName = action.payload.localTimeZoneDatabaseName;

            state.signUpProcessFlow.initialPropertyId = action.payload.initialPropertyId;
            state.signUpProcessFlow.initialPropertyTimezone = action.payload.initialPropertyTimezone;

            if (action.payload.isStandardTimeUpdated
                && action.payload.firstName.length > 0
                && action.payload.lastName.length > 0) {
                state.userInfo.isUserProfileComplete = true;
            } else {
                state.userInfo.isUserProfileComplete = false;
            }
        },
        updateSignUpProcessFlow: (state, action) => {
            state.signUpProcessFlow.statusCode = action.payload.statusCode;
            if (action.payload.initialPropertyId) state.signUpProcessFlow.initialPropertyId = action.payload.initialPropertyId;
            if (action.payload.initialPropertyTimezone) state.signUpProcessFlow.initialPropertyTimezone = action.payload.initialPropertyTimezone;

            const checkSignUpStatus = action.payload.statusCode !== STATUS_CODE_OTP_SUCCESS
              && action.payload.statusCode !== STATUS_CODE_PROFILE_COMPLETE
              && action.payload.statusCode !== STATUS_CODE_ADD_PROPERTY_COMPLETE
              && action.payload.statusCode !== STATUS_CODE_ADD_CALENDAR_COMPLETE
              && action.payload.statusCode !== STATUS_CODE_ADD_BASIC_DEVICES_COMPLETE
              && action.payload.statusCode !== STATUS_CODE_PCR_COMPLETE;

            if (checkSignUpStatus) {
                state.signUpProcessFlow.isSignUpStatusComplete = true;
            } else {
                state.signUpProcessFlow.isSignUpStatusComplete = false;
            }
        },
        // logout: clear authentication state
        clear: (state) => {
            state.isAuthenticated = false;
            state.authenticationType = "";
            state.signUpProcess = "";
            state.logInProcess = "";
        },
        showLoader: (state, action) => {
            state.loader.message = action.payload.message;
            state.loader.open = action.payload.open;
        },
        setPageTitle: (state, action) => {
            state.pageTitle = action.payload;
        },
        showConfirmDialog: (state, action) => {
            state.confirmDialog.title = action.payload.title;
            state.confirmDialog.message = action.payload.message;
            state.confirmDialog.open = action.payload.open;
        },
    },
});

export const { verifyOtp,
    setAuthenticationStatus,
    setSignUpProcess,
    setLogInProcess,
    setAuthenticationType,
    setUserInfo,
    updateSignUpProcessFlow,
    clear,
    showLoader,
    setPageTitle,
} = authSlice.actions;

export default authSlice.reducer;
