import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

export const notificationsAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            queryFn: async ({ referenceId, pageSize, willMarkAsRead }) => {
                try {
                    const response = await API.get(
                        "NotificationApi",
                        "/",
                        {
                            queryStringParameters: {
                                referenceId,
                                pageSize,
                                willMarkAsRead,
                            },
                        },
                    );
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, arg) => [{
                type: "notifications",
                referenceId: arg.referenceId,
                pageSize: arg.pageSize,
            }],
        }),
        markNotificationsAsRead: builder.mutation({
            queryFn: async ({ referenceId, pageSize, willMarkAsRead }) => {
                try {
                    const response = await API.post(
                        "NotificationApi",
                        "/markAsRead",
                        {
                            body: {
                                referenceId,
                                pageSize,
                                willMarkAsRead,
                            },
                        },
                    );
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useMarkNotificationsAsReadMutation,
} = notificationsAPI;
