import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment-timezone";
import { LoadingButton } from "@mui/lab";
import { setPageTitle } from "../features/auth/authSlice";
import { useGetNotificationsQuery } from "../features/auth/notificationsAPI";

export default function NotificationsPage() {
    const dispatch = useDispatch();

    const [pastQueriedNotifs, setPastQueriedNotifs] = useState([]);
    const [queryParams, setQueryParams] = useState({
        referenceId: 0,
        pageSize: 10,
        willMarkAsRead: true,
    });

    const {
        data: dataNotifications,
        // isLoading: isLoadingNotifications,
        isFetching: isFetchingNotifications,
    } = useGetNotificationsQuery(queryParams, { refetchOnMountOrArgChange: true });

    const allQueriedNotifs = isFetchingNotifications
        ? pastQueriedNotifs
        : pastQueriedNotifs.concat(dataNotifications?.notifications || []);

    const noMoreNotifsToLoad = !isFetchingNotifications && dataNotifications && dataNotifications?.notifications.length < queryParams.pageSize;

    const loadMoreNotifications = () => {
        setQueryParams((oldParams) => ({
            ...oldParams,
            referenceId: allQueriedNotifs[allQueriedNotifs.length - 1].id,
        }));
        setPastQueriedNotifs((oldArr) => oldArr.concat(dataNotifications.notifications));
    };

    useEffect(() => { dispatch(setPageTitle("Notifications")); }, []);
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    {allQueriedNotifs.map((notif) => (
                        <Grid
                            item
                            xs={12}
                            key={notif.id}
                        >
                            <Link
                                component={RouterLink}
                                to={notif.onClickURL}
                                color="text.primary"
                                sx={{ textDecorationLine: "none" }}

                            >
                                <Box
                                    sx={{
                                        backgroundColor: notif.isRead ? "transparent" : "background.default",
                                        padding: 1,
                                        "&:hover": {
                                            backgroundColor: "grey.100",
                                        },
                                    }}
                                >
                                    <Typography variant="caption" fontWeight="bold">
                                        {moment(notif.dateAdded).fromNow()}
                                    </Typography>
                                    <Typography variant="inherit">
                                        {notif.content}
                                    </Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        {
                            noMoreNotifsToLoad ? (
                                <Typography variant="body1">
                                    No more notifications to load.
                                </Typography>
                            ) : (
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    onClick={loadMoreNotifications}
                                    loading={isFetchingNotifications}
                                    disabled={isFetchingNotifications}
                                >
                                    Load more notifications
                                    {dataNotifications?.unreadCount > 0 ? ` (${dataNotifications.unreadCount} unread)` : ""}
                                </LoadingButton>
                            )
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
