import { TableCell, TableContainer } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyleTableContainer = styled(TableContainer)(({ theme }) => ({
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    "& .MuiTableCell-root": {
        borderColor: theme.palette.background.default,
    },
}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.divider,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderWidth: 0,
}));
