import { Refresh, Warning } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, ButtonBase, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Pagination, Select, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { setPageTitle } from "../../features/auth/authSlice";
import { ServiceStatusColorCode } from "../../utils/ServiceStatusColorCode";
import { CompletedStatusTypes } from "../../utils/enum";
import "../../utils/moment-setup";
import { StyledEmptyStateContainer } from "../helper/EmptyStateContainer";
import CompletedServicesDetails from "./CompletedServicesDetails";
// GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue);
function CompletedServicesPage() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const [getCompletedListofServices, setGetCompletedListofServices] = useState([]);
    const [openServicesDetailsDialog, setOpenServicesDetailsDialog] = useState(false);
    const [openFilterbyStatusAndDate, setOpenFilterbyStatusAndDate] = useState(false);
    const [showPagination, setShowPagination] = useState(false);
    const [selectedServiceStatus, setSelectedServiceStatus] = useState(-1);
    const [dateFromValue, setDateFromValue] = useState(moment().subtract(2, "days").format("YYYY-MM-DD"));
    const [dateToValue, setDateToValue] = useState(moment().add(7, "days").format("YYYY-MM-DD"));
    const [searchInput, setSearchInput] = useState("");
    const [completedRequestPage, setCompletedRequestPage] = useState(1);
    const [completedRequestRowCount, setCompletedRequestRowCount] = useState(20);
    const [servicesRowCount, setServicesRowCount] = useState(0);
    const [getDetailsId, setGetDetailsId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const capitalizeWords = (str) => str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    useEffect(() => {
        GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue);
        dispatch(setPageTitle("Service Request"));
    }, []);
    const GetCompletedListOfServices = async (
        StatusId = -1,
        dateFrom = moment().subtract(2, "days").format("YYYY-MM-DD"),
        dateTo = moment().add(7, "days").format("YYYY-MM-DD"),
        searchFilter = "",
        pageNumber = 1,
        rowCount = 20,
    ) => {
        setIsLoading(true);
        setCompletedRequestPage(pageNumber);
        setCompletedRequestRowCount(rowCount);
        const services = await API.get("ServiceProviderApi", `/GetCompletedServices?serviceStatusId=${StatusId}&dateFrom=${dateFrom}&dateTo=${dateTo}&filter=${searchFilter}&pageNumber=${pageNumber}&rowCount=${rowCount}`)
            .then((r) => r);
        // if (services.results.length !== 0) {
        const totalpages = Math.ceil(services.rowCount / rowCount);
        setServicesRowCount(services.results.length);
        setGetCompletedListofServices(services.results);
        setCompletedRequestRowCount(totalpages);

        if (services.results.length !== 0) {
            setShowPagination(true);
        } else setShowPagination(false);
        // } else {
        //     setServicesRowCount(0);
        //     setShowPagination(false);
        // }
        setIsLoading(false);
    };
    const handleCloseDetailsDialog = () => {
        setOpenServicesDetailsDialog(false);
    };
    const handleSelectServiceStatusOnChange = (e) => {
        setSelectedServiceStatus(e.target.value);
        // GetCompletedListOfServices(e.target.value);
    };
    const handleDateFromOnChange = (e) => {
        setDateFromValue(e.target.value);
        // GetCompletedListOfServices(selectedServiceStatus, e.target.value);
    };
    const handleDateToOnChange = (e) => {
        setDateToValue(e.target.value);
        // GetCompletedListOfServices(selectedServiceStatus, dateFromValue, e.target.value);
    };
    const handleCompletedServicesPagingOnChange = (event, value) => {
        if (value === completedRequestPage) return;
        GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue, searchInput, value);
    };
    const handleSearchbyPropertyNameOnClick = () => {
        GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue, searchInput);
    };

    return (
        <>
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{
                            marginBottom: theme.spacing(1),
                            paddingBottom: theme.spacing(1),
                            borderBottom: `1px solid ${theme.palette.background.default}`,
                        }}
                    >
                        <TextField
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                                // GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue, e.target.value);
                            }}
                            variant="outlined"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        size="small"
                                        onClick={handleSearchbyPropertyNameOnClick}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                ),
                            }}
                            value={searchInput}
                            sx={{
                                flexGrow: {
                                    xs: 1,
                                    sm: 0,
                                },
                            }}
                        />
                        <IconButton
                            onClick={() => { setOpenFilterbyStatusAndDate(true); }}
                            color="primary"
                            size="small"
                        >
                            <FilterAltIcon />
                        </IconButton>
                        <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                                GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue);
                            }}
                        >
                            <Refresh />
                        </IconButton>
                    </Stack>
                    <TableContainer>
                        <Table size="small">
                            {!isMobile && (
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Property Name</TableCell>
                                        <TableCell align="left">Provider Name</TableCell>
                                        <TableCell align="center">Service Type</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Scheduled Date</TableCell>
                                        <TableCell align="center" />
                                    </TableRow>
                                </TableHead>
                            )}
                            <TableBody>
                                {isLoading && (
                                    <TableRow>
                                        <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                                            <Skeleton
                                                width="100%"
                                                height={50}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(!isLoading && servicesRowCount === 0) ? (
                                    <TableRow>
                                        <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                                            <StyledEmptyStateContainer>
                                                <Warning />
                                                <Typography className="sub">No records found.</Typography>
                                            </StyledEmptyStateContainer>
                                        </TableCell>
                                    </TableRow>
                                )
                                    : getCompletedListofServices.map((s, i) => {
                                        const propertyLink = (
                                            <ButtonBase
                                                component={RouterLink}
                                                to={`/property/${s.propertyFK}`}
                                                size="small"
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                }}
                                            >
                                                {capitalizeWords(s.propertyName)}
                                            </ButtonBase>
                                        );
                                        return (
                                            <TableRow key={s.id}>
                                                {!isMobile && (
                                                    <>
                                                        <TableCell>
                                                            {propertyLink}
                                                        </TableCell>
                                                        <TableCell>
                                                            {s.serviceProviderName}
                                                        </TableCell>
                                                        <TableCell align="center">{s.serviceTypeName}</TableCell>
                                                        <TableCell align="center">
                                                            <Typography
                                                                variant="body2"
                                                                color={ServiceStatusColorCode({ statusCode: s.serviceStatusId })}
                                                            >
                                                                {s.serviceStatusName}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {`${moment.utc(s.requestDate).tz(moment.tz.guess()).format("llll zz")}`}
                                                        </TableCell>
                                                    </>
                                                )}
                                                <TableCell>
                                                    <Grid container spacing={1}>
                                                        {isMobile && (
                                                            <>
                                                                <Grid item xs={6}>
                                                                    {propertyLink}
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color={ServiceStatusColorCode({ statusCode: s.serviceStatusId })}
                                                                    >
                                                                        {s.serviceStatusName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                    >
                                                                        {s.serviceProviderName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                    >
                                                                        {s.serviceTypeName}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="text.secondary"
                                                                    >
                                                                        {`${moment.utc(s.requestDate).tz(moment.tz.guess()).format("llll zz")}`}
                                                                    </Typography>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        <Grid item xs={12}>
                                                            <CompletedServicesDetails
                                                                PassInfoId={s.id}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog
                        open={openFilterbyStatusAndDate}
                        maxWidth="xs"
                    >
                        <DialogTitle>Filter</DialogTitle>
                        <DialogContent>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    marginTop: theme.spacing(1),
                                }}
                            >
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <InputLabel id="label-id">Status</InputLabel>
                                        <Select
                                            labelId="label-id"
                                            variant="outlined"
                                            size="small"
                                            label="Status"
                                            value={selectedServiceStatus}
                                            onChange={handleSelectServiceStatusOnChange}
                                        >
                                            <MenuItem value={-1}>Show all</MenuItem>
                                            {CompletedStatusTypes.map((d) => (
                                                <MenuItem key={d.value} value={d.value}>{d.type}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>Scheduled Date</Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="date"
                                        value={dateFromValue}
                                        onChange={handleDateFromOnChange}
                                        label="From"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="To"
                                        fullWidth
                                        size="small"
                                        type="date"
                                        value={dateToValue}
                                        onChange={handleDateToOnChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Typography align="right">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    sx={{ mr: "5px" }}
                                    onClick={() => { setOpenFilterbyStatusAndDate(false); }}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="success"
                                    onClick={() => {
                                        if (moment(dateFromValue).diff(moment(dateToValue), "years") < 0) {
                                            window.alert("Date range is greater than 1 year");
                                        } else {
                                            GetCompletedListOfServices(selectedServiceStatus, dateFromValue, dateToValue);
                                            setOpenFilterbyStatusAndDate(false);
                                        }
                                    }}
                                >
                                    Apply Filter
                                </Button>
                            </Typography>
                        </DialogActions>
                    </Dialog>
                    {(getDetailsId !== null) && (
                        <CompletedServicesDetails
                            OpenDetailsDialog={openServicesDetailsDialog}
                            closeDetailsDialog={handleCloseDetailsDialog}
                            PassInfoId={getDetailsId}
                        />
                    )}
                </CardContent>
            </Card>

            {(showPagination && !isLoading) && (
                <Box
                    textAlign="right"
                >
                    <Pagination
                        variant="outlined"
                        shape="rounded"
                        count={completedRequestRowCount}
                        page={completedRequestPage}
                        onChange={handleCompletedServicesPagingOnChange}
                        sx={{
                            display: "inline-block",
                            marginTop: theme.spacing(1),
                        }}
                    />
                </Box>
            )}
        </>
    );
}

export default CompletedServicesPage;
