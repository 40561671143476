import { Cancel, CheckCircle, RadioButtonChecked, RadioButtonUnchecked, Send } from "@mui/icons-material";
import { Alert, Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputAdornment, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, OutlinedInput, Pagination, Paper, Select, Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../features/auth/authSlice";
import { useAddServiceProviderMutation } from "../../features/auth/hostAPI";
import { ServiceTypes } from "../../utils/enum";
import InviteServiceProvider from "./InviteServiceProvider";
import { StyledEmptyStateContainer } from "../helper/EmptyStateContainer";

const StyledList = styled(List)(({ theme }) => ({
    borderColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: "solid",
}));

function AddService({
    propertyInfo,
    open,
    callbackClose,
    callbackSuccess }) {
    const dispatch = useDispatch();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));
    const [selectedServiceType, setSelectedServiceType] = useState(0);
    const [showInvite, setShowInvite] = useState(false);
    const [serviceProviders, setServiceProviders] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchInput, setSearchInput] = useState("");
    const [providerPage, setProviderPage] = useState(1);
    const [providerRowCount, setProviderRowCount] = useState(10);
    const noSearchServiceProviderResult = !isLoading && (serviceProviders !== null && serviceProviders.pageCount === 0);
    const [disableSendInviteButton, setDisableSendInviteButton] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const initialInviteState = {
        serviceProviderId: 0,
        phoneNumber: "",
        group: "individual",
        firstName: "",
        lastName: "",
        serviceTypeId: 0,
        propertyFK: Number(propertyInfo.id),
        minAfterCheckOut: "00:30:00",
    };

    const [inviteServiceProvider, setInviteServiceProvider] = useState(initialInviteState);

    const initialServiceState = {
        propertyId: Number(propertyInfo.id),
        id: 0,
        timespanRequest: "00:30:00",
        serviceProviderId: 0,
        serviceTypeId: 0,
    };

    const initialShowAlertState = {
        open: false,
        severity: "info",
        message: "",
    };

    const [showAlert, setShowAlert] = useState(initialShowAlertState);

    const [addServiceProvider, {
        isSuccess: isSuccessAddServiceProvider,
        isError: isErrorAddServiceProvider,
    }] = useAddServiceProviderMutation();

    const getAllServiceProviders = async (id, filter = "", pageNumber = 1, rowCount = 10) => {
        setServiceProviders(null);
        setIsLoading(true);
        setProviderPage(pageNumber);
        setProviderRowCount(rowCount);
        await API.get("ServiceProviderApi", `/getAllByServiceTypeId?serviceTypeId=${id}&filter=${encodeURIComponent(filter)}&pageNumber=${pageNumber}&rowCount=${rowCount}`)
            .then((d) => {
                setServiceProviders(d);
                setIsLoading(false);

                setInviteServiceProvider((prev) => ({
                    ...prev,
                    serviceProviderId: 0,
                }));
            })
            .catch((e) => setIsLoading(false));
    };

    useEffect(() => {
        if (openDialog) {
            setSelectedServiceType(0);
            setShowInvite(false);
            setShowAlert(initialServiceState);
            getAllServiceProviders(selectedServiceType);
            setSearchInput("");
            setInviteServiceProvider(initialInviteState);
        }
    }, [openDialog]);

    useEffect(() => {
        const delayDbounceFn = setTimeout(() => {
            if (openDialog) { getAllServiceProviders(inviteServiceProvider.serviceTypeId, searchInput); }
        }, 2000);

        return () => clearTimeout(delayDbounceFn);
    }, [searchInput]);

    // handles Send Invite button enable/disable
    useEffect(() => {
        let disableBtn = false;

        if (!showInvite) {
            disableBtn = noSearchServiceProviderResult || isLoading;
        } else {
            disableBtn = inviteServiceProvider.firstName.trim().length === 0 || inviteServiceProvider.lastName.trim().length === 0;
        }
        setDisableSendInviteButton(disableBtn);
    }, [noSearchServiceProviderResult,
        isLoading,
        showInvite,
        inviteServiceProvider.firstName,
        inviteServiceProvider.lastName]);

    const handleServiceTypeOnChange = (e) => {
        setInviteServiceProvider((prev) => ({
            ...prev,
            serviceTypeId: e.target.value,
        }));

        getAllServiceProviders(e.target.value);
    };

    const handleSelectSP = (value) => () => {
        setInviteServiceProvider((prev) => ({
            ...prev,
            serviceProviderId: value,
        }));
    };

    const handleSelectRequestTimeOnChange = (e) => {
        setInviteServiceProvider((prev) => ({
            ...prev,
            minAfterCheckOut: e.target.value,
        }));
    };

    const handleShowAlert = ({ show, severity, message }) => {
        setShowAlert((prev) => ({
            ...prev,
            open: show,
            severity,
            message,
        }));
    };

    // callback invite
    const callbackInviteFirstName = (val) => {
        setInviteServiceProvider((prev) => ({
            ...prev,
            firstName: val,
        }));
    };
    const callbackInviteLastName = (val) => {
        setInviteServiceProvider((prev) => ({
            ...prev,
            lastName: val,
        }));
    };
    const callbackInvitePhoneNumber = (val) => {
        setInviteServiceProvider((prev) => ({
            ...prev,
            phoneNumber: val,
        }));
    };

    const SendInviteRequest = async (data) => {
        addServiceProvider({
            serviceProviderId: data.serviceProviderId,
            phoneNumber: data.phoneNumber,
            group: data.group,
            firstName: data.firstName,
            lastName: data.lastName,
            serviceTypeId: data.serviceTypeId,
            propertyFK: data.propertyFK,
            minAfterCheckOut: data.minAfterCheckOut,
        });
    };

    useEffect(() => {
        if (isSuccessAddServiceProvider) {
            // SendSMStoSP(inviteServiceProvider, dataAddServiceProvider);
            dispatch(showLoader({ open: false, message: "" }));
        }
    }, [isSuccessAddServiceProvider]);

    useEffect(() => {
        if (isErrorAddServiceProvider) {
            handleShowAlert({
                show: true,
                severity: "error",
                message: "Unable to send to invitation. Please try again.",
            });
            dispatch(showLoader({ open: false, message: "" }));
        }
    }, [isErrorAddServiceProvider]);

    // const SendSMStoSP = async (data, res) => {
    //     await API.post("PublishToSNSApi", "/SendInviteSMStoSP", {
    //         body: {
    //             phoneNumber: data.phoneNumber,
    //             inviteCode: res.inviteCode,
    //         },
    //     }).then((d) => {
    //         dispatch(showLoader({ open: false, message: "" }));
    //         handleShowAlert({
    //             show: true,
    //             severity: "success",
    //             message: "Invite successfully sent.",
    //         });
    //         callbackSuccess();
    //     }).catch((e) => {
    //         dispatch(showLoader({ open: false, message: "" }));
    //         handleShowAlert({
    //             show: true,
    //             severity: "error",
    //             message: "An invite was created in their Hostly.AI account, but the SMS could not be sent.",
    //         });
    //     });
    // };

    const handleSendInvite = () => {
        if (showInvite) {
            if (inviteServiceProvider.firstName.trim().length === 0
                || inviteServiceProvider.lastName.trim().length === 0
                || inviteServiceProvider.phoneNumber.trim().length === 0) {
                setShowAlert((prev) => ({
                    ...prev,
                    open: true,
                    severity: "error",
                    message: "Please complete provider details.",
                }));
            } else {
                // dispatch(showLoader({ open: true, message: "Sending invite. Please wait..." }));
                setShowAlert(initialShowAlertState);
                SendInviteRequest(inviteServiceProvider);
            }
        } else if (!showInvite) {
            if (inviteServiceProvider.serviceProviderId === 0) {
                setShowAlert({
                    message: "Please select a provider.",
                    severity: "error",
                    open: true,
                });
            } else {
                setShowAlert((prev) => ({
                    ...prev,
                    open: false,
                }));
                SendInviteRequest(inviteServiceProvider);
            }
        }
    };

    const handleProvidersPagingOnChange = (event, value) => {
        if (value === providerPage) return;
        getAllServiceProviders(selectedServiceType, searchInput, value, providerRowCount);
    };

    const CancelButton = (
        <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={() => setOpenDialog(false)}
            sx={{
                marginLeft: "0!important",
            }}
        >
            Cancel
        </Button>
    );

    const SendInviteButton = (
        <Button
            variant="contained"
            onClick={handleSendInvite}
            disabled={disableSendInviteButton}
            size="small"
        >
            Send Invite
        </Button>
    );

    const GoBackButton = (
        <Button
            variant="outlined"
            onClick={() => setShowInvite(false)}
            size="small"
        >
            Go back to provider&apos;s list.
        </Button>
    );

    const ShowDialogActionInviteNew = (
        <DialogActions
            sx={{
                flexDirection: {
                    xs: "column",
                    sm: "row-reverse",
                },
                alignItems: {
                    xs: "flex-start",
                    sm: "normal",
                },
                justifyContent: {
                    sm: "flex-start",
                },
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    marginBottom: {
                        xs: theme.spacing(1),
                        sm: 0,
                    },
                    marginLeft: {
                        xs: 0,
                        sm: theme.spacing(1),
                    },
                    width: {
                        xs: "100%",
                        sm: "initial",
                    },
                    justifyContent: {
                        xs: "space-between",
                        sm: "flex-start",
                    },
                }}
            >
                {GoBackButton}
                {SendInviteButton}
            </Stack>
            {CancelButton}
        </DialogActions>
    );

    const ShowNormalDialogAction = (
        <DialogActions>
            {CancelButton}
            {SendInviteButton}
        </DialogActions>
    );

    return (
        <>
            <Button
                variant="contained"
                onClick={() => setOpenDialog(true)}
            >
                Add Service
            </Button>
            <Dialog
                open={openDialog}
                maxWidth="sm"
                fullScreen={isMobile}
            >
                <DialogTitle>{showInvite ? "Invite a Service Provider" : "Add a Service Provider"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {showAlert.open && (
                            <Grid item xs={12}>
                                <Alert
                                    severity={showAlert.severity}
                                    onClose={() => handleShowAlert({ open: false, severity: "", message: "" })}
                                >
                                    {showAlert.message}

                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                size="small"
                                sx={{ marginTop: 1 }}
                            >
                                <InputLabel>Select Service Type</InputLabel>
                                <Select
                                    label="Select Service Type"
                                    value={inviteServiceProvider.serviceTypeId}
                                    onChange={handleServiceTypeOnChange}
                                >
                                    {ServiceTypes.map((d) => (
                                        <MenuItem key={d.value} value={d.value}>{d.type}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {!showInvite ? (
                                    <Grid item xs={12}>
                                        <Typography>Service Providers:</Typography>
                                        <Box sx={{
                                            marginBottom: 1,
                                        }}
                                        >
                                            <OutlinedInput
                                                size="small"
                                                fullWidth
                                                value={searchInput}
                                                placeholder="Search by phone number or by name"
                                                endAdornment={(
                                                    <InputAdornment position="end">
                                                        {searchInput.trim().length > 0 && (
                                                            <Button
                                                                size="small"
                                                                color="inherit"
                                                                onClick={() => setSearchInput("")}
                                                            >
                                                                Clear
                                                            </Button>
                                                        )}
                                                    </InputAdornment>
                                                )}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                        </Box>
                                        {isLoading && (
                                            <>
                                                <Box sx={{ marginBottom: 1 }}>
                                                    <Skeleton
                                                        variant="rounded"
                                                        height={32}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Skeleton
                                                        variant="rounded"
                                                        height={32}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                        {noSearchServiceProviderResult && (
                                            <Box
                                                sx={{
                                                    borderColor: theme.palette.background.default,
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <StyledEmptyStateContainer>
                                                    <div className="sub">Provider not found.</div>
                                                    <div className="sub">Want to add a new provider?</div>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => setShowInvite(true)}
                                                        endIcon={<Send />}
                                                        size="small"
                                                    >
                                                        Send an Invite
                                                    </Button>
                                                </StyledEmptyStateContainer>
                                            </Box>
                                        )}
                                        {(serviceProviders !== null && serviceProviders.pageCount > 0) && (
                                            <Stack spacing={1}>
                                                <StyledList
                                                    dense
                                                    sx={{
                                                        maxHeight: 200,
                                                        overflow: "auto",
                                                    }}
                                                >
                                                    {serviceProviders.results.map((m) => (
                                                        <ListItem
                                                            key={m.id}
                                                            disablePadding
                                                        >
                                                            <ListItemButton
                                                                selected={inviteServiceProvider.serviceProviderId === m.id}
                                                                onClick={handleSelectSP(m.id)}
                                                            >
                                                                <ListItemText>
                                                                    {`${m.fullName} (${m.phoneNumber})`}
                                                                </ListItemText>
                                                                {inviteServiceProvider.serviceProviderId === m.id
                                                                    ? (
                                                                        <CheckCircle
                                                                            color="primary"
                                                                        />
                                                                    ) : (
                                                                        <RadioButtonUnchecked
                                                                            color="action"
                                                                        />
                                                                    )}

                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </StyledList>
                                                <Box textAlign="right">
                                                    <Pagination
                                                        variant="text"
                                                        shape="rounded"
                                                        count={serviceProviders.pageCount}
                                                        page={serviceProviders.currentPage}
                                                        onChange={handleProvidersPagingOnChange}
                                                        sx={{
                                                            display: "inline-block",
                                                        }}
                                                    />
                                                </Box>
                                            </Stack>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <InviteServiceProvider
                                            iFirstName={inviteServiceProvider.firstName}
                                            iLastName={inviteServiceProvider.lastName}
                                            callbackInvitePhoneNumber={callbackInvitePhoneNumber}
                                            callbackInviteFirstName={callbackInviteFirstName}
                                            callbackInviteLastName={callbackInviteLastName}
                                            setShowInvite={setShowInvite}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    Schedule this service
                                    {" "}
                                    <Select
                                        size="small"
                                        variant="standard"
                                        value={inviteServiceProvider.minAfterCheckOut}
                                        onChange={handleSelectRequestTimeOnChange}
                                        sx={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <MenuItem value="00:05:00">5 minutes</MenuItem>
                                        <MenuItem value="00:10:00">10 minutes</MenuItem>
                                        <MenuItem value="00:15:00">15 minutes</MenuItem>
                                        <MenuItem value="00:30:00">30 minutes</MenuItem>
                                        <MenuItem value="01:00:00">1 hour</MenuItem>
                                        <MenuItem value="01:30:00">1 hour and 30 minutes</MenuItem>
                                        <MenuItem value="02:00:00">2 hours</MenuItem>
                                    </Select>
                                    {" "}
                                    after checking out.
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                {(serviceProviders !== null
                    && serviceProviders.pageCount === 0
                    && showInvite === true) ? (
                        ShowDialogActionInviteNew
                    ) : (
                        ShowNormalDialogAction
                    )}
            </Dialog>
        </>
    );
}

export default AddService;
