export const ServiceTypes = [
    {
        type: "Cleaning",
        value: 0,
    },
    {
        type: "Pest Control",
        value: 1,
    },
    {
        type: "Electrician",
        value: 2,
    },
    {
        type: "Plumber",
        value: 3,
    },
];

export const CompletedStatusTypes = [
    {
        type: "Pending Confirmation",
        value: 0,
    },
    {
        type: "Confirmed/Accepted",
        value: 1,
    },
    {
        type: "Ongoing",
        value: 2,
    },
    {
        type: "Done",
        value: 3,
    },
    {
        type: "Cancelled",
        value: 4,
    },
    {
        type: "Cancelled by Host",
        value: 5,
    },
];

export const GetCompletedStatusNameById = (id) => {
    const idx = CompletedStatusTypes.findIndex((x) => x.value === id);

    if (idx < 0) return "";

    return ServiceTypes[idx].type;
};

export const GetServiceTypeNameById = (id) => {
    const idx = ServiceTypes.findIndex((x) => x.value === id);

    if (idx < 0) return "";

    return ServiceTypes[idx].type;
};
