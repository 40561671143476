import { Box, Grid, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CustomPhoneInput from "../shared/custom_phoneinput";

function InviteServiceProvider({
    iFirstName,
    iLastName,
    callbackInvitePhoneNumber,
    callbackInviteFirstName,
    callbackInviteLastName,
    setShowInvite,
}) {
    const theme = useTheme();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        if (iFirstName !== undefined) { setFirstName(iFirstName); }
        if (iLastName !== undefined) { setLastName(iLastName); }
    }, [iFirstName, iLastName]);

    return (
        <Box
            sx={{
                border: `1px solid ${theme.palette.background.default}`,
                padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="First Name"
                        fullWidth
                        size="small"
                        value={firstName}
                        error={firstName.trim().length === 0}
                        onChange={(e) => {
                            if (callbackInviteFirstName !== null) { callbackInviteFirstName(e.target.value); }
                            setFirstName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Last Name"
                        fullWidth
                        size="small"
                        value={lastName}
                        error={lastName.trim().length === 0}
                        onChange={(e) => {
                            if (callbackInviteLastName !== null) { callbackInviteLastName(e.target.value); }
                            setLastName(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomPhoneInput
                        label="Phone"
                        fullWidth
                        onChange={(e) => {
                            if (callbackInvitePhoneNumber !== null) {
                                const phoneWithPlus = e[0] === "+" ? e : `+${e}`;
                                callbackInvitePhoneNumber(phoneWithPlus);
                            }
                            setPhoneNumber(e);
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default InviteServiceProvider;
