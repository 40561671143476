export const DOORSENSOR_DEVICETYPEID = 1;
export const MOTIONSENSOR_DEVICETYPEID = 3;
export const NA_DEVICETYPEID = 4;
export const LEAKSENSOR_DEVICETYPEID = 2;
export const THERMOSTAT_DEVICETYPEID = 8;

export const getUserFriendlyDeviceTypes = (typeId) => {
    switch (typeId) {
        case 1:
            return "Door Sensor";
        case 2:
            return "Leak Sensor";
        case 3:
            return "Motion Sensor";
        case 8:
            return "AC Thermostat";
        default:
            return "Device type does not exist";
    }
};
