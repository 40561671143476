export function ServiceStatusColorCode({ statusCode }) {
    let colorCode = "";
    switch (statusCode) {
        case 0:
            colorCode = "service_status.pending";
            break;
        case 1:
            colorCode = "service_status.confirmed";
            break;
        case 2:
            colorCode = "service_status.ongoing";
            break;
        case 3:
            colorCode = "service_status.done";
            break;
        case 4:
            colorCode = "service_status.cancelled";
            break;
        default:
            colorCode = "";
            break;
    }

    return colorCode;
}
