import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Alert, Grid, Skeleton, Stack } from "@mui/material";
import BookingCalendarBase from "./BookingCalendarBase";

export default function BookingCalendar({ bookings, isLoading, error }) {
    if (!isLoading) {
        return (
            <Grid container spacing={1}>
                {/* {showSuccessAlert && (
                    <Grid item xs={12}>
                        <Alert onClose={() => setShowSuccessAlert(false)}>Bookings refreshed.</Alert>
                    </Grid>
                )} */}
                <Grid item xs={12}>
                    {error && (
                        <Alert severity="error">An error occured.</Alert>
                    )}
                    {!error && bookings && bookings.length === 0 && (
                        <Alert severity="info">No bookings yet.</Alert>
                    )}
                    {!error && bookings && bookings.length !== 0 && (
                        <BookingCalendarBase bookings={bookings} />
                    )}
                </Grid>
            </Grid>
        );
    }
    return (
        <Stack spacing={1}>
            <Skeleton variant="rounded" height={500} />
        </Stack>
    );
}
