import { Grid, Divider } from "@mui/material";
import { Fragment } from "react";
import moment from "moment-timezone";
import "../../utils/moment-setup";

/*
    Example input:
    '2024-01-09T11:30:24+08:00,Ongoing,Completed,"this is\\na completed message"'
    Example output:
    ['2024-01-09T11:30:24+08:00', 'Ongoing', 'Completed', 'this is\na completed message']
*/
function parseRemark(remarkString) {
    const [date, lastState, currentState, ...body] = remarkString.split(",");
    return [date, lastState, currentState, JSON.parse(body.join(",") || "\"\"")];
}

function ServiceDetailsRemarks({ remarksString }) {
    if (typeof remarksString !== "string") return [];
    const remarksParsed = remarksString.split("\n").map((remarkString) => parseRemark(remarkString));
    return remarksParsed.map(([date, lastState, currentState, body], i) => date && lastState && currentState && (
        <Fragment key={date}>
            {i !== 0 && <Divider />}
            <Grid container alignItems="center" columnSpacing={1} sx={{ my: 0.5 }}>
                <Grid item sm>
                    <span style={{ fontWeight: "bold" }}>
                        {`[${lastState === currentState
                            ? currentState
                            : `${lastState} » ${currentState}`}] `}
                    </span>
                    <span>{body}</span>
                </Grid>
                <Grid item alignItems="center" sx={{ typography: "body2", color: "gray" }}>
                    {`${moment.parseZone(date).format("llll zz")} (${moment.parseZone(date).fromNow()})`}
                </Grid>
            </Grid>
        </Fragment>
    ));
}

export default ServiceDetailsRemarks;
