import React, { useEffect, useRef, useState } from "react";
import { AmplifyProvider, Geocoder, LocationSearch, MapView } from "@aws-amplify/ui-react";
import { Grid, List, ListItem, ListItemButton, ListItemText, TextField, Typography } from "@mui/material";
import { Marker, NavigationControl } from "react-map-gl";
import { Geo } from "aws-amplify";
// eslint-disable-next-line import/no-unresolved
// import "@aws-amplify/ui-react/styles.css";

const INITIAL_MAP_STATE = {
    latitude: 49.2819,
    longitude: -123.1187,
};

export default function MapComponent({ callBack, lat, lng, streetAddress1, mapSize }) {
    const mapRef = useRef();
    const [searchResult, setSearchResult] = useState([]);

    const [viewState, setViewState] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 14,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    });

    const triggerMapSearch = ({ val }) => {
        if (val.length > 2) {
            const searchOptions = {
                maxResults: 5,
            };
            Geo.searchByText(val, searchOptions).then((d) => {
                setSearchResult(d);
            });
        } else {
            setSearchResult([]);
        }
    };

    useEffect(() => {
        if (callBack) {
            callBack(viewState);
        }
    }, [viewState.longitude, viewState.latitude]);

    useEffect(() => {
        if (lat !== undefined && lng !== undefined) {
            // mapRef.current.flyTo({ center: [14.806010000000072, 121.02091000000007], zoom: 14 })
            setViewState((prev) => ({
                ...prev,
                latitude: lat,
                longitude: lng,
            }));
        } else {
            setViewState((prev) => ({
                ...prev,
                latitude: INITIAL_MAP_STATE.latitude,
                longitude: INITIAL_MAP_STATE.longitude,
            }));
        }
    }, [lat, lng]);

    useEffect(() => {
        if (streetAddress1) {
            setViewState({
                ...viewState,
                label: streetAddress1,
            });
            triggerMapSearch({ val: streetAddress1 });
        }
    }, [streetAddress1]);

    const handleCustomSearchOnChange = (e) => {
        setViewState({
            ...viewState,
            label: e.target.value,
        });

        triggerMapSearch({ val: e.target.value });
    };

    const displayMarker = (val) => {
        mapRef.current.flyTo({ center: [val.geometry.point[0], val.geometry.point[1]], zoom: viewState.zoom });
        setViewState((prev) => ({
            ...prev,
            label: val.label,
            country: val.country,
            municipality: val.municipality,
            postalCode: val.postalCode,
            region: val.region,
            street: val.street,
            timeZone: val.timeZone,
        }));
    };

    return (
        <Grid container>
            <Grid item xs={4}>
                <TextField
                    size="small"
                    value={viewState.label}
                    style={{ width: "95%", marginRight: "5%" }}
                    placeholder="Search your Address here to Autofill Fields"
                    onChange={handleCustomSearchOnChange}
                />
                <List>
                    {searchResult.map((s) => (
                        <ListItem disablePadding key={String(s.geometry.point)}>
                            <ListItemButton
                                onClick={(e) => {
                                    displayMarker(s);
                                }}
                            >
                                <ListItemText>
                                    <Typography variant="caption">{s.label}</Typography>
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={8}>
                <MapView
                    ref={mapRef}
                    latitude={viewState.latitude}
                    longitude={viewState.longitude}
                    zoom={viewState.zoom}
                    onMove={(e) => {
                        setViewState((prev) => ({
                            ...prev,
                            latitude: e.viewState.latitude,
                            longitude: e.viewState.longitude,
                            zoom: e.viewState.zoom,
                        }));
                    }}
                    style={mapSize}
                >
                    <Marker latitude={viewState.latitude} longitude={viewState.longitude} />

                    <NavigationControl />
                </MapView>
            </Grid>
        </Grid>
    );
}
