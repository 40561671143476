import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDeleteActionMutation } from "../../features/auth/actionsAPI";

export default function DeleteAction({
    ruleId,
    eventActionId,
    onClick,
}) {
    const [
        deleteActionData, {
            isLoading: isLoadingDeleteAction,
            isFetching: isFetchingDeleteAction,
        },
    ] = useDeleteActionMutation({
        fixedCacheKey: `delete-action-${ruleId}`,
    });

    const disableControl = isLoadingDeleteAction
    || isFetchingDeleteAction;
    const DeleteActionHandler = async (id) => {
        await deleteActionData({
            ruleId,
            eventActionId: id,
        });
    };
    return (
        <IconButton
            disabled={disableControl}
            color="error"
            size="small"
            onClick={() => {
                if (onClick !== undefined) onClick();
                DeleteActionHandler(eventActionId);
            }}
        >
            <Delete />
        </IconButton>
    );
}
