import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import { hostAPI } from "./features/auth/hostAPI";

const store = configureStore({
    reducer: {
        auth: authReducer,
        [hostAPI.reducerPath]: hostAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(
            hostAPI.middleware,
        ),
});

export default store;
