import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const actionsAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getActionsByRuleId: builder.query({
            queryFn: async ({
                ruleId,
            }) => {
                try {
                    const response = await API.get("IoTAPI", `/actions/rule=${ruleId}/`);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [{
                type: "actions",
                id: id.ruleId,
            }],
        }),
        addAction: builder.mutation({
            queryFn: async ({
                action,
            }) => {
                try {
                    const myInit = {
                        body: {
                            ...action,
                        },
                    };

                    const response = await API.post("IoTAPI", "/actions/", myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "actions",
                id: arg.action.RuleId,
            }],
        }),
        updateAction: builder.mutation({
            queryFn: async ({
                action,
            }) => {
                try {
                    const myInit = {
                        body: {
                            ...action,
                        },
                    };

                    const response = await API.put("IoTAPI", "/actions/", myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "actions",
                id: arg.action.RuleId,
            }],
        }),
        deleteAction: builder.mutation({
            queryFn: async ({
                ruleId,
                eventActionId,
            }) => {
                try {
                    const response = await API.del("IoTAPI", `/actions/${eventActionId}`);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "actions",
                id: arg.ruleId,
            }],
        }),
    }),
});

export const {
    useGetActionsByRuleIdQuery,
    useAddActionMutation,
    useUpdateActionMutation,
    useDeleteActionMutation,
    useLazyGetActionsByRuleIdQuery,
} = actionsAPI;
