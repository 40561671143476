import { Button, Container, Paper, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
    const theme = useTheme();
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const TakeHomeOnClick = () => {
        if (isAuthenticated) { navigate("/dashboard"); } else { navigate("/"); }
    };

    return (
        <Paper
            sx={{
                padding: theme.spacing(8),
                textAlign: "center",
            }}
            elevation={3}
        >
            <Typography
                variant="h5"
                color="primary"
                mb={theme.spacing(4)}
            >
                Page not found.
            </Typography>
            <Typography
                mb={theme.spacing(2)}
                component="div"
            >
                <div>The link you clicked may be broken, </div>
                <div>or the page may have been removed.</div>
            </Typography>
            <Button
                size="small"
                variant="contained"
                onClick={TakeHomeOnClick}
            >
                Take me back to
                {" "}
                {isAuthenticated ? "dashboard" : "homepage"}
            </Button>
        </Paper>
    );
}
