import { LoadingButton } from "@mui/lab";
import { CircularProgress, Grid, Paper, Stack, TextField, Typography, styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazySearchLocationQuery } from "../../features/auth/hostAPI";

const AddressItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
    boxShadow: theme.shadows[0],
    minHeight: 64,
    height: "100%",
}));

export default function CustomLocationFormGroup({
    propertyInfo,
    callbackResult,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const signUpStatusCode = useSelector((state) => state.auth.signUpProcessFlow.statusCode);

    const [PropertyAddress, setPropertyAddress] = useState("");
    const [AddressforSearch, setAddressforSearch] = useState("");
    const mapInitialState = {
        latitude: 0,
        longitude: 0,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    };

    const [mapLocation, setMapLocation] = useState(mapInitialState);

    const [result, setResult] = useState({
        PropertyAddress,
        mapLocation: mapInitialState,
    });

    const [triggerSearchLocation, {
        // data: dataSearchLocation,
        isLoading: isLoadingSearchLocation,
        isFetching: isFetchingSearchLocation,
    }] = useLazySearchLocationQuery();

    const [disableConfirmLocationButton, setDisableConfirmLocationButton] = useState(false);

    // useEffect(() => {
    //     console.log(">>", isLoadingSearchLocation, isFetchingSearchLocation);
    // }, [isLoadingSearchLocation, isFetchingSearchLocation]);

    useEffect(() => {
        if (propertyInfo !== undefined) {
            setAddressforSearch(propertyInfo.streetAddress1);
            setDisableConfirmLocationButton(true);
            setMapLocation({
                ...mapLocation,
                latitude: parseFloat(propertyInfo.locationLat),
                longitude: parseFloat(propertyInfo.locationLong),
                country: propertyInfo.country,
                municipality: propertyInfo.city,
                postalCode: propertyInfo.postalCode,
                region: propertyInfo.state,
                timeZone: propertyInfo.timeZone,
            });

            setResult((prev) => ({
                ...prev,
                PropertyAddress: propertyInfo.streetAddress1,
                mapLocation: {
                    latitude: parseFloat(propertyInfo.locationLat),
                    longitude: parseFloat(propertyInfo.locationLong),
                    country: propertyInfo.country,
                    municipality: propertyInfo.city,
                    postalCode: propertyInfo.postalCode,
                    region: propertyInfo.state,
                    timeZone: propertyInfo.timeZone,
                },
            }));
        }
    }, [propertyInfo]);

    const PerformLocationSearch = () => {
        triggerSearchLocation({
            locationVal: AddressforSearch.trim().toUpperCase(),
        }, true).then((data) => {
            const dataSearchLocation = data.data;

            if (data.data !== undefined && data.data !== null) {
                setMapLocation({
                    ...mapLocation,
                    country: dataSearchLocation[0].country || "",
                    longitude: dataSearchLocation[0].geometry.point[0],
                    latitude: dataSearchLocation[0].geometry.point[1],
                    municipality: dataSearchLocation[0].municipality || "",
                    region: dataSearchLocation[0].region || "",
                    postalCode: dataSearchLocation[0].postalCode || "",
                    timeZone: dataSearchLocation[0].timeZone.name || "",
                });
                setResult((prev) => ({
                    ...prev,
                    PropertyAddress: AddressforSearch,
                    mapLocation: {
                        country: dataSearchLocation[0].country || "",
                        longitude: dataSearchLocation[0].geometry.point[0],
                        latitude: dataSearchLocation[0].geometry.point[1],
                        municipality: dataSearchLocation[0].municipality || "",
                        region: dataSearchLocation[0].region || "",
                        postalCode: dataSearchLocation[0].postalCode || "",
                        timeZone: dataSearchLocation[0].timeZone.name || "",
                    },
                }));
                setDisableConfirmLocationButton(true);
            }
        });
    };

    useEffect(() => {
        // if (result.PropertyAddress.trim().length > 0) {
        if (callbackResult !== undefined) callbackResult(result);
        // }
    }, [result]);

    const isSearchLocationLoading = isLoadingSearchLocation || isFetchingSearchLocation;

    return (
        <Grid container spacing={1}>
            <Grid
                item
                xs={12}
            >
                <Stack
                    direction={{
                        xs: "column",
                        sm: "row",
                    }}
                    spacing={1}
                >
                    <TextField
                        onChange={(e) => {
                            setAddressforSearch(e.target.value);
                            setDisableConfirmLocationButton(false);
                            setMapLocation(mapInitialState);
                            setResult((prev) => ({
                                ...prev,
                                PropertyAddress: e.target.value,
                                mapLocation: mapInitialState,
                            }));
                        }}
                        label="Street Address"
                        size="small"
                        value={AddressforSearch}
                        error={AddressforSearch.trim().length === 0}
                        helperText={AddressforSearch.trim().length === 0 && "* Property address is required."}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") PerformLocationSearch();
                        }}
                        onBlur={() => PerformLocationSearch()}
                        sx={{
                            flexGrow: 1,
                        }}
                    />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        sx={{
                            height: 40,
                        }}
                        disabled={disableConfirmLocationButton}
                        onClick={() => PerformLocationSearch()}
                        loadingIndicator={(
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )}
                        loading={isSearchLocationLoading}
                    >
                        Confirm Location
                    </LoadingButton>
                </Stack>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="common.black"
                    >
                        Country
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color="common.black"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.country === "" ? "-" : mapLocation.country}

                    </Typography>
                </AddressItem>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="common.black"
                    >
                        City
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color="common.black"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.municipality === "" ? "-" : mapLocation.municipality}
                    </Typography>
                </AddressItem>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="common.black"
                    >
                        State
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color="common.black"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.region === "" ? "-" : mapLocation.region}
                    </Typography>
                </AddressItem>
            </Grid>
            <Grid item xs={6} lg={3}>
                <AddressItem>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="common.black"
                    >
                        Zip
                    </Typography>
                    <Typography
                        component="div"
                        variant="caption"
                        color="common.black"
                    >
                        {isSearchLocationLoading ? (
                            <CircularProgress
                                size={theme.spacing(2)}
                            />
                        )
                            : mapLocation.postalCode === "" ? "-" : mapLocation.postalCode}
                    </Typography>
                </AddressItem>
            </Grid>
        </Grid>
    );
}
