import { Select, TextField, InputLabel, FormControl } from "@mui/material";
import { useState, useEffect, createRef } from "react";

function convertToSlug(text) {
    return text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
}

export default function DropdownWithOtherField({ onChange, value, fullWidth, type, label, required, inputProps, children }) {
    const [isOther, setIsOther] = useState(false);
    const textFieldRef = createRef();
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const onSelectChange = (e) => {
        if (e.target.value === "Others") {
            // setIsOther(true);
            onChange({ target: { value: "" } });
        } else {
            onChange(e);
        }
    };

    useEffect(() => {
        if (isOther && textFieldRef.current) {
            textFieldRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOther]);
    return isOther ? (
        <TextField
            fullWidth={fullWidth}
            onChange={onChange}
            // onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            value={value}
            type={type}
            label={label}
            required={required}
            inputProps={inputProps}
            inputRef={textFieldRef}
        />
    ) : (
        <FormControl fullWidth={fullWidth} size="small">
            <InputLabel id={`${convertToSlug(label)}-label`}>{label}</InputLabel>
            <Select
                labelId={`${convertToSlug(label)}-label`}
                onChange={onSelectChange}
                value={value}
                type={type}
                label={label}
                required={required}
            >
                {children}
            </Select>
        </FormControl>
    );
}
