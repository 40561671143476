import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useCancelServiceRequestMutation } from "../../features/auth/hostAPI";

export default function CancelServiceRequest({ sr }) {
    const [openDlg, setOpenDlg] = useState(false);
    const [cancelServiceRequest, {
        data: dataCancelServiceRequest,
        isLoading: isLoadingCancelServiceRequest,
    }] = useCancelServiceRequestMutation();

    useEffect(() => {
        if (dataCancelServiceRequest !== undefined) {
            if (dataCancelServiceRequest === true) setOpenDlg(false);
        }
    }, [dataCancelServiceRequest]);

    if (sr.saR_StatusId === 2
        || sr.sR_StatusId === 3
        || sr.sR_StatusId === 4
        || sr.sR_StatusId === 5) return <>&nbsp;</>;

    const handleCancelService = async (s) => {
        await cancelServiceRequest({
            sarId: s.saR_Id,
            srId: s.sR_Id,
            sarStatusId: s.saR_StatusId,
            srStatusId: s.sR_StatusId,
        });
    };

    return (
        <>
            <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => setOpenDlg(true)}
            >
                Cancel Request
            </Button>
            <Dialog
                open={openDlg}
            >
                <DialogTitle>Cancel Service Request</DialogTitle>
                <DialogContent>
                    {`Are you sure you want to cancel ${sr.name}'s ${sr.serviceType} service dated ${sr.convertedDateOfRequest} at ${sr.convertedTimeOfRequest}?`}
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isLoadingCancelServiceRequest}
                        onClick={() => setOpenDlg(false)}
                        variant="outlined"
                        size="small"
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoadingCancelServiceRequest}
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            handleCancelService(sr);
                        }}
                    >
                        Proceed
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
