import React, { useEffect, useState } from "react";
import {
    Checkbox,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { StyledTableCell } from "../helper/StyleTable";
// import { getAllDeviceTypes } from "../../api/IoT/types";
import { useGetDeviceTypesQuery } from "../../features/auth/devicesAPI";

function ProxyDevice({ setDevData, devData, setName, showLoader, floorNumber, setFloorNumber }) {
    const { data: dataGetDeviceTypes } = useGetDeviceTypesQuery();
    // const [deviceTypes, setDeviceTypes] = useState([]);
    const convertToProperCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    // useEffect(() => {
    //     (async () => {
    //         setDeviceTypes(await getAllDeviceTypes());
    //     })();
    //     // console.log(deviceTypes);
    // }, [showLoader]);

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={devData.type === "doorSensor" ? 4 : 3}>
                            <h3>IFTTT Devices</h3>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        {devData.type === "doorSensor" && (
                            <TableCell>Used as check in/out</TableCell>
                        )}
                        <TableCell>Floor No.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {showLoader && (
                        <TableRow>
                            <StyledTableCell
                                colSpan={devData.type === "doorSensor" ? 5 : 4}
                            >
                                <Skeleton variant="rounded" height={30} width="inherit">
                                    Loading...Please Wait
                                </Skeleton>
                            </StyledTableCell>
                        </TableRow>
                    )}
                    {!showLoader && (
                        <TableRow>
                            <TableCell>
                                <TextField
                                    className="ifttt-name"
                                    margin="normal"
                                    // error={customRuleGroup.name.length === 0}
                                    // helperText="Name must be filled out."
                                    // placeholder="Enter alert group name."
                                    size="small"
                                    fullWidth
                                    // disabled={disableControls}
                                    // label="Device name:"
                                    // value={currentDevice.DisplayName}
                                    value={devData.DisplayName}
                                    onChange={(e) => {
                                        // setCurrentDevice({ ...currentDevice, DisplayName: e.target.value });
                                        setName(e.target.value);
                                        setDevData({ ...devData, DisplayName: e.target.value });
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                {dataGetDeviceTypes !== undefined && dataGetDeviceTypes.length > 0 && (
                                    <Select
                                        // disabled={disableControls}
                                        size="small"
                                        fullWidth
                                        // value={currentDevice.DeviceTypeId}
                                        value={devData.type}
                                        onChange={(e) => {
                                            // setCurrentDevice({ ...currentDevice, DeviceTypeId: e.target.value });
                                            // checkInDoorHandler(e.target.value);
                                            // setTypeId(e.target.value);
                                            setDevData({
                                                ...devData,
                                                type: e.target.value,
                                                Name: e.target.value,
                                            });
                                        }}
                                    >
                                        {dataGetDeviceTypes !== undefined && dataGetDeviceTypes.map((t) => (
                                            <MenuItem key={t.deviceTypeId} value={t.name}>
                                                {/* {convertToProperCase(t.name)} */}
                                                {t.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </TableCell>
                            {devData.type === "doorSensor" && (
                                <TableCell>
                                    <Checkbox
                                        checked={devData.isCheckInOutDoor}
                                        onChange={(e) => {
                                            setDevData({
                                                ...devData,
                                                isCheckInOutDoor: e.target.checked,
                                            });
                                        }}
                                    />
                                </TableCell>
                            )}
                            <TableCell>
                                <TextField
                                    className="txtFloorNumber"
                                    // label="Floor Number"
                                    type="number"
                                    error={floorNumber.length === 0}
                                    // helperText="Floor Number must be filled out"
                                    onChange={(e) => {
                                        setFloorNumber(e.target.value);
                                    }}
                                    fullWidth
                                    size="small"
                                    value={floorNumber}
                                // disabled={loading}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {/* <TableRow>
                        <TextField
                            className="txtFloorNumber"
                            label="Floor Number"
                            type="number"
                            error={floorNumber.length === 0}
                            helperText="Floor Number must be filled out"
                            onChange={(e) => {
                                setFloorNumber(e.target.value);
                            }}
                            fullWidth
                            size="small"
                            // disabled={loading}
                        />
                    </TableRow> */}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ProxyDevice;
