import { TextField } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import MapComponent from "./MapComponent";

function TestMapComponent() {
    const [mapLocation, setMapLocation] = useState({
        latitude: 0,
        longitude: 0,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    });

    const mapCallBack = (e) => {
        setMapLocation((prev) => ({
            ...prev,
            latitude: e.latitude,
            longitude: e.longitude,
            label: e.label,
            country: e.country,
            municipality: e.municipality,
            postalCode: e.postalCode,
            region: e.region,
            street: e.street,
            timeZone: e.timeZone,
        }));
    };

    return (
        <>
            <div>
                {/* <TextField placeholder='Search Street Address...' size='small'
                onChange={(e) => {

                }}
            /> */}
            </div>
            <div>
                <MapComponent callBack={mapCallBack} mapSize={{ width: 400, height: 400 }} streetAddress1={mapLocation.label} />
            </div>
            <div>
                <TextField
                    size="small"
                    label="Label"
                    value={mapLocation.label}
                    onChange={(e) => {
                        setMapLocation({
                            ...mapLocation,
                            label: e.target.value,
                        });
                    }}
                />
            </div>
            <div>
                <pre>{JSON.stringify(mapLocation, null, 2)}</pre>
            </div>
        </>
    );
}

export default TestMapComponent;
