import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const conditionsAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getConditionsByRule: builder.query({
            queryFn: async ({
                ruleId,
            }) => {
                try {
                    const response = await API.get("IoTAPI", `/conditions/${ruleId}`);
                    // rename unreachable_by_partner to no response
                    if (response.data.deviceConditions.length > 0) {
                        for (let index = 0; index < response.data.deviceConditions.length; index++) {
                            const element = response.data.deviceConditions[index];
                            if (element.stateName === "unreachable_by_partner") element.stateName = "no resposne";
                            response.data.deviceConditions[index] = element;
                        }
                    }
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [{
                type: "conditions",
                id: id.ruleId,
            }],
        }),
        addRelativeTimeCondition: builder.mutation({
            queryFn: async ({
                condition,
            }) => {
                try {
                    const myInit = {
                        body: {
                            ...condition,
                        },
                    };
                    const response = await API.post("IoTAPI", "/conditions/relativetime", myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions", id: arg.condition.RuleId,
            }],
        }),
        addDeviceCondition: builder.mutation({
            queryFn: async ({
                condition,
            }) => {
                try {
                    const myInit = {
                        body: {
                            ...condition,
                        },
                    };

                    const response = await API.post("IoTAPI", "/conditions/device", myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions",
                id: arg.condition.RuleId,
            }],
        }),
        updateDeviceCondition: builder.mutation({
            queryFn: async ({
                id,
                condition,
            }) => {
                try {
                    const myInit = {
                        body: {
                            ...condition,
                        },
                    };

                    const response = await API.put("IoTAPI", `/conditions/device/${id}`, myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions",
                id: arg.condition.ruleId,
            }],
        }),
        deleteDeviceCondition: builder.mutation({
            queryFn: async ({
                id,
            }) => {
                try {
                    const response = await API.del("IoTAPI", `/conditions/device/${id}`);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions",
                id: result.ruleId,
            }],
        }),
        deleteRelativeTimeCondition: builder.mutation({
            queryFn: async ({
                id,
            }) => {
                try {
                    const response = await API.del("IoTAPI", `/conditions/relativetime/${id}`);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions",
                id: result.ruleId,
            }],
        }),
        deleteGroupCondition: builder.mutation({
            queryFn: async ({
                id,
            }) => {
                try {
                    const response = await API.del("IoTAPI", `/conditions/group/${id}`);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions",
                id: result.ruleId,
            }],
        }),
        addGroupCondition: builder.mutation({
            queryFn: async ({
                RuleId,
                RuleGroups,
            }) => {
                try {
                    const myInit = {
                        body: {
                            RuleId,
                            RuleGroups,
                            IsActive: true,
                        },
                    };
                    const response = await API.post("IoTAPI", "/conditions/group", myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                type: "conditions",
                id: arg.RuleId,
            }],
        }),
        updateRelativeTimeCondition: builder.mutation({
            queryFn: async ({
                id,
                condition,
            }) => {
                try {
                    const myInit = {
                        body: {
                            ...condition,
                        },
                    };

                    const response = await API.put("IoTAPI", `/conditions/relativetime/${id}`, myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [{
                // type: "conditions", id: arg.condition.ruleId,
                type: "conditions",
                id: arg.condition.RuleId,
            }],
        }),
    }),
});

export const {
    useGetConditionsByRuleQuery,
    useAddRelativeTimeConditionMutation,
    useAddDeviceConditionMutation,
    useAddGroupConditionMutation,
    useUpdateRelativeTimeConditionMutation,
    useUpdateDeviceConditionMutation,
    useDeleteDeviceConditionMutation,
    useDeleteRelativeTimeConditionMutation,
    useDeleteGroupConditionMutation,
    useLazyGetConditionsByRuleQuery,
} = conditionsAPI;
