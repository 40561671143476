import { Save } from "@mui/icons-material";
import { TextField, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { alignProperty } from "@mui/material/styles/cssUtils";
import { UpdateRelativeTimeCondition } from "../../api/IoT/conditions";

function PCoRSetUp({ relTimeCond, callbackRequired }) {
    const [timeCondition, setTimeCondition] = useState({
        conditionId: relTimeCond.timeConditionId,
        TimeValue: relTimeCond.timeValue,
        relativeTime: relTimeCond.relativeTime,
        relative: "",
        checkin: "",
        RuleId: relTimeCond.ruleId,
        isActive: relTimeCond.isActive,
        BookingAlertLimit: relTimeCond.bookingAlertLimit,
        MinuteAlertLimit: relTimeCond.minuteAlertLimit,
        MinuteAlertTimeInterval: relTimeCond.minuteAlertTimeInterval,
    });
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    function numberOnly(id) {
        const element = document.getElementById(id);
        element.value = element.value.replace(/[^0-9]/gi, "");
    }
    const handleTimeChange = (e) => {
        setTimeCondition({
            ...timeCondition,
            TimeValue: e.target.value,
        });
    };

    const EditConditionHandler = async () => {
        setLoadingUpdate(true);
        await UpdateRelativeTimeCondition(timeCondition.conditionId, timeCondition);
        callbackRequired(setLoadingUpdate);
    };

    const CancelConditionHandler = async () => {
        setLoadingUpdate(true);
        callbackRequired(setLoadingUpdate);
    };
    return (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <TextField
                variant="outlined"
                value={timeCondition.TimeValue}
                label="Minutes"
                onInput={(e) => numberOnly(e.target.id)}
                inputProps={{
                    maxLength: 4,
                }}
                onChange={handleTimeChange}
                sx={{ width: "100px", mr: 2 }}
            />
            <LoadingButton
                variant="contained"
                loading={loadingUpdate}
                loadingPosition="center"
                onClick={(e) => {
                    if (timeCondition.TimeValue === "" || timeCondition.TimeValue === 30) {
                        CancelConditionHandler(e);
                    } else {
                        EditConditionHandler(e);
                    }
                }}
            >
                <Typography variant="button">Proceed</Typography>
            </LoadingButton>
        </Box>

    );
}

export default PCoRSetUp;
