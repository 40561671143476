import { useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

export default function BookingCalendarBase({ bookings }) {
    const events = bookings.map((booking) => ({
        start: new Date(moment.utc(booking.checkInUTC)),
        end: new Date(moment.utc(booking.checkOutUTC)),
        title: booking.name,
    }));

    // const handleOnSelectEvent = (calendarEvent, syntheticEvent) => {};

    const { defaultDate } = useMemo(
        () => ({
            defaultDate: new Date(),
        }),
        [],
    );
    return (
        <Calendar
            localizer={localizer}
            events={events}
            defaultDate={defaultDate}
            defaultView="month"
            style={{ height: "540px" }}
            allDayAccessor={() => false}
            showMultiDayTimes
            tooltipAccessor={(event) => event.title}
            // onSelectEvent={handleOnSelectEvent}
            selectable={false}
        />
    );
}
