const ActionModel = () => {
    const actionModel = {
        EventActionsId: 0,
        ActionReferenceId: 0,
        RuleId: 0,
        DeviceId: "",
        PartnerAccountId: 0,
        PartnerId: 0,
        Parameters: "",
        IsActive: true,
    };
    return actionModel;
};

export default ActionModel;
