import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyGetAccountsByIdQuery } from "../../features/auth/accountsAPI";
import { useGetAllActionReferencesQuery } from "../../features/auth/actionReferenceAPI";
import { useAddActionMutation } from "../../features/auth/actionsAPI";
import { useGetDevicesByPropertyIdQuery, useLazyGetDevicesByPropertyIdQuery } from "../../features/auth/devicesAPI";
import ActionModel from "./models/ActionModel";
import ActionReferenceModel from "./models/ActionReferenceModel";
import { IFTTT_PARTNERID } from "./constants/Partners";
import { NA_DEVICETYPEID } from "./constants/DeviceTypes";
import { getAccountsbyId } from "../../api/IoT/accounts";

export default function AddAction({
    ruleId,
    propertyId,
    onClick,
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [actionRefList, setActionRefList] = useState([]);
    const [deviceList, setDeviceList] = useState([]);
    const [selectedActionRefObject, setSelectedActionRefObject] = useState(ActionReferenceModel());
    const [actionRefDeviceList, setActionRefDeviceList] = useState([]);
    const [actionHasParam, setActionHasParam] = useState(false);
    const [selectedActionRefDeviceObject, setSelectedActionRefDeviceObject] = useState();
    const [actionObject, setActionObject] = useState(ActionModel());
    const [paramValue, setParamValue] = useState(16);
    const [addDisabled, setAddDisabled] = useState(false);
    const [paramUnitList, setParamUnitList] = useState([]);
    const [selectedTempUnit, setSelectedTempUnit] = useState(null);

    const [showAddAction, setShowAddAction] = useState(false);

    // might be queried from db too
    const parameterUnits = [
        {
            id: 1,
            measurement: "temperature",
            unit: "°C",
            condition: "Temperature must be between 16°C and 35°C",
            error: [16, 35],
        },
        {
            id: 2,
            measurement: "temperature",
            unit: "°F",
            condition: "Temperature must be between 60°F and 95°F",
            error: [60, 95],
        },
    ];

    // << rtk query
    // const {
    //     data: dataDevicesByProperty,
    //     isLoading: isLoadingGetDevicesByPropertyId,
    //     isFetching: isFetchingGetDevicesByPropertyId,
    // } = useGetDevicesByPropertyIdQuery({
    //     propertyId,
    // });
    const [triggerGetDevicesByPropertyId, {
        data: dataDevicesByProperty,
        isLoading: isLoadingGetDevicesByPropertyId,
        isFetching: isFetchingGetDevicesByPropertyId,
    }] = useLazyGetDevicesByPropertyIdQuery();
    const {
        data: dataAllActionReferences,
        isLoading: isLoadingGetAllActionReferences,
        isFetching: isFetchingGetAllActionReferences,
    } = useGetAllActionReferencesQuery();
    const [
        triggerGetAccounts,
    ] = useLazyGetAccountsByIdQuery();
    const [
        addAction, {
            isLoading: isLoadingAddAction,
            isFetching: isFetchingAddAction,
        }] = useAddActionMutation({
        fixedCacheKey: `add-action-${ruleId}`,
    });

    const disableControl = isLoadingGetDevicesByPropertyId
        || isFetchingGetDevicesByPropertyId
        || isLoadingGetAllActionReferences
        || isFetchingGetAllActionReferences
        || isLoadingAddAction || isFetchingAddAction;

    useEffect(() => {
        // get devices
        if (dataDevicesByProperty !== undefined) {
            setDeviceList(structuredClone(dataDevicesByProperty));
        }
    }, [dataDevicesByProperty]);

    useEffect(() => {
        // get action references
        if (dataAllActionReferences !== undefined) {
            setSelectedActionRefObject(dataAllActionReferences[0]);
            setActionRefList(dataAllActionReferences);
        }
    }, [dataAllActionReferences]);
    // rtk query >>

    useEffect(() => {
        setParamValue(null);
        setParamUnitList(parameterUnits);
        setSelectedTempUnit(parameterUnits[0]);
    }, []);

    useEffect(() => {
        if (showAddAction) {
            triggerGetDevicesByPropertyId({
                propertyId,
            });
        }
    }, [showAddAction]);

    const actionHasParamChecker = (actionRefName) => {
        const nameArray = actionRefName.split(" ");
        if (nameArray[0] === "Set") {
            setActionHasParam(true);
        } else {
            setActionHasParam(false);
        }
    };

    const onChangeActionRefHandler = async (actionRefObject) => {
        setSelectedActionRefObject(actionRefObject);
        actionHasParamChecker(actionRefObject.name);
        const actionRefDevices = deviceList.filter(
            (d) => d.deviceTypeId === actionRefObject.deviceTypeId,
        );
        setActionRefDeviceList(actionRefDevices);
        if (actionRefDevices.length === 0) {
            setSelectedActionRefDeviceObject(null);
            if (actionRefObject.deviceTypeId !== NA_DEVICETYPEID) {
                setAddDisabled(true);
            } else {
                setAddDisabled(false);
            }
        } else {
            setSelectedActionRefDeviceObject(actionRefDevices[0]);
            setAddDisabled(false);
        }

        if (actionRefObject.state === null || !actionRefObject.state) {
            setParamValue(null);
        } else {
            setParamValue(16);
        }
    };

    const addActionHandler = async () => {
        // setAddDisabled(true);
        // setAddLoading(true);
        let partnerAccountObject = null;
        if (selectedActionRefDeviceObject != null) {
            partnerAccountObject = await getAccountsbyId(
                selectedActionRefDeviceObject.partnerAccountId,
            );
            triggerGetAccounts({
                id: selectedActionRefDeviceObject.partnerAccountId,
            }).then((d) => {
                partnerAccountObject = d;
            });
        }
        const actionObjectSubmit = {
            ActionReferenceId: selectedActionRefObject.actionReferenceId,
            RuleId: ruleId,
            DeviceId:
                selectedActionRefDeviceObject != null
                    ? selectedActionRefDeviceObject.deviceId
                    : null,
            PartnerAccountId:
                selectedActionRefDeviceObject != null
                    ? selectedActionRefDeviceObject.partnerAccountId
                    : null,
            PartnerId: partnerAccountObject != null ? partnerAccountObject.partnerId : (selectedActionRefObject.name === "Trigger IFTTT Service" ? IFTTT_PARTNERID : null),
            Parameters: paramValue != null ? `${paramValue} ${selectedTempUnit.unit}` : null,
            IsActive: true,
        };
        setActionObject({ ...actionObject, actionObjectSubmit });
        await addAction({ action: actionObjectSubmit });
        // setAddLoading(false);
        // setAddDisabled(false);
        setShowAddAction(false);
        // setReloadPage((x) => !x);
    };

    return (
        <>
            <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => {
                    if (onClick !== undefined) onClick();
                    setShowAddAction(true);
                }}
                fullWidth={isMobile}
                sx={{
                    marginLeft: {
                        xs: 0,
                        md: theme.spacing(7),
                    },
                    minWidth: {
                        sm: 0,
                        md: 166,
                    },
                }}
            >
                <Add />
                {" "}
                Add Action
            </LoadingButton>

            <Dialog
                open={showAddAction}
                fullWidth
                fullScreen={isMobile}
                maxWidth="sm"
            >
                <DialogTitle>Add Action</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {actionRefList.length === 0 && (
                            <Grid item xs={12}><Skeleton variant="rounded" height={30} width="inherit">Loading...Please Wait</Skeleton></Grid>
                        )}
                        {actionRefList.length > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <InputLabel>Choose an Action:</InputLabel>
                                    {actionRefList.length > 0 && actionRefList.includes(selectedActionRefObject) && (
                                        <Select
                                            disabled={disableControl}
                                            value={selectedActionRefObject}
                                            onChange={(e) => {
                                                onChangeActionRefHandler(e.target.value);
                                            }}
                                            fullWidth
                                            size="small"
                                        >
                                            {actionRefList.length > 0
                                                && actionRefList.map((a) => (
                                                    <MenuItem key={a.actionReferenceId} value={a}>
                                                        {a.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    )}
                                </Grid>
                                {actionRefDeviceList.length > 0 && actionRefDeviceList.includes(selectedActionRefDeviceObject) && (
                                    <Grid item xs={12}>
                                        <InputLabel>
                                            Choose your
                                            {" "}
                                            {actionRefDeviceList[0].name}
                                            :
                                        </InputLabel>
                                        <Select
                                            disabled={disableControl}
                                            value={selectedActionRefDeviceObject}
                                            size="small"
                                            onChange={(e) => {
                                                setSelectedActionRefDeviceObject(e.target.value);
                                            }}
                                            fullWidth
                                        >
                                            {actionRefDeviceList.map((d) => (
                                                <MenuItem key={d.deviceId} value={d}>
                                                    {d.displayName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                )}
                                {(actionRefDeviceList.length === 0
                                    && selectedActionRefObject.deviceTypeId !== NA_DEVICETYPEID) && (
                                    <Grid item xs={8}>
                                        <InputLabel>
                                            No devices for that action in the property.
                                        </InputLabel>
                                    </Grid>
                                )}
                                {actionHasParam && paramUnitList.includes(selectedTempUnit) && (
                                    <>
                                        <Grid item>
                                            <TextField
                                                disabled={disableControl || addDisabled}
                                                sx={{ width: "20ch" }}
                                                label={selectedActionRefObject.name}
                                                value={paramValue}
                                                error={
                                                    paramValue < selectedTempUnit.error[0]
                                                    || paramValue > selectedTempUnit.error[1]
                                                }
                                                helperText={
                                                    paramValue.length === 0
                                                        ? "Required"
                                                        : selectedTempUnit.condition
                                                }
                                                onChange={(e) => {
                                                    setParamValue(e.target.value);
                                                }}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                disabled={disableControl || addDisabled}
                                                value={selectedTempUnit}
                                                size="small"
                                                onChange={(e) => {
                                                    setSelectedTempUnit(e.target.value);
                                                }}
                                            >
                                                {paramUnitList.map((u) => (
                                                    <MenuItem key={u.id} value={u}>
                                                        {u.unit}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        disabled={disableControl}
                        onClick={() => setShowAddAction(false)}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        variant="contained"
                        onClick={addActionHandler}
                        disabled={disableControl || addDisabled}
                        loading={disableControl}
                    >
                        Add
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
