import { Box, styled } from "@mui/material";

export const StyledEmptyStateContainer = styled(Box)(({ theme }) => ({
    display: "inline-block",
    padding: theme.spacing(2),
    textAlign: "center",
    ">.MuiSvgIcon-root": {
        width: 48,
        height: 48,
        color: theme.palette.text.disabled,
    },
    ".main": {
        fontWeight: "bold",
        marginBottom: theme.spacing(2),
    },
    ".sub": {
        color: "#ADB9CC",
        marginBottom: theme.spacing(2),
    },
}));
