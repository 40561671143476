import { Clear, Rtt, TaskAlt } from "@mui/icons-material";
import { Box, CircularProgress, Grid, OutlinedInput, Stack, TextField, Typography, styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormControl } from "@mui/base";
import { useLazyCheckPropertyNameExistQuery } from "../../features/auth/hostAPI";

const StyledInput = styled(FormControl)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
}));

export default function PropertyNameComponent(props) {
    const theme = useTheme();
    const { value, onChange, onBlur, propertyid } = props;
    const [errorMsg, setErrorMessage] = useState("");
    const [isPropertyNameAvailable, setIsPropertyNameAvailable] = useState(false);

    const [triggerCheckPropertyNameExists, {
        isLoading: isLoadingCheckPropertyNameExists,
        isFetching: isFetchingCheckPropertyNameExists,
    }] = useLazyCheckPropertyNameExistQuery();

    useEffect(() => {
        if (value.trim().length === 0) setErrorMessage("* Property name is required");
        else setErrorMessage("");
    }, [value]);

    const isCheckingPropertyName = isLoadingCheckPropertyNameExists || isFetchingCheckPropertyNameExists;

    const inputIcon = () => {
        if (isCheckingPropertyName) {
            return (
                <CircularProgress
                    size="small"
                    sx={{
                        height: `${theme.spacing(2)}!important`,
                        width: `${theme.spacing(2)}!important`,
                    }}
                />
            );
        }
        if (isPropertyNameAvailable) {
            return (
                <TaskAlt
                    sx={{
                        color: `${theme.palette.success.main}!important`,
                    }}
                />
            );
        }
        return <>&nbsp;</>;
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                    size="small"
                    onBlur={(e) => {
                        setIsPropertyNameAvailable(false);
                        setErrorMessage("");
                        triggerCheckPropertyNameExists({
                            propertyId: propertyid === undefined ? 0 : propertyid,
                            propertyName: e.target.value,
                        }).then((d) => {
                            if (d.data !== null) {
                                const isPropertyExists = d.data;
                                setIsPropertyNameAvailable(!isPropertyExists);
                                setErrorMessage(isPropertyExists ? "* Property name already exists." : "");
                            } else {
                                setIsPropertyNameAvailable(false);
                            }
                        });
                        if (onBlur !== undefined) onBlur(e);
                    }}
                    onChange={(e) => {
                        setIsPropertyNameAvailable(false);
                        if (onChange !== undefined) onChange(e);
                    }}
                    error={errorMsg.length > 0}
                    helperText={errorMsg}
                    InputProps={{
                        endAdornment: inputIcon(),
                    }}
                />
            </Grid>
            {/* <Grid item xs={12}>
                <StyledInput>
                    <OutlinedInput
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        size="small"
                        onBlur={(e) => {
                            setIsPropertyNameAvailable(false);
                            setErrorMessage("");
                            triggerCheckPropertyNameExists({
                                propertyId: propertyid === undefined ? 0 : propertyid,
                                propertyName: e.target.value,
                            }).then((d) => {
                                if (d.data !== null) {
                                    const isPropertyExists = d.data;
                                    setIsPropertyNameAvailable(!isPropertyExists);
                                    setErrorMessage(isPropertyExists ? "* Property name already exists." : "");
                                } else {
                                    setIsPropertyNameAvailable(false);
                                }
                            });
                            if (onBlur !== undefined) onBlur(e);
                        }}
                        onChange={(e) => {
                            setIsPropertyNameAvailable(false);
                            if (onChange !== undefined) onChange(e);
                        }}
                    />
                    {isCheckingPropertyName && (
                        <CircularProgress
                            color="primary"
                            size="small"
                            sx={{
                                height: theme.spacing(2),
                                width: theme.spacing(2),
                            }}
                        />
                    )}
                    {(isPropertyNameAvailable)
                        && (
                            <TaskAlt
                                color="success"
                                sx={{
                                    marginLeft: theme.spacing(2),
                                    marginRight: theme.spacing(1),
                                }}
                            />
                        )}
                </StyledInput>
            </Grid> */}
            {/* <Grid item xs={12}>
                {isCheckingPropertyName && (
                    <Box
                        sx={{
                            paddingLeft: theme.spacing(2),
                        }}
                    >
                        <CircularProgress
                            color="primary"
                            size="small"
                            sx={{
                                height: theme.spacing(2),
                                width: theme.spacing(2),
                            }}
                        />
                    </Box>
                )}
                {(isPropertyNameAvailable)
                    && (
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            <TaskAlt
                                color="success"
                                sx={{
                                    marginLeft: theme.spacing(2),
                                    marginRight: theme.spacing(1),
                                }}
                            />
                            <Typography
                                variant="caption"
                                sx={{
                                    color: theme.palette.success.main,
                                }}
                            >
                                Property name is available
                            </Typography>
                        </Stack>
                    )}
            </Grid> */}
        </Grid>
    );
}
