import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../features/auth/authSlice";

export default function Dashboard() {
    const dispatch = useDispatch();

    useEffect(() => { dispatch(setPageTitle("Dashboard")); }, []);
    return (
        <>

        </>
    );
}
