import { Alert, Box, Button, ButtonBase, Card, CardContent, Grid, MenuItem, Modal, Paper, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { API, Geo } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { setPageTitle, showLoader } from "../features/auth/authSlice";
import { hostAPI, useAddPropertyMutation } from "../features/auth/hostAPI";
import MapComponent from "./MapComponent";
import PropertyNameComponent from "./Property/PropertyNameComponent";
import PropertyRoom from "./Property/PropertyRoom";
import DropdownWithOtherField from "./helper/DropdownWithOtherField";
import CustomLocationFormGroup from "./helper/CustomLocationFormGroup";
import { useAddPremadeRulesAndRuleGroupsMutation } from "../features/auth/rulesAPI";
import { IoTPropertyReference } from "../models/property";
import { useAddToIoTPropertyReferenceMutation } from "../features/auth/propertyAPI";

const AddressItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.grey[100],
    boxShadow: theme.shadows[0],
    minHeight: 64,
}));

export default function PropertyAdd({ callbackRequire }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobileBreakpoint = useSelector((state) => state.auth.mobileBreakpoint);
    const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint));

    // Constant/s Initialization...
    const [open, setOpen] = useState(false);
    const [PropertyName, setPropertyName] = useState("");
    const [PropertyAddress, setPropertyAddress] = useState("");
    const [PropertyBathroom, setPropertyBathroom] = useState(0);
    const [PropertyBed, setPropertyBed] = useState(0);
    const [AddButtonState, setAddButtonState] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [mapLocation, setMapLocation] = useState({
        latitude: 0,
        longitude: 0,
        label: "",
        country: "",
        municipality: "",
        postalCode: "",
        region: "",
        street: "",
        timeZone: "",
    });
    const [errorMsg, setErrorMsg] = useState("");
    const [addProperty, {
        data: dataAddProperty,
        isLoading: isLoadingAddProperty,
        isSuccess: isSuccessAddProperty,
        isError: isErrorAddProperty,
        error: errorAddProperty,
    }] = useAddPropertyMutation();
    const [
        addPremadeRulesAndRuleGroups,
        {
            data: dataAddPremadeRulesAndRuleGroups,
            isLoading: isLoadingAddPremadeRulesAndRuleGroups,
            isSuccess: isSuccessAddPremadeRulesAndRuleGroups,
            isError: isErrorAddPremadeRulesAndRuleGroups,
            error: errorAddPremadeRulesAndRuleGroups,
        },
    ] = useAddPremadeRulesAndRuleGroupsMutation();
    const [
        addToIoTPropertyReference,
        {
            data: dataAddToIoTPropertyReference,
            isLoading: isLoadingAddToIoTPropertyReference,
            isSuccess: isSuccessAddToIoTPropertyReference,
            isError: isErrorAddToIoTPropertyReference,
            error: errorAddToIoTPropertyReference,
        },
    ] = useAddToIoTPropertyReferenceMutation();

    const isValid = PropertyName.trim().length > 0
        && PropertyAddress.trim().length > 0
        && rooms.length > 0
        && (mapLocation.country.trim().length > 0
            || mapLocation.municipality.trim().length > 0
            || mapLocation.region.trim().length > 0
            || mapLocation.postalCode.trim().length > 0);

    const [isPropertyNameAvailable, setIsPropertyNameAvailable] = useState(false);

    const { data: dataIsPropertyNameExists,
        isLoading: isLoadingCheckPropertyNameExists,
        isFetching: isFetchingCheckPropertyNameExists,
    } = hostAPI.endpoints.checkPropertyNameExist.useQueryState({
        propertyId: 0,
        propertyName: PropertyName,
    });

    useEffect(() => {
        if (dataIsPropertyNameExists === undefined
            || dataIsPropertyNameExists === null
        ) {
            setIsPropertyNameAvailable(false);
        } else {
            setIsPropertyNameAvailable(!dataIsPropertyNameExists);
        }

        if (isLoadingCheckPropertyNameExists || isFetchingCheckPropertyNameExists) setIsPropertyNameAvailable(false);
    }, [dataIsPropertyNameExists, isLoadingCheckPropertyNameExists, isFetchingCheckPropertyNameExists]);

    const forSignUp = callbackRequire !== undefined;

    useEffect(() => {
        dispatch(setPageTitle("New Property Information"));
    }, []);

    useEffect(() => {
        if (
            isLoadingAddProperty
          || isLoadingAddToIoTPropertyReference
          || isLoadingAddPremadeRulesAndRuleGroups
        ) {
            dispatch(
                showLoader({
                    open: true,
                    message: "Adding property. Please wait...",
                }),
            );
        } else {
            dispatch(
                showLoader({
                    open: false,
                    message: "",
                }),
            );
        }
    }, [isLoadingAddProperty, isLoadingAddToIoTPropertyReference, isLoadingAddPremadeRulesAndRuleGroups]);

    useEffect(() => {
        if (isSuccessAddProperty && dataAddProperty.id > 0) {
            (async () => {
                await addToIoTPropertyReference({
                    property: new IoTPropertyReference(
                        dataAddProperty.id,
                        dataAddProperty.propertyName,
                        dataAddProperty.userId,
                        dataAddProperty.propertyStatus,
                    ),
                }).then(async () => {
                    await addPremadeRulesAndRuleGroups({
                        propertyId: dataAddProperty.id,
                    }).then(() => {
                        if (callbackRequire) {
                            callbackRequire(dataAddProperty);
                        } else {
                            navigate(`/property/${dataAddProperty.id}?status=new`);
                        }
                    });
                });
            })();
        }

        if (dataAddProperty?.id === 0) setErrorMsg("Error encountered while adding property. Please try again.");

        if (isErrorAddProperty) {
            // alert(errorAddProperty);
            console.log(">>", errorAddProperty);
        }
    }, [isSuccessAddProperty, isErrorAddProperty, dataAddProperty]);
    // ...End of Constant/s Initialization
    // On-Change Events...
    const mapCallBack = (e) => {
        setMapLocation((prev) => ({
            ...prev,
            latitude: e.latitude,
            longitude: e.longitude,
            label: e.label,
            country: e.country,
            municipality: e.municipality,
            postalCode: e.postalCode,
            region: e.region,
            street: e.street,
            timeZone: e.timeZone.name,
        }));
    };
    const PropBathroomOnChange = (e) => {
        setPropertyBathroom(e.target.value);
        // if (PropertyBathroom >= 10 || PropertyBathroom <= 0) { setPropertyBathroom(1) }
    };
    const PropBedOnChange = (e) => {
        setPropertyBed(e.target.value);
        // if (PropertyBed >= 10 || PropertyBed <= 0) { setPropertyBed(1) }
    };
    const PropNameOnChange = (e) => setPropertyName(e.target.value);

    const UpdateRooms = (roomArr) => {
        setRooms(roomArr);
    };
    const searchOptionsWithBiasPosition = { maxResults: 1 };
    // ...End of On-change Events

    // ...Button Events
    const modalConfirmBtn = () => {
        setMapLocation({
            ...mapLocation,
            country: mapLocation.country,
            municipality: mapLocation.municipality,
            region: mapLocation.region,
            postalCode: mapLocation.postalCode,
            timeZone: mapLocation.timeZone.name,
        });
        alert("Address refined successfully! See the details below Street Address");
        setOpen(false);
    };

    const modalCancelBtn = () => {
        setMapLocation({
            ...mapLocation,
            country: mapLocation.country,
            municipality: mapLocation.municipality,
            region: mapLocation.region,
            postalCode: mapLocation.postalCode,
            timeZone: mapLocation.timeZone.name,
        });
        setOpen(false);
    };

    const AddOnClick = (e) => {
        e.preventDefault();
        dispatch(showLoader({
            open: true,
            message: "Adding property. Please wait...",
        }));
        Geo.searchByText(PropertyAddress, searchOptionsWithBiasPosition).then((data) => {
            setMapLocation({
                ...mapLocation,
                country: data[0].country || "",
                longitude: data[0].geometry.point[0] || "",
                latitude: data[0].geometry.point[1] || "",
                municipality: data[0].municipality || "",
                region: data[0].region || "",
                postalCode: data[0].postalCode || "",
                timeZone: data[0].timeZone.name || "",
            });

            if (PropertyName !== "" || PropertyAddress !== "") {
                addProperty({
                    propertyName: PropertyName,
                    locationLong: JSON.stringify(data[0].geometry.point[0]),
                    locationLat: JSON.stringify(data[0].geometry.point[1]),
                    propertyStatus: 0,
                    streetAddress1: PropertyAddress,
                    city: data[0].municipality || "",
                    state: data[0].region || "",
                    country: data[0].country || "",
                    postalCode: data[0].postalCode || "",
                    timeZone: data[0].timeZone.name || "",
                    bedroomCount: PropertyBed,
                    bathroomCount: PropertyBathroom,
                    isFromSignUp: !!callbackRequire,
                    propertyRooms: rooms,
                });
            }
        }).catch((geoErr) => {
            dispatch(showLoader({
                open: false,
                message: "",
            }));
        });
    };
    // ...End of Button Events
    const isCheckingPropertyNameRequest = isLoadingCheckPropertyNameExists || isFetchingCheckPropertyNameExists;

    const mainForm = (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                sx={{
                    display: forSignUp ? "none" : "block",
                }}
            >
                <Typography
                    fontWeight="bold"
                    color="text.secondary"
                >
                    Add Property
                </Typography>
            </Grid>
            {errorMsg.length > 0 && (
                <Grid
                    item
                    xs={12}
                >
                    <Alert severity="error">
                        {errorMsg}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <PropertyNameComponent
                    label="Property Name"
                    fullWidth
                    value={PropertyName}
                    onChange={(e) => {
                        setIsPropertyNameAvailable(false);
                        setPropertyName(e.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <CustomLocationFormGroup
                    callbackResult={(res) => {
                        console.log("callbacResult", res);
                        setPropertyAddress(res.PropertyAddress);
                        setMapLocation(res.mapLocation);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <PropertyRoom
                    callbackUpdateRooms={UpdateRooms}
                    disableEntry={mapLocation.country === ""}
                />
            </Grid>
            {/* </Grid>
            </Grid> */}

            <Grid item xs={6} sx={{ display: "none" }}>
                <DropdownWithOtherField
                    onChange={PropBathroomOnChange}
                    fullWidth
                    type="number"
                    label="Bathroom/s"
                    value={PropertyBathroom}
                    required
                    inputProps={{ min: 0, max: 999 }}
                >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5+</MenuItem>
                </DropdownWithOtherField>
            </Grid>

            <Grid item xs={6} sx={{ display: "none" }}>
                <DropdownWithOtherField
                    onChange={PropBedOnChange}
                    fullWidth
                    type="number"
                    label="Bedroom/s"
                    value={PropertyBed}
                    required
                    inputProps={{ min: 0, max: 999 }}
                >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5+</MenuItem>
                </DropdownWithOtherField>
            </Grid>
            {AddButtonState && (
                <Grid item xs={6}>
                    <Button onClick={() => setOpen(true)} fullWidth color="primary">
                        Show Map
                    </Button>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                textAlign={isMobile ? "center" : "right"}
            >
                <Button
                    variant="contained"
                    fullWidth={!!isMobile}
                    color="primary"
                    onClick={AddOnClick}
                    disabled={!isValid || !isPropertyNameAvailable}
                >
                    {callbackRequire ? "Proceed" : "Add Location"}
                </Button>
            </Grid>
        </Grid>
    );
    return (
        <>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => {
                    setMapLocation({
                        ...mapLocation,
                        country: mapLocation.country,
                        municipality: mapLocation.municipality,
                        region: mapLocation.region,
                        postalCode: mapLocation.postalCode,
                    });
                    setOpen(false);
                }}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Box>
                    {/* <Sheet variant="outlined" sx={{ maxWidth: 800, borderRadius: "lg", p: 3, boxShadow: "lg" }}> */}
                    {/* <ModalClose
                                        variant="outlined"
                                        sx={{
                                            top: "calc(-1/4 * var(--IconButton-size))",
                                            right: "calc(-1/4 * var(--IconButton-size))",
                                            boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                                            borderRadius: "50%",
                                            bgcolor: "background.body",
                                        }}
                                    /> */}
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        View Map
                    </Typography>
                    <Typography style={{ marginBottom: "20px" }} id="modal-desc" textColor="text.tertiary">
                        Search your Address through this map and auto-fill the fields through
                        Amazon Location Service
                    </Typography>
                    <div style={{ marginBottom: "10px" }}>
                        <MapComponent
                            callBack={mapCallBack}
                            mapSize={{ width: "100%", height: "50vh" }}
                        />
                    </div>
                    <Button onClick={modalCancelBtn} style={{ marginLeft: "5%", width: "40%", marginRight: "5%" }} color="neutral">Cancel</Button>
                    <Button onClick={modalConfirmBtn} style={{ marginLeft: "5%", width: "40%", marginRight: "5%" }} color="neutral">Confirm</Button>
                    {/* </Sheet> */}
                </Box>
            </Modal>
            {forSignUp ? (
                mainForm
            ) : (
                <Card>
                    <CardContent>
                        {mainForm}
                    </CardContent>
                </Card>
            )}
            {!forSignUp && (
                <Box
                    sx={{
                        padding: theme.spacing(2),
                    }}
                >
                    <ButtonBase
                        component={RouterLink}
                        to="/property"
                        size="small"
                        sx={{
                            color: theme.palette.primary.main,
                        }}
                    >
                        Back to Properties
                    </ButtonBase>
                </Box>
            )}
        </>
    );
}
