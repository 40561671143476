import { API } from "aws-amplify";

export async function getAccountsbyId(id) {
    const response = await API.get("IoTAPI", `/accounts/${id}`);
    return response.data;
}

// export async function getAccountByPartner(partnerId) {
//     const myInit = {
//         body: {
//             ...partnerId,
//         },
//     };
//     const response = await API.get("IoTAPI", "/accounts/partner", myInit);
//     return response.data;
// }
