import { API } from "aws-amplify";
import { hostAPI } from "./hostAPI";

const rulesAPI = hostAPI.injectEndpoints({
    endpoints: (builder) => ({
        getRulesByProperty: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/rules/property/${propertyId}`,
                    );
                    return { data: response.data.sort((a, b) => b.ruleId - a.ruleId) };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: [
                {
                    type: "rules",
                    id: "list",
                },
            ],
        }),
        getRuleByDefaultDeviceId: builder.query({
            queryFn: async ({ deviceId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/rules/default/${deviceId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "rules",
                    id: id.deviceId,
                },
            ],
        }),
        addRule: builder.mutation({
            queryFn: async ({ ruleObject }) => {
                try {
                    const myInit = {
                        body: {
                            ...ruleObject,
                        },
                    };

                    const response = await API.post("IoTAPI", "/rules", myInit);
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "rules",
                    id: "list",
                },
            ],
        }),
        addRuleGroup: builder.mutation({
            queryFn: async ({ RuleGroup, GroupedRuleIds }) => {
                try {
                    const myInit = {
                        body: {
                            RuleGroup,
                            GroupedRuleIds,
                        },
                    };
                    const response = await API.post("IoTAPI", "/rulegroups", myInit);
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: [
                {
                    type: "rules",
                    id: "list",
                },
            ],
        }),
        updateRule: builder.mutation({
            queryFn: async ({ id, ruleObject }) => {
                try {
                    const myInit = {
                        body: {
                            ...ruleObject,
                        },
                    };
                    const response = await API.put("IoTAPI", `/rules/${id}`, myInit);
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "rules",
                    id: "list",
                },
                {
                    type: "ruleGroups",
                    id: arg.propertyId,
                },
            ],
        }),
        deleteRule: builder.mutation({
            queryFn: async ({ id }) => {
                try {
                    const response = await API.del("IoTAPI", `/rules/${id}`);
                    return { data: response };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            invalidatesTags: (result, error, arg) => [
                {
                    type: "rules",
                    id: "list",
                },
            ],
        }),
        getRulesWithoutGroupConditions: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/rules/no-group/${propertyId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        getRuleGroup: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/rules/rulegroups/${propertyId}`,
                    );
                    const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));

                    return { data: sortedData };
                } catch (error) {
                    console.log("getRuleGroup", error);
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
            providesTags: (result, error, id) => [
                {
                    type: "ruleGroups",
                    id: id.propertyId,
                },
            ],
        }),
        getRulesDefaultByProperty: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/rules/default/property/${propertyId}`,
                    );
                    return {
                        data: response.data.sort((a, b) => b.ruleId - a.ruleId),
                    };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message: error?.message,
                        },
                    };
                }
            },
        }),
        addPremadeRulesAndRuleGroups: builder.mutation({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.post(
                        "IoTAPI",
                        `/rules/premade/${propertyId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message:
                "Failed to create premade rules and rule groups in this property due to an unexpected error. Please try again later.",
                        },
                    };
                }
            },
        }),
        getPremadeRulesAndRuleGroupsByProperty: builder.query({
            queryFn: async ({ propertyId }) => {
                try {
                    const response = await API.get(
                        "IoTAPI",
                        `/rules/premade/${propertyId}`,
                    );
                    return { data: response.data };
                } catch (error) {
                    return {
                        error: {
                            data: error?.response?.data,
                            message:
                          error.response.data.message === ""
                              ? "Failed to create premade rules and rule groups in this property due to an unexpected error. Please try again later."
                              : error.response.data.message,
                        },
                    };
                }
            },
        }),
    }),
});

export const {
    useGetRulesByPropertyQuery,
    useAddRuleMutation,
    useAddRuleGroupMutation,
    useUpdateRuleMutation,
    useDeleteRuleMutation,
    useGetRuleGroupQuery,
    useGetRulesWithoutGroupConditionsQuery,
    useGetRulesDefaultByPropertyQuery,
    useLazyGetRuleByDefaultDeviceIdQuery,
    useLazyGetRulesByPropertyQuery,
    useAddPremadeRulesAndRuleGroupsMutation,
    useGetPremadeRulesAndRuleGroupsByPropertyQuery,
    useLazyGetPremadeRulesAndRuleGroupsByPropertyQuery,
} = rulesAPI;
