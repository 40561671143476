import { Alert, Button, Grid, Skeleton, Table, TableBody, TableCell, TableHead, IconButton, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import moment from "moment/moment";
import { Refresh } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { StyleTableContainer, StyledTableCell, StyledTableHeadCell } from "../helper/StyleTable";
import CompletedServicesDetails from "./CompletedServicesDetails";

function CompletedServicesByPFK({ propertyid }) {
    const [getCompletedListofServices, setGetCompletedListofServices] = useState([]);
    const [openServicesDetailsDialog, setOpenServicesDetailsDialog] = useState(false);
    const [getDetailsId, setGetDetailsId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const localTimeZone = moment.tz.guess(true);
    const navigate = useNavigate();
    const [showSuccessServices, setshowSuccessServices] = useState(false);

    useEffect(() => {
        GetCompletedListOfServices();
        setshowSuccessServices(false);
    }, []);

    const GetCompletedListOfServices = async () => {
        setIsLoading(true);
        const services = await API.get("ServiceProviderApi", `/GetCompletedServices/${propertyid}`)
            .then((r) => r);
        setGetCompletedListofServices(services);
        setIsLoading(false);
    };
    const handleCloseDetailsDialog = () => {
        setOpenServicesDetailsDialog(false);
    };

    const viewServiceRequests = () => {
        navigate("/servicerequest");
    };
    return (
        <>
            <Grid item xs={12}>
                <Grid
                    item
                    xs={6}
                    sx={{ textAlign: "right" }}
                >
                    <IconButton
                        color="primary"
                        onClick={() => GetCompletedListOfServices()}
                    >
                        <Refresh />
                    </IconButton>
                </Grid>
                <StyleTableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell align="left">Provider Name</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Service Type</StyledTableHeadCell>
                                <StyledTableHeadCell align="left">Status</StyledTableHeadCell>
                                <StyledTableHeadCell align="center">Date Accomplished</StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!isLoading && getCompletedListofServices.length === 0) ? (
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>Completed Service(s) is empty.</TableCell>
                                </TableRow>
                            )
                                : getCompletedListofServices.map((s) => (
                                    <TableRow key={s.id}>
                                        <StyledTableCell>
                                            {s.serviceProviderName}
                                        </StyledTableCell>
                                        <StyledTableCell>{s.serviceTypeName}</StyledTableCell>
                                        <StyledTableCell>{s.serviceStatusName}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {`${moment(s.endDateTime).tz(localTimeZone).format("dddd")}, ${moment(s.endDateTime).tz(localTimeZone).format("MM-DD-YYYY")}
                             ${moment(s.endDateTime, "HH:mm:ss").tz(localTimeZone).format("LT")} ${moment.tz(localTimeZone).zoneAbbr()}`}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => {
                                                        setGetDetailsId(s.id);
                                                        setOpenServicesDetailsDialog(true);
                                                    }}
                                                >
                                                    View

                                                </Button>
                                                <Button variant="contained" size="small" color="success">Rate</Button>
                                            </Typography>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            {isLoading && (
                                <TableRow>
                                    <StyledTableCell colSpan={4} sx={{ textAlign: "center" }}>
                                        <Skeleton
                                            width="100%"
                                            height={50}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </StyleTableContainer>
                <Grid
                    item
                    xs={6}
                    sx={{ textAlign: "right" }}
                >
                    <Button
                        variant="standard"
                        size="small"
                        fullWidth
                        onClick={() => {
                            viewServiceRequests();
                        }}
                    >
                        View more
                        <ExpandMoreIcon />
                    </Button>
                </Grid>
            </Grid>
            {(getDetailsId !== null) && (
                <CompletedServicesDetails
                    OpenDetailsDialog={openServicesDetailsDialog}
                    closeDetailsDialog={handleCloseDetailsDialog}
                    PassInfoId={getDetailsId}
                />
            )}
        </>
    );
}

export default CompletedServicesByPFK;
