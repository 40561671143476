import { API } from "aws-amplify";

// export async function getConditionsByRule(id) {
//     const response = await API.get("IoTAPI", `/conditions/${id}`);
//     return response.data;
// }

// export async function AddDefaultCondition(condition) {
//     const myInit = {
//         body: {
//             ...condition,
//         },
//     };

//     const response = await API.post("IoTAPI", "/conditions/device/default", myInit);
//     return response.data;
// }

// export async function AddDeviceCondition(condition) {
//     const myInit = {
//         body: {
//             ...condition,
//         },
//     };

//     const response = await API.post("IoTAPI", "/conditions/device", myInit);
//     return response.data;
// }

// export async function AddRelativeTimeCondition(condition) {
//     const myInit = {
//         body: {
//             ...condition,
//         },
//     };
//     const response = await API.post("IoTAPI", "/conditions/relativetime", myInit);
//     return response.data;
// }

// export async function AddGroupCondition(RuleId, RuleGroups) {
//     const myInit = {
//         body: {
//             RuleId,
//             RuleGroups,
//             IsActive: true,
//         },
//     };
//     const response = await API.post("IoTAPI", "/conditions/group", myInit);
//     return response.data;
// }

// export async function UpdateDeviceCondition(id, condition) {
//     const myInit = {
//         body: {
//             ...condition,
//         },
//     };

//     const response = await API.put("IoTAPI", `/conditions/device/${id}`, myInit);
//     return response.data;
// }

export async function UpdateRelativeTimeCondition(id, condition) {
    const myInit = {
        body: {
            ...condition,
        },
    };

    const response = await API.put("IoTAPI", `/conditions/relativetime/${id}`, myInit);
    return response.data;
}

// export async function DeleteDeviceCondition(id) {
//     const response = await API.del("IoTAPI", `/conditions/device/${id}`);
//     return response.data;
// }

// export async function DeleteRelativeTimeCondition(id) {
//     const response = await API.del("IoTAPI", `/conditions/relativetime/${id}`);
//     return response.data;
// }

// export async function DeleteGroupCondition(id) {
//     const response = await API.del("IoTAPI", `/conditions/group/${id}`);
//     return response.data;
// }
