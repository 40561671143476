import { API } from "aws-amplify";

export async function getDevicesbyProperty(id) {
    const response = await API.get("IoTAPI", `/devices/properties/${id}`);
    return response.data;
}

export async function validateSensiboAuthKey(accessToken) {
    const myInit = {
        body: {
            access_token: accessToken,
        },
    };
    const response = await API.get("IoTAPI", "/sensibo", myInit);
    return response.data;
}

export async function getDevicesbyId(id) {
    const response = await API.get("IoTAPI", `/devices/${id}`);
    return response.data;
}

// export async function GetDevicesAndStatesByPropertyId(propertyId) {
//     const response = await API.get("IoTAPI", `/devices/properties/${propertyId}/states`);
//     return response.data;
// }

export async function DisableDevice(id) {
    const response = await API.put("IoTAPI", `/devices/disable/${id}`);
    return response.data;
}

export async function UpdateDevice(device) {
    const myInit = {
        body: {
            ...device,
        },
    };
    console.log("old update device myInit: ", myInit);
    const response = await API.put("IoTAPI", "/devices", myInit);
    return response.data;
}

// export async function DeleteDevice(id) {
//     const response = await API.del("IoTAPI", `/devices/${id}`);
//     return response.data;
// }

export async function UpdateAllDeviceStates(id) {
    const response = await API.post("IoTAPI", `/devices/refresh/${id}`);
    return response.data;
}
export async function AddDevices(devices) {
    const response = await API.post(
        "IoTAPI",
        "/devices",
        devices,
    );
    return response.data;
}

export async function CheckBasicSensors(propertyId) {
    const response = await API.get(
        "IoTAPI",
        `/devices/checkBasicSensors/${propertyId}`,
    );
    return response.data;
}

export async function DeletePropertyDeviceStatesByProperty(propertyId) {
    const response = await API.del(
        "IoTAPI",
        // "IoTAPI",
        `/devices/propertyDeviceState/${propertyId}/`,
    );
    return response.data;
}

export async function AddUpdatePendingPropertyDeviceStates(propertyDeviceStates) {
    const response = await API.post("IoTAPI", "/devices/propertyDeviceState/pending", propertyDeviceStates);
    // const response = await API.post("IoTAPI", "/devices/propertyDeviceState/pending", propertyDeviceStates);
    return response.data;
}
